import { useState, useEffect } from 'react';
import { Note } from 'types';
import _ from 'lodash';
import moment from 'moment';

import {
  getNotes,
  createNote as createNoteApi,
  sendNote as sendNoteApi,
  deleteNote as deleteNoteApi,
  updateNote as updateNoteApi,
} from 'api/note';
import { getCaseByUserId } from 'api/case';
import { NoteAddOutlined } from '@material-ui/icons';

export interface NotesInfo {
  loading: boolean;
  notes: Array<Note>;
  createNote: (patientId, note: Note) => Promise<void>;
  sendNote: (noteId: number, staffSendId: Array<number>) => Promise<void>;
  deleteNote: (patientId, noteId: number) => Promise<void>;
  updateNote: (patientId, data) => Promise<void>;
  fetchNotes: (patientId: number) => Promise<void>;
}

function useNotesApi(patientId): NotesInfo {
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<any>([]);

  async function createNote(patientId, note): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        await createNoteApi(cases[0].id, note);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function updateNote(patientId: number, data): Promise<void> {
    setLoading(true);
    const cases = await getCaseByUserId(patientId);
    try {
      await updateNoteApi(cases[0].id, data);
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
  }

  async function sendNote(noteId: number, staffSendId: Array<number>): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        const noteSend = await sendNoteApi(cases[0].id, noteId, staffSendId);
        setLoading(false);
        return noteSend;
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  async function deleteNote(patientId: number, noteId: number): Promise<void> {
    setLoading(true);
    const cases = await getCaseByUserId(patientId);
    try {
      if (cases.length) {
        await deleteNoteApi(cases[0].id, noteId);
      }
    } catch (e) {
      console.log('Error', e);
    }
    const notes = await getNotes(cases[0].id);
    setNotes(notes);
    setLoading(false);
  }

  async function fetchNotes(patientId: number): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        const notes = await getNotes(cases[0].id);
        const sortedNotes = _.orderBy(
          notes,
          (o: Note) => {
            return moment(o.createdAt);
          },
          ['desc']
        );

        setNotes(sortedNotes);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchNotes(patientId).catch();
  }, [patientId]);

  return {
    loading,
    notes,
    createNote,
    sendNote,
    deleteNote,
    updateNote,
    fetchNotes,
  };
}

export default useNotesApi;
