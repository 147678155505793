import React, { ReactElement, useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

import { Form } from 'types';
import Dialog from 'components/Dialog';
import Select from 'components/Select';
import DialogButton from 'components/DialogButton';
import styles from './CopyFormUrlDialogStyles.module.scss';
import { FormApi } from 'api';
import { CircularProgress } from '@material-ui/core';

interface CopyFormUrlDialogProps {
  open?: boolean;
  loading: boolean;
  forms?: Array<Form>;
  onSend?(formId): void;
  patientId?: number;
  onClose?(): void;
}

function getFormName(id: number, forms: Array<Form>): string {
  const foundForm = forms.find(form => form.id === id);

  return foundForm?.description || '';
}

function CopyUrlFormDialog({
  open = false,
  loading = false,
  forms = [],
  onClose,
  patientId,
}: CopyFormUrlDialogProps): ReactElement {
  const [formId, setFormId] = useState<number | null>(null);
  const [formUrl, setFormUrl] = useState<string>('');
  const [loadingUrl, setLoadingUrl] = useState<boolean>(loading);
  async function getFormUrl(formId: number | null, patientId: number | undefined) {
    setLoadingUrl(true);
    const result = await FormApi.getFormUrl(formId, patientId);
    setFormUrl(result);
    setLoadingUrl(false);
  }
  useEffect(() => {
    getFormUrl(formId, patientId);
  }, [formId]);

  return (
    <Dialog title="Copy Form URL" open={open} onClose={onClose}>
      <div className={styles.main}>
        <Select
          fullWidth
          label="Choose form"
          name={'form'}
          renderValue={value => (
            <div className={styles.selectItem}>{getFormName(value, forms)}</div>
          )}
          onChange={e => setFormId(e?.target?.value)}
          value={formId}
        >
          {forms.map(item => (
            <MenuItem key={item.id} value={item.id} className={styles.selectItem}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
        {formId && (
          <p className={styles.formUrl}>
            {loadingUrl && !formUrl ? <CircularProgress /> : formUrl}
          </p>
        )}
        <div className={classNames(styles.buttons, styles.padding)}>
          <DialogButton
            loading={loading}
            onClick={() => {
              navigator.clipboard.writeText(formUrl);
            }}
          >
            Copy To Clipboard
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default CopyUrlFormDialog;
