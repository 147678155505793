import React, { ReactElement } from 'react';
import { IconButton } from '@material-ui/core';
import styles from './WeekEvent.module.scss';
import { protectByRole } from 'App/routing/utils/protectByRole';
import { ROLE } from 'lib/user';
import { CalendarType } from 'components/Calendar/Calendar.view';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import { ReferrerStatus, ReferrerStatusShorthand, ReferrerStatusId } from 'types';
import classNames from 'classnames';

const protectedForStaff = protectByRole([ROLE.ADMIN, ROLE.RECEPTIONIST], () => null);

const EditForAdminAndUserOnly = protectedForStaff(({ onClick }: any) => (
  <IconButton aria-label="edit" className={styles.remove} onClick={onClick}>
    <EditIcon title={'edit'} />
  </IconButton>
));

interface AppointmentEvent {
  type: string;
  patient;
  location;
  cancellationList: boolean;
}

interface AppointmentWeekEventProps {
  event: AppointmentEvent;
  onEditEvent: (event) => void;
}

const AppointmentWeekEvent = ({ event, onEditEvent }: AppointmentWeekEventProps): ReactElement => {
  return (
    <div>
      <EditForAdminAndUserOnly
        onClick={e => {
          e.stopPropagation();
          onEditEvent(event);
        }}
      />
      <div className={styles.type}>{event.type}</div>
      <div className={styles.patient}>{event.patient.fullName}</div>
      <div className={styles.patient}>{event.location.name}</div>
      {event.patient.referrerStatusId === ReferrerStatusId.NeedReferral && (
        <div className={classNames(styles.patient, styles[ReferrerStatus.NeedReferral])}>
          {ReferrerStatusShorthand.NeedReferral}
        </div>
      )}
      {event.cancellationList && <div className={styles.cancellationList}>Cancellation List</div>}
    </div>
  );
};

interface LocationEvent {
  title: string;
  subtitle: string;
  locationHours: string;
  availableDoctors: Array<any>;
}

interface LocationWeekEventProps {
  event: LocationEvent;
}

const LocationWeekEvent = ({ event }: LocationWeekEventProps): ReactElement => {
  return (
    <div className={styles.locationHourWeekContainer}>
      <div className={styles.title}>{event.title}</div>
      <div className={styles.title}>{event.subtitle}</div>
      <div className={styles.patient}>{event.locationHours}</div>
      <div className={styles.availableDoctors}>
        Available Doctors:
        {event.availableDoctors.map(availableDoctor => {
          return (
            <div className={styles.availableDoctor} key={availableDoctor.id}>
              {availableDoctor.fullName}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function WeekEvent({ event, onEditEvent, calendarType = 0 }: any): ReactElement {
  return (
    <div
      style={{ backgroundColor: event.color }}
      className={classNames(
        styles.weekEvent,
        styles && event && event.status && styles[event.status.id]
      )}
    >
      {calendarType === CalendarType.Appointments && (
        <AppointmentWeekEvent event={event} onEditEvent={onEditEvent} />
      )}
      {calendarType === CalendarType.LocationSchedule && <LocationWeekEvent event={event} />}
    </div>
  );
}

export default WeekEvent;
