import axios from 'axios';

import { GET_DOCUMENT, GET_FAX } from 'lib/config';
import { ACCESS_TOKEN } from 'lib/const';
import { MIME_TYPE_PDF } from 'lib/document';
import { FaxInbound, FaxOutbound, NewDocument } from 'types';
import { getDefaultHeader } from '../utils';
import { allSettledPolyfill } from 'lib/promise';

const putFile = async (endpoint: string, data: NewDocument) => {
  return axios.put(endpoint, data.document, { headers: { 'Content-Type': MIME_TYPE_PDF } });
};

export async function getReceivedFax(
  searchQuery,
  includeAssigned,
  includeDuplicates
): Promise<Array<FaxInbound>> {
  const response = await axios(GET_FAX, {
    method: 'GET',
    params: { searchQuery, includeAssigned, includeDuplicates },
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getOutboundFax(
  searchQuery,
  includePending,
  includeCompleted
): Promise<Array<FaxOutbound>> {
  const response = await axios(`${GET_FAX}/outbound`, {
    method: 'GET',
    params: { searchQuery, includeCompleted, includePending },
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function refreshOutbound(
  searchQuery,
  includePending,
  includeCompleted
): Promise<Array<FaxOutbound>> {
  const response = await axios(`${GET_FAX}/refreshOutbound`, {
    method: 'GET',
    params: { searchQuery, includeCompleted, includePending, manualRefresh: true },
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function assignFax(faxId, data): Promise<any> {
  const response = await axios.put(`${GET_FAX}/${faxId}/assign`, data, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function assignUpdateFax(faxId, data): Promise<any> {
  const response = await axios.post(`${GET_FAX}/${faxId}/assignUpdate`, data, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function duplicate(faxId, duplicate): Promise<any> {
  const response = await axios.put(
    `${GET_FAX}/${faxId}/duplicate`,
    { duplicate },
    { headers: getDefaultHeader() }
  );
  return response.data;
}

export async function refreshInbound(searchQuery, includeAssigned): Promise<Array<FaxInbound>> {
  const response = await axios.get(`${GET_FAX}/refreshInbound`, {
    headers: getDefaultHeader(),
    params: { searchQuery, includeAssigned, refresh: true },
  });
  return response.data;
}

export async function getSignedUrl(faxId): Promise<any> {
  const response = await axios(`${GET_FAX}/${faxId}/signedUrl`, {
    method: 'GET',
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function pushCoverLetterToS3(uploadUrl: string, document: NewDocument) {
  return await putFile(uploadUrl, document);
}

export async function createGenericDocument(data: NewDocument): Promise<any> {
  try {
    const response = await axios.post(
      `${GET_DOCUMENT}/createGenericDocument`,
      {
        name: data.name,
        mimeType: data.document.type,
        fileName: data.document.name,
      },
      {
        headers: getDefaultHeader(),
      }
    );

    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function createOutgoingFaxWithCoverLetter(
  data: NewDocument,
  documentIds,
  title,
  faxNumber,
  recipientName,
  generic = false
): Promise<Document | Error | unknown> {
  try {
    const response = await axios.post(
      `${GET_FAX}/coverLetter`,
      {
        name: data.name,
        fileName: data.document.name,
        documentIds: documentIds,
        title,
        faxNumber,
        recipientName,
        generic,
      },
      {
        headers: getDefaultHeader(),
      }
    );

    const uploadUrl = response.data.signedRequest;
    await allSettledPolyfill([putFile(uploadUrl, data)]);
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function sendMultipleDocuments(
  documentIds: [number | string],
  startingId: number,
  faxNumber: string,
  generic = false
): Promise<void> {
  const response = await axios.post(
    `${GET_FAX}/sendMultipleDocuments`,
    {
      startingId,
      documentIds,
      faxNumber,
      generic,
    },
    {
      headers: getDefaultHeader(),
    }
  );
  return response.data;
}
