export const ScheduleTypes = {
  ConsultationAndfollowUp: 1,
  Procedure: 2,
  UltraSound: 3,
};

export const AllScheduleTypes = [
  ScheduleTypes.ConsultationAndfollowUp,
  ScheduleTypes.Procedure,
  ScheduleTypes.UltraSound,
];

export const ScheduleTypesStrings = {
  ConsultationAndfollowUp: 'Consultation & Follow Up',
  Procedure: 'Procedure',
  UltraSound: 'UltraSound',
};

export const ScheduleTypesStringsShort = {
  ConsultationAndfollowUp: 'C-F',
  Procedure: 'P',
  UltraSound: 'U',
};

export const ScheduleTypesNumberToString = (scheduleType: number) => {
  if (scheduleType === ScheduleTypes.ConsultationAndfollowUp) {
    return ScheduleTypesStrings.ConsultationAndfollowUp;
  } else if (scheduleType === ScheduleTypes.Procedure) {
    return ScheduleTypesStrings.Procedure;
  } else if (scheduleType === ScheduleTypes.UltraSound) {
    return ScheduleTypesStrings.UltraSound;
  }
};
