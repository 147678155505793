import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';

import { MessageApi } from 'api';
import { TextField } from '@material-ui/core';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';

import styles from './SendEmailDialog.module.scss';

interface SendEmailDialogProps {
  open: boolean;
  caseId: number;
  onCloseDialog: () => void;
  onSend: () => void;
}

function SendEmailDialog({
  open,
  caseId,
  onCloseDialog,
  onSend,
}: SendEmailDialogProps): ReactElement {
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<boolean>(false);

  function onChangeMessage(e): void {
    setMessage(e.target.value);
  }

  async function onSubmit(): Promise<void> {
    try {
      if (message) {
        await MessageApi.sendMessage(caseId, message.replace(/\n/g, '<br>'));
        setMessage('');
        onCloseDialog();
        setMessageError(false);
        onSend();
      } else {
        setMessageError(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Dialog open={open} title={'Send Patient Email'} onClose={onCloseDialog}>
      <div className={classNames(styles.dialogBody, styles.withPadding)}>
        <div className={styles.fields}>
          <div className={styles.withPadding}>
            <TextField
              label="Message"
              onChange={onChangeMessage}
              value={message}
              type="text"
              error={messageError}
              fullWidth
              multiline
            />
          </div>
          <div
            className={classNames(styles.buttonSection, styles.rightContent, styles.withPadding)}
          >
            <DialogButton
              onClick={onCloseDialog}
              buttonType={DialogButtonType.NegationLink}
              className={classNames(styles.button)}
            >
              Cancel
            </DialogButton>
            <DialogButton
              onClick={onSubmit}
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.button}
            >
              Send
            </DialogButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SendEmailDialog;
