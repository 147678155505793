import React, { ReactElement } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import styles from './InvoiceRow.module.scss';
import Autocomplete from 'components/Autocomplete';

export interface Invoice {
  id?: number;
  invoiceId: number;
  invoiceLine: string;
  invoiceAmount: number;
}

const ROW_TYPES = {
  header: 'header',
  footer: 'footer',
};

interface InvoiceRowProps {
  invoiceRow?: Invoice;
  rowType?: string;
  onRowChange?: (name, value) => void;
  amountSum?: number;
  receiptTypes?: string[];
  refund?: boolean;
}

const removeSelection = 'None';

const onDropdownChange = (name, value, onRowChange) => {
  if (removeSelection === value) {
    onRowChange(name, '');
  } else {
    onRowChange(name, value);
  }
};

const renderHeader = () => {
  return (
    <div className={classNames(styles.row, styles.header)}>
      <div className={styles.rowCell}>Enter Text</div>
      <div className={styles.rowCell}>Enter Amount</div>
    </div>
  );
};

const renderFooter = (amountSum: number, refund: boolean) => {
  return (
    <div className={classNames(styles.row, styles.footer)}>
      <div className={styles.rowCell}>{`Total ${refund ? 'Refund' : 'Amount Paid'}:`}</div>
      <div className={styles.rowCell}>${amountSum}</div>
    </div>
  );
};

const renderDropdown = (invoiceRow: Invoice, onRowChange, receiptTypes) => {
  return (
    <div className={styles.row}>
      <div className={styles.rowCell}>
        <Autocomplete
          name={`invoiceLine-${invoiceRow.invoiceId}`}
          items={receiptTypes}
          value={invoiceRow.invoiceLine}
          onChange={(event, newValue): void =>
            onDropdownChange(`invoiceLine-${invoiceRow.invoiceId}`, newValue, onRowChange)
          }
          onInputChange={(event): void =>
            onDropdownChange(`invoiceLine-${invoiceRow.invoiceId}`, event.target.value, onRowChange)
          }
          freeSolo={true}
          fullWidth
        />
      </div>
      <div className={styles.rowCell}>
        <TextField
          name={`invoiceAmount-${invoiceRow.invoiceId}`}
          value={invoiceRow.invoiceAmount}
          InputProps={{ disableUnderline: true }}
          className={classNames(styles.padding, styles.invoiceInput)}
          onChange={({ target }) => onRowChange(target.name, target.value)}
          fullWidth
        />
      </div>
    </div>
  );
};

const renderInvoiceRow = (invoiceRow, rowType, onRowChange, amountSum, receiptTypes, refund) => {
  switch (rowType) {
    case ROW_TYPES.header:
      return renderHeader();
    case ROW_TYPES.footer:
      return renderFooter(amountSum, refund);
    default:
      return renderDropdown(invoiceRow, onRowChange, receiptTypes);
  }
};

function InvoiceRow({
  invoiceRow,
  rowType,
  onRowChange,
  amountSum,
  receiptTypes,
  refund,
}: InvoiceRowProps): ReactElement {
  return (
    <div>{renderInvoiceRow(invoiceRow, rowType, onRowChange, amountSum, receiptTypes, refund)}</div>
  );
}

export default InvoiceRow;
