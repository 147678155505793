import { useContext } from 'react';
import { Context as ProfileContext } from 'context/profile';
import { ROLE } from 'lib/user';

// const ROLE =
// {
// ANONYMOUS: 'anonymous',
// USER: 'user',
// ADMIN: 'admin',
// DOCTOR: 'doctor',
// TECHNICIAN: 'technician',
// PATIENT: 'patient',
// RECEPTIONIST: 'receptionist',
// NURSE: 'nurse
// };
const useProfileRoles = () => {
  const profilecontext = useContext(ProfileContext);

  return {
    userType: profilecontext?.user?.type,
    isAnonymous: profilecontext?.user?.type === ROLE.ANONYMOUS,
    isDoctor: profilecontext?.user?.type === ROLE.DOCTOR,
    isPatient: profilecontext?.user?.type === ROLE.PATIENT,
    isReceptionist: profilecontext?.user?.type === ROLE.RECEPTIONIST,
    isAdmin: profilecontext?.user?.type === ROLE.ADMIN,
    isTechnician: profilecontext?.user?.type === ROLE.TECHNICIAN,
    isNurse: profilecontext?.user?.type === ROLE.NURSE,
    isDoctorOrTechnician:
      profilecontext?.user?.type === ROLE.DOCTOR || profilecontext?.user?.type === ROLE.TECHNICIAN,
    isDoctorOrAdmin:
      profilecontext?.user?.type === ROLE.DOCTOR ||
      profilecontext?.user?.type === ROLE.TECHNICIAN ||
      profilecontext?.user?.type === ROLE.ADMIN,
    isAdminOrReceptionist:
      profilecontext?.user?.type === ROLE.ADMIN || profilecontext?.user?.type === ROLE.RECEPTIONIST,
    isStaff:
      profilecontext?.user?.type === ROLE.DOCTOR ||
      profilecontext?.user?.type === ROLE.TECHNICIAN ||
      profilecontext?.user?.type === ROLE.RECEPTIONIST ||
      profilecontext?.user?.type === ROLE.ADMIN,
  };
};

export default useProfileRoles;
