import logdna from '@logdna/browser';

// Found in Settings > Organization > API Keys

function Logger() {
  const LOGDNA_INGESTION_KEY = process.env['REACT_APP_LOG_DNA_KEY'];
  if (!LOGDNA_INGESTION_KEY) return;

  logdna.init(LOGDNA_INGESTION_KEY, {
    console: true,
  });

  logdna.addContext({
    // Context is appended to the metadata of each message sent to LogDNA
    // Add any custom meta data such as:
    version: 'v1.0.2',
    env: `CVC-WEB: ${process.env['NODE_ENV']}`,
  });
}
export default Logger;
