import React, { Fragment, ReactElement } from 'react';
import { Route, match, Redirect } from 'react-router-dom';

import NotesAndTasks from 'components/NotesAndTasks';
import NotesHistory from 'components/NotesAndTasks/NotesHistory.view';
import Forms from 'pages/Patient/components/Forms';
import Receipts from 'pages/Patient/components/Receipts/Receipts.container';
import Documents from 'pages/Patient/components/Documents';
import Communication from 'pages/Patient/components/Communication';
import AppointmentsHistory from 'pages/Patient/components/AppointmentsHistory';
import { protectByRole } from 'App/routing/utils/protectByRole';
import { ROLE } from 'lib/user';

interface PatientManagementRoutingProps {
  match?: match;
}

const protectedForStaff = protectByRole(
  [ROLE.ADMIN, ROLE.RECEPTIONIST, ROLE.DOCTOR, ROLE.TECHNICIAN, ROLE.NURSE],
  () => <Redirect to="/404" />
);

const CommunicationProtectedForAdminAndUser = protectedForStaff(Communication);

function PatientManagementRouting({ match }: PatientManagementRoutingProps): ReactElement {
  const path = match?.path && match.path !== '/' ? match.path : '';

  return (
    <Fragment>
      <Route exact path={`${path || '/'}`} component={Forms} />
      <Route path={`${path}/receipts`} component={Receipts} />
      <Route path={`${path}/notes-tasks`} component={NotesAndTasks} />
      <Route path={`${path}/documents`} component={Documents} />
      <Route path={`${path}/communication`} component={CommunicationProtectedForAdminAndUser} />
      <Route path={`${path}/appointments-history`} component={AppointmentsHistory} />
      <Route path={`${path}/tasks-history`} component={NotesHistory} />
      <Route path={`${path}/payments`} component={() => <div>payments</div>} />
    </Fragment>
  );
}

export default PatientManagementRouting;
