import { useState } from 'react';
import { EditableUser, User } from 'types';

import { updateProfile as updateUser } from 'api/user';

export interface ProfileInfo {
  loading: boolean;
  updateProfile: (user: EditableUser) => Promise<User | null>;
}

function usePatientInfo(userId): ProfileInfo {
  const [loading, setLoading] = useState(false);

  const updateProfile = async (user: EditableUser): Promise<User | null> => {
    setLoading(true);
    let response: User | null = null;

    try {
      response = await updateUser(userId, user);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);

    return response;
  };

  return {
    loading,
    updateProfile,
  };
}

export default usePatientInfo;
