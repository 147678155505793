import React, { ReactElement, useEffect, useState } from 'react';
import { Moment } from 'moment';
import styles from './AppointmentsByTypeColumn.module.scss';
import {
  getQuarterTimeIndexed,
  APPOINTMENT_TYPES,
  getFiveMinTimeIndexed,
  APPOINTMENT_TYPE_IDS,
  MINIMUM_APPOINTMENT_LENGTH,
} from 'lib/appointments';
import classNames from 'classnames';
import AppointmentInfo from '../AppointmentInfo/AppointmentInfo';
import SaveAppointmentDialog from '../SaveAppointmentsDialog/SaveAppointmentDialog.view';

import {
  DailyAppointment,
  DynamicAppointmentSelection,
  AppointmentOpenings,
  RescheduleInfo,
} from 'types';
import { getStartTimesArray, quarterHours, convertTo12From24 } from 'lib/schedule';
import _ from 'lodash';
import { DateTimeFormatString } from 'lib/dateFormatter';
import moment from 'moment';
import { EARLIEST_OPENING_HOUR, LATEST_CLOSING_HOUR } from 'lib/const';
import { ROLE_ID } from 'lib/user';
import AppointmentPersonalTime from '../AppointmentPersonalTime/AppointmentPersonalTime';

interface AppointmentsByTypeColumnProps {
  date?: Moment;
  exceptions?: Array<any>;
  appointments?: Array<DailyAppointment>;
  isHoursColumn?: boolean;
  type?: number | string | null;
  types?: Array<any>;
  locationDailyAppointmentAvailability?: Array<AppointmentOpenings | any>;
  dynamicSelection?: DynamicAppointmentSelection;
  filterAppointmentStatus?: number | null;
  filterAppointmentType?: string | number | null;
  filterSelectedStaff?: number | null | undefined;
  highlightOnlyCancellationAppts?: boolean;
  rescheduleAppointmentInfo?: RescheduleInfo | undefined;
  goToAppointmentId?: number | undefined;
  patientPreset?: boolean;
  readOnly?: boolean;
  exception?: boolean;
  selectedLocation?: number;
  eventToDelete?: any;
  deleting?: boolean;
  error?: boolean;
  responseAppt?: any;
  setResponseAppt?: (appt) => void;
  deleteAppointment?: () => Promise<boolean>;
  deletePersonalTime?: () => Promise<void>;
  setEventToDelete?: (event) => void;
  onNewAppointmentClick?: (basicSetup: boolean, details?: any) => void;
  setDynamicSelection?: (dynamicSelection) => void;
  onSaveSuccess?: () => void;
  editAppointment?: (appt) => void;
  setShowDeleteConfirmation?: (open) => void;
  onUpdateApptStatus?: (appointment) => void;
  openStaffNotes?: (doctor) => void;
}

function AppointmentsByTypeColumn({
  exceptions,
  date,
  appointments,
  isHoursColumn,
  dynamicSelection,
  types,
  filterAppointmentStatus,
  filterAppointmentType,
  filterSelectedStaff,
  locationDailyAppointmentAvailability,
  highlightOnlyCancellationAppts,
  goToAppointmentId,
  type,
  error,
  readOnly,
  rescheduleAppointmentInfo,
  patientPreset = false,
  selectedLocation,
  eventToDelete,
  deleting,
  responseAppt,
  deleteAppointment,
  deletePersonalTime,
  setResponseAppt,
  setEventToDelete,
  setShowDeleteConfirmation,
  setDynamicSelection,
  onNewAppointmentClick,
  exception,
  onSaveSuccess,
  editAppointment,
  onUpdateApptStatus,
  openStaffNotes,
}: AppointmentsByTypeColumnProps): ReactElement {
  const indexedTimes = isHoursColumn
    ? getQuarterTimeIndexed(EARLIEST_OPENING_HOUR, LATEST_CLOSING_HOUR)
    : getFiveMinTimeIndexed(EARLIEST_OPENING_HOUR, LATEST_CLOSING_HOUR);
  const [availableTimesMap, setAvailableTimesMap] = useState<Array<string>>([]);

  useEffect(() => {
    let flattenedTimes: any = [];
    locationDailyAppointmentAvailability?.forEach(opening => {
      if (
        type != APPOINTMENT_TYPES.find(type => type.id == APPOINTMENT_TYPE_IDS.Procedure)?.name ||
        opening.staffType !== ROLE_ID.TECHNICIAN
      ) {
        flattenedTimes.push(getStartTimesArray(opening.startTime, opening.endTime, true));
        flattenedTimes = _.flatten(flattenedTimes);
        flattenedTimes = _.union(flattenedTimes);
      }
    });

    setAvailableTimesMap(flattenedTimes.sort());
  }, [locationDailyAppointmentAvailability]);
  function updateDynamicSelection({ startTime, endTime, type }) {
    if (setDynamicSelection) {
      setDynamicSelection({
        startTime: startTime,
        endTime: endTime,
        type: type,
      });
    }
  }
  const validateSelection = eventTime => {
    // Check if selection conflicts with other appointments
    if (locationDailyAppointmentAvailability && appointments && dynamicSelection) {
      if (eventTime == dynamicSelection.startTime && dynamicSelection.endTime != '') {
        eventTime = dynamicSelection.endTime;
      }

      if (
        moment(eventTime, DateTimeFormatString.TimeOnlyWithoutMeridies).diff(
          moment(dynamicSelection.startTime, DateTimeFormatString.TimeOnlyWithoutMeridies),
          'm'
        ) < MINIMUM_APPOINTMENT_LENGTH
      ) {
        return false;
      }
      for (const appt of appointments) {
        if (
          dynamicSelection.startTime == '' ||
          (dynamicSelection.startTime != '' && dynamicSelection.endTime == '')
        )
          break;
        if (
          dynamicSelection.startTime < appt.appointmentStartTime &&
          eventTime >= appt.appointmentStartTime
        ) {
          return false;
        }
        if (
          dynamicSelection.startTime > appt.appointmentEndTime &&
          eventTime < appt.appointmentEndTime
        ) {
          return false;
        }
      }
      // Check if selection fits inside staff schedule
      for (const dailyAppt of locationDailyAppointmentAvailability) {
        if (dynamicSelection.startTime == '') {
          const validated = eventTime >= dailyAppt.startTime && eventTime < dailyAppt.endTime;
          if (validated) {
            return true;
          }
        } else if (
          dynamicSelection.startTime >= dailyAppt.startTime &&
          dynamicSelection.startTime <= dailyAppt.endTime
        ) {
          const validated = !(eventTime < dailyAppt.startTime || eventTime >= dailyAppt.endTime);
          if (validated) return true;
        }
      }

      return false;
    }
    return true;
  };
  const resetDynamicSelection = () => {
    updateDynamicSelection({
      startTime: '',
      endTime: '',
      type: '',
    });
  };
  function createNewAppointmentDetails(startTime, endTime) {
    if (!onNewAppointmentClick) return;

    let localStartTime = startTime;
    let localEndTime = endTime ? endTime : startTime;
    if (localStartTime > localEndTime) {
      localStartTime = localEndTime;
      localEndTime = startTime;
    }
    const index = indexedTimes.find(obj => obj.time === localEndTime);
    localEndTime = index ? indexedTimes[index.id + 1].time : null;
    onNewAppointmentClick(true, {
      type: type,
      startTime: localStartTime,
      endTime: localEndTime,
    });
  }

  const onClick = event => {
    if (dynamicSelection) {
      const details = { ...dynamicSelection };
      const eventTime = event.currentTarget.id;
      if (event.shiftKey) {
        if (!details.startTime && !details.type) {
          if (validateSelection(eventTime)) {
            details.startTime = eventTime;
            if (type) {
              details.type = type.toString();
            }
            updateDynamicSelection(details);
          }
        } else if (type === details.type) {
          if (validateSelection(eventTime)) {
            createNewAppointmentDetails(details.startTime, eventTime);
          }
          resetDynamicSelection();
          return;
        }
      } else {
        if (dynamicSelection.startTime == '') {
          createNewAppointmentDetails(eventTime, eventTime);
        }
      }
    }
  };

  const onDragOverClk = event => {
    const eventTime = event.currentTarget.id;
    event.preventDefault();
    if (validateSelection(eventTime) && dynamicSelection) {
      const details = { ...dynamicSelection };
      if (eventTime != details?.endTime && details?.type === type) {
        details.endTime = eventTime;
        updateDynamicSelection(details);
      }
    }
  };
  const onDragStartClk = event => {
    const startTime = event.currentTarget.id;
    if (validateSelection(startTime)) {
      updateDynamicSelection({ startTime, endTime: '', type });
    }
  };

  const onDragEnd = event => {
    if (validateSelection(dynamicSelection?.startTime)) {
      createNewAppointmentDetails(dynamicSelection?.startTime, dynamicSelection?.endTime);
    }

    resetDynamicSelection();
  };

  const isDisabled = () => {
    const rescheduleType = APPOINTMENT_TYPES.find(
      type => type.name === rescheduleAppointmentInfo?.mainType
    );
    if (!rescheduleType) return false;
    const typeName = APPOINTMENT_TYPES.find(type => type.id === rescheduleType.id);
    return typeName ? typeName.name !== type : false;
  };

  const displaySelected = (time, dynamicSelection): boolean => {
    if (dynamicSelection?.type != type) return false;
    if (dynamicSelection?.endTime) {
      return (
        (time >= dynamicSelection?.startTime && time <= dynamicSelection?.endTime) ||
        (time >= dynamicSelection?.endTime && time <= dynamicSelection?.startTime)
      );
    }
    return time === dynamicSelection?.startTime;
  };

  const isUnavailable = time => {
    if (!availableTimesMap?.includes(time)) {
      return true;
    }

    if (filterSelectedStaff != -1 && locationDailyAppointmentAvailability) {
      for (const availibility of locationDailyAppointmentAvailability) {
        if (
          type === APPOINTMENT_TYPES[APPOINTMENT_TYPE_IDS.Procedure - 1].name &&
          availibility.staffType === ROLE_ID.TECHNICIAN
        ) {
          continue;
        }
        if (
          availibility.staffId === filterSelectedStaff &&
          availibility.startTime <= time &&
          availibility.endTime > time
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const isQuarterHour = time => {
    const s = time.split(':');
    if (quarterHours.includes(s[1])) {
      return true;
    }
    return false;
  };

  const isException = (exceptions, time) => {
    /**
     * Used when the location or the location schedule has an exception.
     * It will find were the location schedule times are and is used to
     * know which cells to tint red.
     */
    if (!exceptions) return false;

    const exceptionFound = exceptions.find(exception => {
      if (exception.locationId) return true;
      const typeName = APPOINTMENT_TYPES.find(type => type.id == exception.locationScheduleType)
        ?.name;
      const sameType = typeName == type;
      const timeAfterStart = exception.locationScheduleStartTime <= time;
      const timeBeforeEnd = exception.locationScheduleEndTime >= time;
      return timeAfterStart && timeBeforeEnd && sameType;
    });

    return !!exceptionFound;
  };

  return (
    <div className={classNames(styles.dailyColumn, isHoursColumn && styles.hoursColumn)}>
      <div className={styles.dailyAppointmentsContainer}>
        {indexedTimes.map(time => {
          return (
            <div
              className={classNames(
                styles.hourBlock,
                displaySelected(time.time, dynamicSelection) && styles.hourBlockSelected,
                readOnly && styles.disableClickOnly,
                (isUnavailable(time.time) || isDisabled()) &&
                  !isHoursColumn &&
                  styles.unavailableTimeCell,
                isQuarterHour(time.time) && !isHoursColumn && styles.quarterHour,
                isException(exceptions, time.time) && styles.exception
              )}
              key={time.id}
              id={time.time}
              onClick={onClick}
              draggable={true}
              onDragStart={onDragStartClk}
              onDragOver={onDragOverClk}
              onDragEnd={onDragEnd}
            >
              <span className={styles.hourLabel}>{convertTo12From24(time.time)}</span>
            </div>
          );
        })}
      </div>

      <div className={styles.appointmentContainer}>
        {appointments?.map(appointment => {
          const pastAppointment = moment(
            `${moment(date).format(DateTimeFormatString.APIDateFormat)} ${
              appointment.appointmentEndTime
            }`
          ).isBefore();

          return appointment.patient.fullName == 'Personal Time' ? (
            <AppointmentPersonalTime
              key={appointment.id}
              appointmentDate={date}
              pastAppointment={pastAppointment}
              indexedTimes={indexedTimes}
              appointment={appointment}
              filterAppointmentStatus={filterAppointmentStatus}
              filterAppointmentType={filterAppointmentType}
              filterSelectedStaff={filterSelectedStaff}
              highlightOnlyCancellationAppts={highlightOnlyCancellationAppts}
              types={types}
              date={date}
              selectedLocationId={selectedLocation}
              eventToDelete={eventToDelete}
              deleting={deleting}
              deletePersonalTime={deletePersonalTime}
              setEventToDelete={setEventToDelete}
              onUpdateAppointmentSuccess={onSaveSuccess}
              setResponseAppt={setResponseAppt}
              setShowConfirmation={setShowDeleteConfirmation}
              editAppointment={editAppointment}
              reschedulingAppointment={!!rescheduleAppointmentInfo}
              patientPreset={patientPreset}
              goToAppointmentId={goToAppointmentId}
              readOnly={readOnly}
              onUpdateApptStatus={onUpdateApptStatus}
            />
          ) : (
            <AppointmentInfo
              key={appointment.id}
              appointmentDate={date}
              pastAppointment={pastAppointment}
              indexedTimes={indexedTimes}
              appointment={appointment}
              filterAppointmentStatus={filterAppointmentStatus}
              filterAppointmentType={filterAppointmentType}
              filterSelectedStaff={filterSelectedStaff}
              highlightOnlyCancellationAppts={highlightOnlyCancellationAppts}
              types={types}
              date={date}
              selectedLocationId={selectedLocation}
              eventToDelete={eventToDelete}
              deleting={deleting}
              deleteAppointment={deleteAppointment}
              setEventToDelete={setEventToDelete}
              onUpdateAppointmentSuccess={onSaveSuccess}
              setResponseAppt={setResponseAppt}
              setShowConfirmation={setShowDeleteConfirmation}
              editAppointment={editAppointment}
              reschedulingAppointment={!!rescheduleAppointmentInfo}
              patientPreset={patientPreset}
              goToAppointmentId={goToAppointmentId}
              readOnly={readOnly}
              onUpdateApptStatus={onUpdateApptStatus}
              openStaffNotes={openStaffNotes}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AppointmentsByTypeColumn;
