import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Dialog from '../Dialog';
import DialogButton from '../DialogButton';

import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/CVCButton';
import styles from './StaffEditDialogStyles.module.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../Select';
import formatPhoneInput, { MAX_PHONE_CHARACTER_LENGTH } from 'lib/phoneNumFormatter';
import { supportedFileTypesString } from 'lib/document';
import { User } from 'types';
import { ROLE } from 'lib/user';

interface StaffEditEntity {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  userType?: string;
  locationId?: number;
  ohipPhysicianId?: string;
  abbreviations?: string;
  signatureFile?: any;
  signatureApprovedUsers?: Array<User>;
}

interface DisabledInputs {
  email?: boolean;
  phone?: boolean;
  firstName?: boolean;
  lastName?: boolean;
  userType?: boolean;
  locationId?: boolean;
  ohipPhysicianId?: boolean;
  abbreviations?: boolean;
}

interface UserType {
  id: number;
  type: string;
}

interface Location {
  id: string | number;
  name: string;
}

interface StaffEditDialogProps {
  open?: boolean;
  title?: string;
  value: StaffEditEntity;
  disabled?: DisabledInputs;
  types?: Array<UserType>;
  locations?: Array<Location>;
  loading?: boolean;
  staffList?: Array<User>;
  onChangeSignatureFile(file): void;
  onChangeSignatureApprovedStaff(staff: Array<User>): void;
  onChange(Event): void;
  onSave(): void;
  onClose(): void;
}

function StaffEditDialog({
  open = false,
  title = 'New Staff',
  loading,
  value,
  types = [],
  disabled,
  locations = [],
  staffList = [],
  onChange,
  onChangeSignatureFile,
  onChangeSignatureApprovedStaff,
  onSave,
  onClose,
}: StaffEditDialogProps): ReactElement {
  const fileInput = useRef<any>(null);

  const handleFileChange = (event): void => {
    event.stopPropagation();
    event.preventDefault();
    onChangeSignatureFile(event.target.files[0]);
  };

  return (
    <Dialog open={open} title={title} onClose={onClose}>
      <div className={classNames(styles.dialogBody, styles.withPadding)}>
        <div className={styles.fields}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="First Name"
                  name="firstName"
                  placeholder="Enter First Name"
                  disabled={disabled?.firstName}
                  onChange={onChange}
                  fullWidth
                  value={value.firstName}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  placeholder="Enter Last Name"
                  disabled={disabled?.lastName}
                  onChange={onChange}
                  fullWidth
                  value={value.lastName}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Email"
                  name="email"
                  disabled={disabled?.email}
                  placeholder="Enter email"
                  onChange={onChange}
                  fullWidth
                  value={value.email}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Phone"
                  name="phone"
                  disabled={disabled?.phone}
                  placeholder="Enter phone"
                  onChange={onChange}
                  fullWidth
                  value={formatPhoneInput(value.phone)}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <Select
                  fullWidth
                  label="User Type"
                  name={'userType'}
                  disabled={disabled?.userType}
                  renderValue={value => <div className={styles.selectItem}>{value}</div>}
                  onChange={onChange}
                  value={value.userType}
                >
                  {types.map(item => (
                    <MenuItem key={item.type} value={item.type} className={styles.selectItem}>
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Ohip Physician ID"
                  name="ohipPhysicianId"
                  disabled={disabled?.ohipPhysicianId}
                  placeholder="Enter Ohip Physician ID"
                  onChange={onChange}
                  fullWidth
                  value={value.ohipPhysicianId}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Abbreviations"
                  name="abbreviations"
                  placeholder="Enter Abbreviations"
                  disabled={disabled?.abbreviations}
                  onChange={onChange}
                  fullWidth
                  value={value.abbreviations}
                />
              </div>
            </Grid>
            {value.userType == ROLE.DOCTOR && (
              <Grid item xs={12} sm={6}>
                <div className={styles.withPadding}>
                  <Button onClick={(): void => fileInput?.current?.click()}>
                    {'Change Signature'}
                  </Button>
                  <input
                    type="file"
                    ref={fileInput}
                    style={{ display: 'none' }}
                    name="document"
                    onChange={handleFileChange}
                    accept={supportedFileTypesString().slice(0, -1)}
                  />
                  <div>{value.signatureFile?.name}</div>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container>
            {value.userType == ROLE.DOCTOR && (
              <Grid item xs={12} sm={12}>
                <div className={styles.withPadding}>
                  <Autocomplete
                    label="Approved Staff"
                    items={staffList}
                    value={value.signatureApprovedUsers}
                    getOptionLabel={staff => `${staff.fullName}`}
                    onChange={(event, newValue) => onChangeSignatureApprovedStaff(newValue)}
                    multiple
                    fullWidth
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classNames(styles.buttons, styles.withPadding)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton onClick={onSave} loading={loading} className={styles.marginLeft}>
            Save
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default StaffEditDialog;
