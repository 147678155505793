import { useState, useEffect } from 'react';
import { User } from 'types';

import { getUser, updatePatient as updateUser } from 'api/user';

export interface PatientInfo {
  loading: boolean;
  patient: User | null;
  updatePatient: (user: any) => void;
  fetchUser: (patientId: any) => void;
}

function usePatientInfo(patientId): PatientInfo {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<User | null>(null);

  const updatePatient = async (user: any): Promise<void> => {
    setLoading(true);
    if (patient) {
      try {
        const response = await updateUser(patient.id, user);
        setPatient(response);
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
    setLoading(false);
  };

  async function fetchUser(patientId): Promise<void> {
    setLoading(true);
    try {
      const user = await getUser(patientId);
      setPatient(user);
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (patientId) fetchUser(patientId).catch();
  }, [patientId]);

  return {
    loading,
    patient,
    updatePatient,
    fetchUser,
  };
}

export default usePatientInfo;
