import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './PatientManagementHeaderStyles.module.scss';
import { protectByRole } from 'App/routing/utils/protectByRole';
import { ROLE } from 'lib/user';

interface PatientManagementHeaderProps {
  parentUrl?: string;
}

const protectedForStaff = protectByRole(
  [ROLE.ADMIN, ROLE.RECEPTIONIST, ROLE.DOCTOR, ROLE.TECHNICIAN, ROLE.NURSE],
  () => null
);

const CommunicationNavLinkProtectedForAdminAndUser = protectedForStaff(({ url }: any) => (
  <NavLink to={url} className={styles.link} activeClassName={styles.selected}>
    Communication
  </NavLink>
));

const ReceiptNavLinkProtectedForAdmin = protectByRole(
  [ROLE.ADMIN, ROLE.RECEPTIONIST],
  () => null
)(({ url }: any) => (
  <NavLink to={url} className={styles.link} activeClassName={styles.selected}>
    Receipts
  </NavLink>
));

function PatientManagementHeader({ parentUrl = '' }: PatientManagementHeaderProps): ReactElement {
  const url = !parentUrl || parentUrl === '/' ? '' : parentUrl;

  return (
    <div className={styles.container}>
      <NavLink to={`${url || '/'}`} className={styles.link} activeClassName={styles.selected} exact>
        Forms
      </NavLink>
      <NavLink to={`${url}/documents`} className={styles.link} activeClassName={styles.selected}>
        Documents
      </NavLink>
      <ReceiptNavLinkProtectedForAdmin url={`${url}/receipts`} />
      <NavLink
        to={`${url}/appointments-history`}
        className={styles.link}
        activeClassName={styles.selected}
      >
        Appointments History
      </NavLink>
      <NavLink to={`${url}/notes-tasks`} className={styles.link} activeClassName={styles.selected}>
        Notes & TASKS
      </NavLink>
      <NavLink
        to={`${url}/tasks-history`}
        className={styles.link}
        activeClassName={styles.selected}
      >
        Tasks History
      </NavLink>
      <CommunicationNavLinkProtectedForAdminAndUser url={`${url}/communication`} />
      {/*<NavLink to={`${url}/payments`} className={styles.link} activeClassName={styles.selected}>*/}
      {/*  Payments*/}
      {/*</NavLink>*/}
    </div>
  );
}

export default PatientManagementHeader;
