import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Button from 'components/Button';
import Selector from 'components/Selector';
import { DatePicker } from '@material-ui/pickers';
import styles from './ResponsiveTopBar.module.scss';
import InputSearch from '../InputSearch';
import LocationSelector from 'components/LocationSelector/LocationSelector.container';
import TitledSection from 'components/TitledSection';
import Checkbox from '@material-ui/core/Checkbox';
import { ROLE } from 'lib/user';
import { Context } from 'context/auth';
import { DateTimeFormatString } from 'lib/dateFormatter';
import { DateType } from '@date-io/type';
import classNames from 'classnames';
import { LocationListItem, SubType } from 'types';

interface ResponsiveTopBarProps {
  appointmentTypeId: string | number | null;
  selectAppointmentTypeId: (type) => void;
  searchQuery: string | null;
  locationIds: Array<number>;
  locations: Array<LocationListItem>;
  onChangeSearchQuery: (query) => void;
  onNewPatientClick: () => void;
  selectLocation: (location) => void;
  cancellationValue: boolean | undefined;
  selectCancellationValue: (value: boolean) => void;
  appointmentStartDay: DateType | null;
  selectAppointmentStartDate: (date) => void;
  subTypes: Array<SubType>;
}

function ResponsiveTopBar({
  appointmentTypeId,
  selectAppointmentTypeId,
  locationIds,
  locations,
  searchQuery,
  onChangeSearchQuery,
  onNewPatientClick,
  cancellationValue,
  selectCancellationValue,
  selectLocation,
  appointmentStartDay,
  selectAppointmentStartDate,
  subTypes,
}: ResponsiveTopBarProps): ReactElement {
  const authContext = useContext(Context);
  const [selectorSubTypes, setSelectorSubTypes] = useState<Array<any>>([]);

  const onCalendarChange = date => {
    selectAppointmentStartDate(date);
  };

  useEffect(() => {
    const types = subTypes.map(type => {
      return { id: type.id, name: type.completeName };
    });
    setSelectorSubTypes(types);
  }, [subTypes]);

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <TitledSection title="Patient List" />
        <div className={styles.selectors}>
          <div className={styles.selectors} style={{ justifyContent: 'flex-end' }}>
            <div className={styles.inputSearchContainer}>
              {cancellationValue && (
                <>
                  <div className={classNames(styles.withPadding, styles.startDatePicker)}>
                    <label>Date</label>
                    <DatePicker
                      value={appointmentStartDay}
                      onMonthChange={onCalendarChange}
                      onYearChange={onCalendarChange}
                      onChange={onCalendarChange}
                      format={DateTimeFormatString.DateOnly}
                      fullWidth
                    />
                  </div>
                  <LocationSelector
                    locations={locations}
                    value={locationIds}
                    onChange={selectLocation}
                  />
                  <Selector
                    label="Type"
                    items={selectorSubTypes}
                    value={appointmentTypeId}
                    onChange={event => selectAppointmentTypeId(event.target.value || null)}
                  />
                </>
              )}
              {(authContext.role === ROLE.ADMIN || authContext.role === ROLE.RECEPTIONIST) && (
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    onChange={event => selectCancellationValue(event.target.checked)}
                    title={'Show cancellation list'}
                    inputProps={{ 'aria-label': 'Show cancellation list' }}
                  />
                  <label>Show cancellation list</label>
                </div>
              )}
              <InputSearch
                value={searchQuery}
                onChange={event => onChangeSearchQuery(event.target.value)}
              />
            </div>
            {(authContext.role === ROLE.ADMIN || authContext.role === ROLE.RECEPTIONIST) && (
              <div style={{ margin: '2px' }}>
                <Button
                  className={`${styles.marginLeft} ${styles.marginTop}`}
                  onClick={onNewPatientClick}
                >
                  New Patient
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveTopBar;
