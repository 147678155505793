import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import Dialog from '../Dialog';
import styles from './OtherPhysicianEditDialogStyles.module.scss';
import DialogButton from '../DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { ValidationError } from '../Forms/ValidationError';
import { UserApi } from 'api';
import { EditPhysicianValidationError, OtherPhysician } from 'types';
import formatPhoneInput, { MAX_PHONE_CHARACTER_LENGTH } from 'lib/phoneNumFormatter';
import { FamDocInfoConfirmDialog } from 'components/FamDocInfoConfirmDialog';

interface OtherPhysicianEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSaveSuccess: (physcian: OtherPhysician) => void;
  selectedPhysician?: OtherPhysician;
}

function OtherPhysicianEditDialog({
  open,
  onClose,
  onSaveSuccess,
  selectedPhysician,
}: OtherPhysicianEditDialogProps): ReactElement {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [physicianId, setPhysicianId] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [fax, setFax] = useState<string>('');
  const [billing, setBilling] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Array<EditPhysicianValidationError>>([]);
  const [fieldsValidated, setFieldsValidated] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<Array<string>>([]);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);

  const validateForm = (showErrors = false) => {
    let validated = true;
    if (showErrors) setFieldsValidated(true);
    if (
      firstName == '' &&
      lastName == '' &&
      fax == '' &&
      billing == '' &&
      email == '' &&
      phone == ''
    ) {
      validated = false;
    }
    return validated;
  };

  const reset = () => {
    setFirstName('');
    setLastName('');
    setPhysicianId('');
    setPhone('');
    setEmail('');
    setFax('');
    setBilling('');
    setFieldsValidated(false);
    setValidationErrors([]);
    setMissingFields([]);
    setOpenWarningDialog(false);
  };

  const onSave = async () => {
    try {
      const body = {
        firstName,
        lastName,
        email,
        phone,
        billing,
        fax,
        physicianId,
      };
      let physician;
      if (selectedPhysician) {
        physician = await UserApi.updateOtherPhysician(selectedPhysician.id, body);
      } else {
        physician = await UserApi.createOtherPhysician(body);
      }
      onSaveSuccess(physician);
    } catch (err) {
      console.log(err);
    }
  };

  const onPhysicianDialogSave = test => {
    if (validateForm(true)) {
      missingFields.length > 0 ? setOpenWarningDialog(true) : onSave();
    }
  };
  const onWarningDialogSave = test => {
    onSave();
  };
  const onWarningDialogCancel = () => {
    setOpenWarningDialog(false);
  };

  const localOnClose = () => {
    reset();
    onClose();
  };

  const checkForError = (fieldName): boolean => {
    const validationError = validationErrors.filter(item => item.field === fieldName);
    return validationError && validationError.length > 0;
  };

  useEffect(() => {
    if (!open) return;

    const missed: Array<string> = [];
    if (phone == '' || phone == null) missed.push('Phone Number');
    if (email == '' || email == null) missed.push('Email');

    setMissingFields(missed);
  }, [email, phone, open]);

  useEffect(() => {
    if (!fieldsValidated) return;
    validateForm();
  }, [firstName, lastName]);

  useEffect(() => {
    if (!selectedPhysician) return;

    setFirstName(selectedPhysician.firstName);
    setLastName(selectedPhysician.lastName);
    setPhysicianId(selectedPhysician.physicianId);
    setPhone(selectedPhysician.phone);
    setEmail(selectedPhysician.email);
    setFax(selectedPhysician.fax);
    setBilling(selectedPhysician.billing);
  }, [selectedPhysician, open]);

  return (
    <Dialog
      open={open}
      title={selectedPhysician ? 'Edit Physician' : 'Create Physician'}
      onClose={localOnClose}
    >
      <div className={styles.container}>
        <div>
          <form className={styles.formContainer}>
            <div className={styles.formColumn}>
              <div className={styles.formFieldContainer}>
                <TextField
                  label="First Name"
                  name={'firstName'}
                  value={firstName}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) => setFirstName(target.value)}
                  error={checkForError('firstName')}
                  fullWidth
                />
                <ValidationError field={'firstName'} validationErrors={validationErrors} />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  label="Last Name"
                  name={'lastName'}
                  value={lastName}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) => setLastName(target.value)}
                  error={checkForError('lastName')}
                  fullWidth
                />
                <ValidationError field={'lastName'} validationErrors={validationErrors} />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  label="Phone Number"
                  name={'phone'}
                  value={formatPhoneInput(phone)}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) => setPhone(target.value)}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                  fullWidth
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  label="Email"
                  name={'email'}
                  value={email}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) => setEmail(target.value)}
                  fullWidth
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  label="Fax"
                  name={'fax'}
                  value={formatPhoneInput(fax)}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) => setFax(target.value)}
                  error={checkForError('fax')}
                  fullWidth
                />
                <ValidationError field={'fax'} validationErrors={validationErrors} />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  label="Billing"
                  name={'billing'}
                  value={billing}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) => setBilling(target.value)}
                  error={checkForError('billing')}
                  fullWidth
                />
                <ValidationError field={'billing'} validationErrors={validationErrors} />
              </div>
            </div>
          </form>
          <FamDocInfoConfirmDialog
            open={openWarningDialog}
            onCancel={onWarningDialogCancel}
            onSave={onWarningDialogSave}
            emptyFamDocFields={missingFields}
          />
          <div className={styles.buttonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={localOnClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onPhysicianDialogSave}
            >
              {selectedPhysician ? 'Update' : 'Save'}
            </DialogButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default OtherPhysicianEditDialog;
