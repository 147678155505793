import React, { ReactElement } from 'react';
import classNames from 'classnames';

import Paper from 'components/Paper';
import DialogButton from 'components/DialogButton';
import ConditionDiv from 'components/ConditionDiv';

import styles from './ReceiptItemStyles.module.scss';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';

interface ReceiptItemProps {
  value?: {
    title?: string;
    receipt?: string;
    amount?: number;
    sent?: number;
    createdByFullName?: string;
    createdAt?: string;
  };
  loading?: boolean;
  className?: string;
  onDelete?(): void;
  onSend?(): void;
  viewReceipt?(): void;
}

function ReceiptItem({
  className,
  value,
  loading = false,
  onDelete,
  onSend,
  viewReceipt,
}: ReceiptItemProps): ReactElement {
  return (
    <Paper className={classNames(styles.padding, className)}>
      <div className={styles.padding}>
        <div className={styles.title} onClick={viewReceipt}>
          {value?.title}
        </div>
        <div className={styles.content}>Receipt: {value?.receipt}</div>
        <div className={styles.content}>Amount: ${value?.amount}</div>
        <div className={styles.content}>
          Created:{' '}
          {`${value?.createdByFullName} - ${formatDate(
            DateTimeFormat.DateWithFullMonthName,
            value?.createdAt
          )} `}
        </div>
        <div className={styles.buttons}>
          <ConditionDiv condition={!!onDelete}>
            <DialogButton onClick={onDelete} loading={loading}>
              Delete
            </DialogButton>
          </ConditionDiv>
          <DialogButton onClick={onSend} loading={loading}>
            <ConditionDiv condition={value?.sent == 1}>Send Again</ConditionDiv>
            <ConditionDiv condition={value?.sent == 0}>Send</ConditionDiv>
          </DialogButton>
        </div>
      </div>
    </Paper>
  );
}

export default ReceiptItem;
