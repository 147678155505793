import React, { memo, ReactElement, useEffect } from 'react';

import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { TaskDialogValidationError } from 'types';
import styles from './Autocomplete.module.scss';
import { ValidationError } from 'components/Forms/ValidationError';

interface AutocompleteProps {
  items?: Array<any>;
  value?: any;
  multiple?: boolean;
  name?: string;
  error?: string;
  label?: string;
  validationErrors?: Array<TaskDialogValidationError>;
  vaidationErrorKey?: string;
  placeholder?: string;
  customCharacterSearchCount?: number;
  onChange?: (event, newValue) => void;
  onInputChange?: (event) => void;
  getOptionLabel?: (item) => string;
  fullWidth?: boolean;
  disabled?: boolean;
  freeSolo?: boolean;
}

function Autocomplete({
  value = null,
  multiple = false,
  error,
  name,
  items = [],
  validationErrors = [],
  vaidationErrorKey = '',
  label,
  fullWidth = false,
  placeholder,
  customCharacterSearchCount,
  onChange,
  onInputChange,
  getOptionLabel,
  disabled = false,
  freeSolo,
}: AutocompleteProps): ReactElement {
  const [open, setOpen] = React.useState(false);

  function handleDropdownBehaviourOnSearchCharacterCount(event): void {
    const value = event?.target?.value || '';

    if (customCharacterSearchCount) {
      if (value.length < customCharacterSearchCount) {
        setOpen(false);
        return;
      }
    }

    setOpen(true);
  }

  const onChangeInput = (event, value) => {
    onChange && onChange(event, value);
    setOpen(false);
  };

  function autoVerification(event, newValue) {
    let result;
    if (Array.isArray(newValue) && value.length < newValue.length) {
      result = value.find(item => item.id === newValue[value.length].id);
    }
    if (!result) onChangeInput(event, newValue);
  }

  useEffect(() => {
    setOpen(false);
  }, [value]);

  return (
    <div>
      <MuiAutocomplete
        multiple={multiple}
        value={multiple ? value || [] : value}
        open={open}
        openOnFocus={true}
        blurOnSelect={true}
        onChange={(event, newValue) => autoVerification(event, newValue)}
        disabled={disabled}
        onInputChange={event => {
          handleDropdownBehaviourOnSearchCharacterCount(event);
        }}
        getOptionLabel={getOptionLabel}
        renderInput={params => (
          <TextField
            {...params}
            error={!!error}
            label={label}
            name={name}
            placeholder={placeholder}
            onChange={onInputChange}
            fullWidth={fullWidth}
            helperText={error}
            onFocus={() => {
              setOpen(true);
            }}
            onBlur={() => {
              setOpen(false);
            }}
          />
        )}
        options={items}
        freeSolo={freeSolo}
      />
      <ValidationError field={vaidationErrorKey} validationErrors={validationErrors} />
    </div>
  );
}

export default memo(Autocomplete);
