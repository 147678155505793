import React, { ReactElement } from 'react';
import { Avatar } from '@material-ui/core';

import ConditionDiv from 'components/ConditionDiv';
import { protectByRole } from '../../../App/routing/utils/protectByRole';
import { ROLE } from 'lib/user';

import { ReactComponent as Task } from 'assets/svgs/Task.svg';
import styles from './styles.module.scss';

const protectedForStaff = protectByRole(
  [ROLE.ADMIN, ROLE.RECEPTIONIST, ROLE.DOCTOR, ROLE.TECHNICIAN, ROLE.NURSE],
  () => null
);

const TaskProtectForUser = protectedForStaff(({ onClick }: any) => <Task onClick={onClick} />);

function Nav(props): ReactElement {
  return (
    <div className={styles.nav}>
      <TaskProtectForUser className={styles.taskIcon} onClick={props?.onTaskClick} />
      <ConditionDiv condition={props?.tasks?.length > 0} className={styles.newTask} />
      <Avatar className={styles.iconProfile} onClick={props?.onClick} />
      {/* <div className={styles.iconProfile} onClick={props?.onClick} /> */}
    </div>
  );
}

export default Nav;
