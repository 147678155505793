import { useState, useEffect } from 'react';
import { Receipt } from 'types';
import { ReceiptApi } from 'api';

import { getCaseByUserId } from 'api/case';

export interface ReceiptsState {
  loading: boolean;
  receipts: Array<Receipt>;
  finalizeReceipt(id, params): void;
  createReceipt(value, paymentRows, invoiceRows): Promise<any>;
  pushReceiptToS3(signedRequest, document): Promise<any>;
  getReceipts(order?: string): Promise<void>;
  sendReceipt(id: number): Promise<void>;
  deleteReceipt(id: number): Promise<void>;
  getReceiptUrl(id: number): Promise<string>;
  hardDeleteReceipt(id: number): Promise<void>;
}

function useReceiptApi(patientId: number): ReceiptsState {
  const [caseId, setCaseId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);

  async function getReceipts(order = 'desc'): Promise<void> {
    setLoading(true);

    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        setCaseId(cases[0].id);
        const receipts = await ReceiptApi.getReceipts(cases[0].id, order);
        setReceipts(receipts);
      }
    } catch (e) {
      console.log('useReceiptApi', e);
    }
    setLoading(false);
  }

  async function createReceipt(value, paymentRows, invoiceRows): Promise<void> {
    setLoading(true);
    let receiptData;
    try {
      value.paymentRows = paymentRows;
      value.invoiceRows = invoiceRows;
      receiptData = await ReceiptApi.createReceipts(caseId, value);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    return receiptData;
  }

  async function pushReceiptToS3(signedRequest: string, document) {
    setLoading(true);
    let addedToS3;
    try {
      addedToS3 = await ReceiptApi.pushReceiptToS3(signedRequest, document);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    return addedToS3;
  }

  async function finalizeReceipt(id: number, params) {
    setLoading(true);
    let finalReceipt;

    try {
      finalReceipt = await ReceiptApi.completeRecieptCreation(id, { ...params, pdfCreated: true });
      if (finalReceipt) {
        setReceipts([finalReceipt, ...receipts]);
      }
    } catch (err) {
      console.log(err);
      try {
        await ReceiptApi.completeRecieptCreation(id, { pdfCreated: false });
      } catch (err2) {
        console.log(err2);
      }
    }
    setLoading(false);
    return finalReceipt;
  }

  async function sendReceipt(id): Promise<void> {
    setLoading(true);
    try {
      await ReceiptApi.sendReceipt(caseId, id);
      await getReceipts();
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function getReceiptUrl(id: number): Promise<string> {
    const result = await ReceiptApi.getTemporaryReceiptUrl(id);
    return result.signedRequest;
  }

  async function deleteReceipt(id): Promise<void> {
    setLoading(true);
    try {
      await ReceiptApi.deleteReceipt(caseId, id);
      await getReceipts();
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function hardDeleteReceipt(id): Promise<void> {
    setLoading(true);
    try {
      await ReceiptApi.hardDeleteReceipt(caseId, id);
      await getReceipts();
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  return {
    loading,
    receipts,
    getReceiptUrl,
    finalizeReceipt,
    pushReceiptToS3,
    getReceipts,
    createReceipt,
    sendReceipt,
    deleteReceipt,
    hardDeleteReceipt,
  };
}

export default useReceiptApi;
