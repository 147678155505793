import { useState, useEffect, useContext } from 'react';

import moment from 'moment';
import { AppointmentsApi, UserApi } from 'api';
import { Context as ProfileContext } from 'context/profile';
import { fetchUser } from 'actions/userActions';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import { transformUserToStaff } from 'api/user/user.transformer';
import useProfileRoles from 'hook/useProfileRoles.hook';
import { User, ContactType, DailyAppointment, SubType } from 'types';
import { createPatient } from 'api/user';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import { AppointmentStatus, APPOINTMENT_TYPES, APPOINTMENT_TYPE_IDS } from 'lib/appointments';

function useAppointmentsApi() {
  const [user, setUser] = useState<any>(null);
  const [locationIds, setLocationIds] = useState<Array<number>>([]);
  const [deleting, setDeleting] = useState(false);
  const profilecontext = useContext(ProfileContext);
  const [events, setEvents] = useState<Array<AppointmentInfo>>([]);
  const [allUnfilteredEvents, setUnfilteredEvents] = useState<Array<AppointmentInfo>>([]);
  const [staffList, setStaffList] = useState<Array<UserStaff>>([]);
  const [staffListForLocation, setStaffListForLocation] = useState<Array<UserStaff>>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number>(-1);
  const [eventToDelete, setEventToDelete] = useState<AppointmentInfo | DailyAppointment | null>(
    null
  );
  const [subTypes, setSubTypes] = useState<Array<SubType>>([]);
  const [eventToEdit, setEventToEdit] = useState<AppointmentInfo | null>(null);
  const [provider, setProvider] = useState<number | null>(null);
  const [appointmentStatus, setAppointmentStatus] = useState<number | null>(-1);
  const [referrerStatusId, setReferrerStatusId] = useState<number | null>(null);
  const [date, changeDate] = useState(moment());
  const [typeId, setTypeId] = useState<string | number | null>(-1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [staff, setStaff] = useState<User | null>(null);
  const { isDoctor, isTechnician } = useProfileRoles();
  const isDocOrTech = isDoctor || isTechnician;
  // Non admin user should only get there days info by default
  const startingCalendarView = isDocOrTech ? 'day' : 'week';
  const [calendarView, changeCalendarView] = useState<'week' | 'day'>(startingCalendarView);
  const [showOnlyCancellationAppts, setShowOnlyCancellationAppts] = useState<boolean>(false);
  const [highlightOnlyCancellationAppts, setHighlightOnlyCancellationAppts] = useState<boolean>(
    false
  );

  const [selectedStaff, setSelectedStaff] = useState<number | null>(-1);

  async function getUser(): Promise<void> {
    const response = await fetchUser();
    setUser(response);
  }

  async function filterAppts(eventsToFilter) {
    const filtered = eventsToFilter.filter(unfilteredEvent => {
      if (unfilteredEvent.cancellationList) {
        return unfilteredEvent;
      }
    });
    setEvents(filtered);
  }

  async function getTypes(): Promise<void> {
    const subTypes = await AppointmentsApi.getTypes();
    let allSubTypes: Array<SubType> = [];
    APPOINTMENT_TYPES.forEach(type => (allSubTypes = [...allSubTypes, ...subTypes[type.id]]));
    setSubTypes(allSubTypes);
  }

  async function getAppointments(): Promise<void> {
    // Non admin user should only get there info
    const staffIDUse = isDocOrTech ? profilecontext?.user?.staffInfo?.id : undefined;
    const ignoreStatusIds = [AppointmentStatus.Cancelled, AppointmentStatus.Deleted];

    if (locationIds.length > 0 && date) {
      try {
        const response = await AppointmentsApi.getAppointments(
          date
            .clone()
            .startOf(calendarView)
            .toDate()
            .toISOString(),
          date
            .clone()
            .endOf(calendarView)
            .toDate()
            .toISOString(),
          locationIds,
          ignoreStatusIds,
          typeId === -1 ? null : typeId,
          provider == -1 ? null : provider,
          searchQuery,
          staffIDUse,
          appointmentStatus === -1 ? null : appointmentStatus,
          referrerStatusId === -1 ? null : referrerStatusId
        );
        setEvents(response);
        setUnfilteredEvents(response);

        if (showOnlyCancellationAppts) {
          await filterAppts(response);
        }
      } catch (e) {
        setEvents([]);
        setUnfilteredEvents([]);
        console.log('Error getting appointments', e);
      }
    }
  }

  async function cancellationListOnly(value) {
    setShowOnlyCancellationAppts(value);
    if (value) {
      filterAppts(allUnfilteredEvents);
    } else {
      setEvents(allUnfilteredEvents);
    }
    setShowOnlyCancellationAppts(value);
  }

  async function refreshAppointments() {
    await getAppointments();
  }

  async function deleteAppointment(): Promise<boolean> {
    setDeleting(true);
    try {
      if (eventToDelete) {
        await AppointmentsApi.deleteAppointment(eventToDelete.appointmentId);
        setEventToDelete(null);
        await refreshAppointments();
      }
      setDeleting(false);
      return true;
    } catch (e) {
      setDeleting(false);
      console.log(e);
      return false;
    }
  }

  async function deletePersonalTime(): Promise<boolean> {
    setDeleting(true);
    try {
      if (eventToDelete) {
        await AppointmentsApi.deletePersonalTime(eventToDelete.appointmentId);
        setEventToDelete(null);
        await refreshAppointments();
      }
      setDeleting(false);
      return true;
    } catch (e) {
      setDeleting(false);
      console.log(e);
      return false;
    }
  }

  async function getStaff(): Promise<void> {
    const users = await UserApi.getStaff('', undefined, '');
    let staffList: UserStaff[] = [];

    if (users) staffList = await transformUserToStaff(users);

    setStaffList(staffList);
  }

  async function getStaffForLocation(locationId: number): Promise<void> {
    const users = await UserApi.getStaff('', undefined, locationId);
    let staffList: UserStaff[] = [];

    if (users) staffList = await transformUserToStaff(users);

    setStaffListForLocation(staffList);
  }

  async function updateAppointment(id, data, refresh = true) {
    const response = await AppointmentsApi.update(id, data);
    if (refresh) await getAppointments().catch();
    return response;
  }

  async function updateAppointmentOnDrag(data: any): Promise<void> {
    const body = {
      id: data.event.id,
      startTime: data.start,
      endTimeExpected: data.end,
      caseId: data.event.caseId,
      locationId: data.event.location.id,
      staffId: data.event.staff.id,
      statusId: data.event.status.id,
      anyDoctor: data.event.anyDoctor,
      description: data.event.description,
    };

    if (body.anyDoctor) {
      const availableDoctors = await AppointmentsApi.getDoctorsAvailableForTimeSlot(
        moment(body.startTime).toISOString(true),
        body.locationId,
        body.startTime.toISOString(true),
        body.endTimeExpected.toISOString(true)
      );
      if (availableDoctors.length > 0) {
        body.staffId = availableDoctors[0].id;
      } else {
        body.staffId = null;
      }
    }

    if (
      (moment(data.event.end).isAfter(moment()) ||
        moment(data.event.end).isSame(moment(), 'day')) &&
      (moment(data.end).isAfter(moment()) || moment(data.end).isSame(moment(), 'day'))
    ) {
      updateAppointment(data.event.id, body);
    }
  }

  async function saveNewPatient(data): Promise<User | null> {
    const sex = data.gender === 'Female' ? 'F' : 'M';

    return createPatient({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      userType: 'patient',
      contacts: [
        {
          type: ContactType.Phone,
          value: data.phone,
        },
        {
          type: ContactType.Cell,
          value: data.cellPhone,
        },
        {
          type: ContactType.Home,
          value: data.homePhone,
        },
      ],
      patientInfo: {
        healthCardProvinceCode: data.healthCardProvinceCode,
        hcn: data.hcn,
        healthCard: data.healthCard,
        gender: data.gender,
        sex: sex,
        dateOfBirth: formatDate(DateTimeFormat.APIDateFormat, data.dateOfBirth).toString(),
        referrerFirstName: data.referrerFirstName,
        referrerLastName: data.referrerLastName,
        referrerStatusId: data.referrerStatusId,
        familyPhysicianFirstName: data.familyPhysicianFirstName,
        familyPhysicianLastName: data.familyPhysicianLastName,
        familyPhysicianEmail: data.familyPhysicianEmail,
        familyPhysicianPhone: data.familyPhysicianPhone,
        familyPhysicianFax: data.familyPhysicianFax,
        familyPhysicianBilling: data.familyPhysicianBilling,
      },
    });
  }

  useEffect((): void => {
    getStaff();
    getTypes();
    getUser();
  }, []);

  useEffect(() => {
    if (selectedLocationId > 0) getStaffForLocation(selectedLocationId);
  }, [selectedLocationId]);

  useEffect((): void => {
    getAppointments();
  }, [
    date,
    locationIds,
    searchQuery,
    typeId,
    calendarView,
    provider,
    appointmentStatus,
    referrerStatusId,
  ]);

  return {
    user,
    locationIds,
    deleting,
    staff,
    events,
    staffList,
    eventToDelete,
    eventToEdit,
    provider,
    appointmentStatus,
    referrerStatusId,
    date,
    typeId,
    calendarView,
    searchQuery,
    selectedLocationId,
    staffListForLocation,
    selectedStaff,
    subTypes,
    highlightOnlyCancellationAppts,
    setSelectedStaff,
    setSelectedLocationId,
    setStaffListForLocation,
    setSearchQuery,
    changeCalendarView,
    setStaff,
    setTypeId,
    changeDate,
    setProvider,
    setAppointmentStatus,
    setReferrerStatusId,
    setEventToDelete,
    setEventToEdit,
    updateAppointment,
    updateAppointmentOnDrag,
    setLocationIds,
    deleteAppointment,
    deletePersonalTime,
    refreshAppointments,
    saveNewPatient,
    cancellationListOnly,
    setHighlightOnlyCancellationAppts,
  };
}

export default useAppointmentsApi;
