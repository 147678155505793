import React, { memo, ReactElement, useEffect } from 'react';
import classNames from 'classnames';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { AppointmentsApi } from 'api';
import { getAppointmentStatusNameById, AppointmentStatus } from 'lib/appointments';

import styles from './SaveAppointmentDialog.module.scss';

interface SaveAppointmentDialogProps {
  open: boolean | undefined;
  error: string;
  message: string;
  existingAppointmentId: number;
  responseAppt?: any;
  appointmentCreated: boolean | undefined;
  closeConfirmation: () => void;
  onClose: () => void;
  finalActions?: () => void;
}

function SaveAppointmentDialog({
  open,
  error,
  message,
  existingAppointmentId,
  responseAppt,
  appointmentCreated,
  onClose,
  closeConfirmation,
  finalActions,
}: SaveAppointmentDialogProps): ReactElement {
  const sendEmailConfirmation = async () => {
    onClose();
    let deleted = false;
    const deletedStatus = getAppointmentStatusNameById(AppointmentStatus.Deleted);
    const cancelledStatus = getAppointmentStatusNameById(AppointmentStatus.Cancelled);
    const status = responseAppt.status ? responseAppt.status : responseAppt.appointmentStatus;
    const appointmentId = responseAppt.id ? responseAppt.id : responseAppt.appointmentId;
    if (
      status == deletedStatus ||
      status == cancelledStatus ||
      status == AppointmentStatus.Deleted
    ) {
      deleted = true;
    }
    if (responseAppt) {
      await AppointmentsApi.mailPatientAppointment(appointmentId, deleted);
    }
  };

  useEffect(() => {
    if (finalActions && appointmentCreated) {
      finalActions();
    }
  }, [appointmentCreated]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={
        existingAppointmentId > 0
          ? 'Edit Appointment'
          : existingAppointmentId < 0
          ? 'Delete Appointment'
          : 'New Appointment'
      }
    >
      {appointmentCreated ? (
        <div className={styles.container}>
          <div className={styles.containerHeader}>
            <div className={styles.appointmentOpeningInfo}>
              {responseAppt?.patient_email || responseAppt?.patient?.email ? (
                <p>
                  {message} Do you want to email the patient
                  {existingAppointmentId < 0
                    ? ' regarding the cancellation?'
                    : ' the appointment details?'}
                </p>
              ) : (
                <p>Patient email is missing. Appointment email cannot be sent to the patient.</p>
              )}
            </div>
          </div>
          <div className={classNames(styles.buttonSection)}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              {responseAppt?.patient_email || responseAppt?.patient?.email ? 'No' : 'Ok'}
            </DialogButton>
            {responseAppt?.patient_email || responseAppt?.patient?.email ? (
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                className={styles.btn}
                onClick={sendEmailConfirmation}
              >
                Yes
              </DialogButton>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.containerHeader}>
            <div className={styles.appointmentOpeningInfo}>
              <p>{error}</p>
            </div>
          </div>
          <div className={classNames(styles.buttonSection)}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={closeConfirmation}
            >
              Go back
            </DialogButton>
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default memo(SaveAppointmentDialog);
