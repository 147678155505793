// The build date
export const buildDate = process.env.REACT_APP_BUILD_TIME
  ? process.env.REACT_APP_BUILD_TIME
  : 'Development';

// The base API url for taxplan.api
export const baseAPIUrl = process.env.REACT_APP_API_URL;

// Jwt specific data
export const jwtData = {
  refreshUrl: '/users/refreshJwt',
  authToken: 'auth_token',
  refreshToken: 'refresh_token',
};

export const REFRESH_INTERVAL: number = 20 * 60 * 1000;

export const LOGIN: string = baseAPIUrl + '/login';
export const GET_FORM: string = baseAPIUrl + '/forms';
export const GET_MESSAGE: string = baseAPIUrl + '/message';
export const GET_CASE: string = baseAPIUrl + '/cases';
export const GET_RECEIPTS: string = baseAPIUrl + '/receipts';
export const GET_TASKS: string = baseAPIUrl + '/tasks';
export const GET_CASES: string = baseAPIUrl + '/cases';
export const GET_FAX: string = baseAPIUrl + '/fax';
export const REFRESH_TOKEN: string = baseAPIUrl + '/refreshToken';
export const GET_MY_USER_INFO: string = baseAPIUrl + '/users/me';
export const GET_USERS: string = baseAPIUrl + '/users';
export const GET_STAFF: string = baseAPIUrl + '/staff';
export const GET_PATIENTS_LIST: string = baseAPIUrl + '/patients';
export const GET_OTHER_PHYSICIANS_LIST: string = baseAPIUrl + '/otherPhysicians';
export const GET_LOCATIONS: string = baseAPIUrl + '/locations';
export const GET_LOCATION: string = baseAPIUrl + '/location';
export const GET_APPOINTMENTS = baseAPIUrl + '/appointments';
export const FORGOT_PASSWORD = baseAPIUrl + '/forgotPassword';
export const RESET_PASSWORD = baseAPIUrl + '/resetPassword';
export const GET_CASE_TYPES: string = baseAPIUrl + '/documents/types';
export const CREATE_PATIENT = baseAPIUrl + '/patients';
export const CREATE_STAFF = baseAPIUrl + '/staff';
export const CREATE_OTHER_PHYSICIAN = baseAPIUrl + '/otherPhysicians';
export const GET_DOCUMENT = baseAPIUrl + '/documents';
export const COMPOSITE_IMAGE: string = baseAPIUrl + '/getCompositeImages';
export const GET_SCHEDULE_EXCEPTION: string = baseAPIUrl + '/scheduleExceptions';
export const GET_AVAILABLE_STAFF: string = baseAPIUrl + '/availableStaff';
export const SIGNATURE_DOWNLOAD: string = baseAPIUrl + '/signatureDownload';
export const APIDATEFORMAT = 'YYYY-MM-DD';
export const VALIDATE_HEALTH_CARD: string = baseAPIUrl + '/getPatientDataFromHealthcard';
