import React, { ReactElement } from 'react';

import Dialog from 'components/Dialog';
import styles from './ActionConfirmationDialogStyles.module.scss';
import Button from '@material-ui/core/Button';

interface ActionConfirmationDialogProps {
  open: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

function ActionConfirmationDialog({
  open,
  title,
  description,
  onClose,
  onConfirm,
}: ActionConfirmationDialogProps): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.text}>{description}</div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>No</Button>
          <Button onClick={onConfirm}>Yes</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ActionConfirmationDialog;
