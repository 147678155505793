import React, { ReactElement } from 'react';
import { IconButton } from '@material-ui/core';

import styles from './DocumentStyles.module.scss';

import Paper from 'components/Paper';

import { ReactComponent as DarkPlusIcon } from 'assets/svgs/DarkPlus.svg';
import { ReactComponent as RubishIcon } from 'assets/svgs/Rubish.svg';

interface DocumentProps {
  title?: string;
  className?: string;
  allowDelete?: boolean;
  addDocument: (() => void) | null;
  deleteDocument: () => void;
  previewDocument: () => void;
}

function Document({
  title,
  className,
  allowDelete,
  addDocument,
  deleteDocument,
  previewDocument,
}: DocumentProps): ReactElement {
  return (
    <Paper className={className}>
      <div className={styles.main}>
        {addDocument ? (
          <IconButton aria-label="add" className={styles.remove} onClick={addDocument}>
            <DarkPlusIcon title={'add'} />
          </IconButton>
        ) : null}
        <div className={styles.content} onClick={previewDocument}>
          <div className={styles.title}>{title}</div>
        </div>

        {allowDelete ? (
          <IconButton aria-label="delete" className={styles.remove} onClick={deleteDocument}>
            <RubishIcon title={'delete'} />
          </IconButton>
        ) : null}
      </div>
    </Paper>
  );
}

export default Document;
