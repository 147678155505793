import { useState, useEffect } from 'react';
import { Event } from 'react-big-calendar';
import moment from 'moment';
import { getUserSchedule } from 'api/user';
import { Schedule } from 'api/user/user.interfaces';

export interface DoctorScheduleState {
  loading: boolean;
  schedule: Array<Schedule>;
  events: Array<Event>;
  refresh(): void;
}

function toEventType(schedule: Array<Schedule>): Array<Event> {
  const events: Array<Event> = [];

  schedule.forEach(scheduleRecord => {
    scheduleRecord.schedule.forEach(timeSlot => {
      const mStartTime = moment(timeSlot.startTime, 'hh:mm:s');
      const mEndTime = moment(timeSlot.endTime, 'hh:mm:s');

      const start = moment(scheduleRecord.date);
      start.hours(mStartTime.hours());
      start.minutes(mStartTime.minutes());
      start.seconds(mStartTime.seconds());

      const end = moment(scheduleRecord.date);
      end.hours(mEndTime.hours());
      end.minutes(mEndTime.minutes());
      end.seconds(mEndTime.seconds());

      const event: Event = {
        start: start.toDate(),
        end: end.toDate(),
        title: timeSlot.location?.name,
        resource: timeSlot.needsToBeChanged,
      };

      events.push(event);
    });
  });

  return events;
}

function useDoctorSchedule(doctorId: number, start: string, end: string): DoctorScheduleState {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<Array<Event>>([]);
  const [schedule, setSchedule] = useState<Array<Schedule>>([]);

  async function fetchSchedule(doctorId: number, start: string, end: string): Promise<void> {
    setLoading(true);

    try {
      const schedule = await getUserSchedule(doctorId, start, end);
      setSchedule(schedule);
      setEvents(toEventType(schedule));
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchSchedule(doctorId, start, end).catch();
  }, [doctorId, start, end]);

  return {
    loading,
    schedule,
    events,
    refresh(): void {
      fetchSchedule(doctorId, start, end).catch();
    },
  };
}

export default useDoctorSchedule;
