import { sendMultipleDocuments as sendMultipleFaxDocumentsAPI } from 'api/fax';

export interface FaxState {
  sendMultipleFaxDocuments: (
    documentIds: (string | number)[],
    startingId: number,
    faxNumber: string | undefined,
    generic?: boolean
  ) => void;
}

function useFaxApi(): FaxState {
  async function sendMultipleFaxDocuments(
    documentIds,
    startingId,
    faxNumber,
    generic = false
  ): Promise<void> {
    try {
      return await sendMultipleFaxDocumentsAPI(documentIds, startingId, faxNumber, generic);
    } catch (e) {
      console.log('Error', e);
      return Promise.reject(e);
    }
  }

  return {
    sendMultipleFaxDocuments,
  };
}

export default useFaxApi;
