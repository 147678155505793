import React, { ReactElement, useEffect, useState } from 'react';

import ConditionDiv from 'components/ConditionDiv';
import TitledSection from 'components/TitledSection';
import Button from 'components/Button';
import { formatDate, DateTimeFormat } from 'lib/dateFormatter';

import Form from './components/Form';
import SendFormDialog from './components/SendFormDialog';
import useFormsApi from './useFormsApi.hook';
import { FormStatus, FormStatusTypesSelection } from 'lib/form';

import styles from './FormsStyles.module.scss';
import CopyFormUrlDialog from './components/CopyFormUrlDialog';
import CreateNewSetOfFormsConfirmationDialog from '../../components/CreateNewSetOfFormsConfirmationDialog/CreateNewSetOfFormsConfirmationDialog.view';
import SuccessSnackBar from 'components/SuccessSnackBar';
import { AlertDialog } from 'components/AlertDialog';
import InputSearch from 'pages/Staff/components/InputSearch';
import { MenuItem } from '@material-ui/core';
import Select from 'components/Select';
import { FormTemplate, Form as FormType } from 'types';

function createInfoString(date: number | string | undefined, status): string {
  const formattedDate = formatDate(DateTimeFormat.DateWithFullMonthName, date);
  if (status === FormStatus.Complete || status === FormStatus.SentToPatient) {
    return `${status}: ${formattedDate}`;
  }
  return status;
}

function Forms(props): ReactElement {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [copyFormUrlDialogOpen, setCopyFormUrlDialogOpen] = useState<boolean>(false);

  const patientId = props?.match?.params?.patientId;

  const [searchQuery, setSearchQuery] = useState<string | undefined>('');

  const [filteredForms, setFilteredForms] = useState<Array<FormType>>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [templatesList, setTemplatesList] = useState<Array<FormTemplate>>([]);

  const {
    forms,
    formsReadyToSend,
    loading,
    sendForm,
    caseId,
    errorMessage,
    getNewSetsOfForms,
    getNewCopyOfForm,
    setActionFailed,
    setActionSuccess,
    setSuccessSnackbarMessage,
    successSnackbarMessage,
    actionFailed,
    actionSuccess,
  } = useFormsApi(patientId);

  function createNewForms(): void {
    getNewSetsOfForms(caseId);
  }

  const duplicateForm = formId => {
    getNewCopyOfForm(formId);
  };

  const [sendFormDialogOpen, setSendFormDialogOpen] = useState(false);

  function showConfirmDialogBox(): void {
    setShowConfirmDialog(true);
  }

  function onConfirm(): void {
    createNewForms();
    setShowConfirmDialog(false);
  }

  function closeConfirmDialogBox(): void {
    setShowConfirmDialog(false);
  }

  function closeCopyFormUrlDialog(): void {
    setCopyFormUrlDialogOpen(false);
  }
  function mainFormsCompleted(forms): boolean {
    if (!forms) {
      return false;
    }
    const mainForms = forms.filter(form => {
      if (form.key === 'Doctor' || form.key === 'Patient' || form.key === 'Tech') {
        return form;
      }
    });

    if (!mainForms) {
      return false;
    }
    return mainForms.every(form => {
      return FormStatus.Complete === form.status;
    });
  }

  function reviewReport(): void {
    props?.history?.push(`/case/${caseId}/workflowResult`, {
      patientId: patientId,
      everyFormCompleted: mainFormsCompleted(forms),
    });
  }

  function getTemplateList() {
    const types: Array<FormTemplate> = [{ id: 0, title: 'All Types' }];
    forms.forEach(form => {
      if (!types.find(type => type.id == form.template_type_id)) {
        types.push({ id: form.template_type_id, title: form.type });
      }
    });
    setTemplatesList(types);
  }

  useEffect(() => {
    setFilteredForms(forms);
    getTemplateList();
  }, [forms]);

  async function onTemplateChange(e) {
    setSelectedTemplate(e?.target?.value);
  }

  async function onStatusChange(e) {
    setSelectedStatus(e?.target?.value);
  }
  useEffect(() => {
    const filterStatus = selectedStatus > 0;
    const filterTemplate = selectedTemplate > 0;
    const filterSearch = searchQuery && searchQuery.length >= 2 ? searchQuery : '';

    const filteredForms = forms.filter(form => {
      const fStatus = !filterStatus || selectedStatus == form.status_id;
      const fTemplate = !filterTemplate || selectedTemplate == form.template_type_id;
      const fSearch =
        !filterSearch ||
        (searchQuery &&
          form.description &&
          form.description.toLowerCase().includes(searchQuery.toLowerCase()));

      return fStatus && fTemplate && fSearch;
    });
    setFilteredForms(filteredForms);
  }, [selectedStatus, selectedTemplate, searchQuery]);

  const headContent = (
    <div>
      <div className={styles.formListButtonContainer}>
        <div className={styles.searchBar}>
          <InputSearch value={searchQuery} onChange={event => setSearchQuery(event.target.value)} />
        </div>
        <div className={styles.folder}>
          <Select fullWidth name={'template'} onChange={onTemplateChange} value={selectedTemplate}>
            {templatesList?.map(item => (
              <MenuItem key={item.id} value={item.id} className={styles.selectItem}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.folder}>
          <Select fullWidth name={'status'} onChange={onStatusChange} value={selectedStatus}>
            {FormStatusTypesSelection.map(item => (
              <MenuItem key={item.id} value={item.id} className={styles.selectItem}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </div>
        <ConditionDiv condition={mainFormsCompleted(forms)}>
          <div className={styles.button}>
            <Button onClick={showConfirmDialogBox}>Create New Forms</Button>
          </div>
        </ConditionDiv>
        <div className={styles.button}>
          <Button onClick={reviewReport}>Review Report</Button>
        </div>
        <div className={styles.button}>
          <Button onClick={(): void => setCopyFormUrlDialogOpen(true)}>Copy Form URL</Button>
        </div>
        <div className={styles.button}>
          <Button onClick={(): void => setSendFormDialogOpen(true)}>Send form</Button>
        </div>
      </div>
    </div>
  );

  function onSendForm(formId: number): void {
    const form = forms.find(form => form.id === formId);
    if (form && form.document_url) {
      props?.history?.push(`/formDocument/${formId}`, {
        patientId: patientId,
        caseId: caseId,
        sendToPatient: true,
      });
      setSendFormDialogOpen(false);
    } else {
      sendForm(formId).then(() => {
        setActionSuccess(true);
        setSendFormDialogOpen(false);
      });
    }
  }

  return (
    <TitledSection title="Forms" headContent={headContent}>
      <ConditionDiv condition={!!forms.length}>
        {filteredForms.map(form => (
          <Form
            id={form.id}
            key={`form-${form.id}`}
            className={styles.form}
            title={form.description}
            info={createInfoString(form.completed_at, form.status)}
            statusId={form.status_id}
            createdAt={formatDate(DateTimeFormat.DateWithFullMonthName, form.created_at)}
            onClick={(): void =>
              props?.history?.push(`${form.document_url ? '/formDocument/' : '/form/'}${form.id}`, {
                patientId: patientId,
                caseId: caseId,
                sendToPatient: false,
              })
            }
            duplicateForm={duplicateForm}
          />
        ))}
      </ConditionDiv>
      <ConditionDiv condition={!forms.length}>Currently no forms</ConditionDiv>
      <SendFormDialog
        forms={formsReadyToSend}
        loading={loading}
        open={sendFormDialogOpen}
        onSend={onSendForm}
        onClose={(): void => setSendFormDialogOpen(false)}
      />
      <CreateNewSetOfFormsConfirmationDialog
        show={showConfirmDialog}
        onClose={closeConfirmDialogBox}
        onConfirm={onConfirm}
        reviewReport={reviewReport}
      />
      <CopyFormUrlDialog
        open={copyFormUrlDialogOpen}
        forms={formsReadyToSend}
        patientId={patientId}
        onClose={closeCopyFormUrlDialog}
        loading={loading}
      />
      <SuccessSnackBar
        message={successSnackbarMessage}
        open={actionSuccess}
        setOpen={setActionSuccess}
      />
      <AlertDialog
        title={'Error Creating New Form'}
        message={errorMessage}
        open={actionFailed}
        onClose={() => setActionFailed(false)}
      />
    </TitledSection>
  );
}

export default Forms;
