import { FormStatus as FormStatusType } from 'types';

export enum FormStatus {
  NeedsToBeFilled = 'Needs to be filled',
  InProgress = 'In progress',
  Complete = 'Complete',
  SentToPatient = 'Sent to patient',
}

export enum FormStatusId {
  NeedsToBeFilled = 1,
  InProgress = 2,
  Complete = 3,
  SentToPatient = 4,
}

export const FormDescriptions = {
  FOLLOW_UP: 'Follow-Up Form',
  REQUISITION: 'Requisition Form',
  NON_MEDICAL_PRESCRIPTION: 'Non-Medical Prescription Form',
  MEDICAL_PRESCRIPTION: 'Medical Prescription Form',
};

/**
 * The form template question Ids are set individually to each form.
 * The numbers assigned here match the database and are needed to
 * programmatically change the values of the fields when selecting a doctor
 * from the dropdown
 **/
export const formTemplateQuestionIds = {
  [FormDescriptions.REQUISITION]: { doctor: 186, billing: 187, fax: 188 },
  [FormDescriptions.NON_MEDICAL_PRESCRIPTION]: { doctor: 221, billing: 222, fax: 223 },
  [FormDescriptions.MEDICAL_PRESCRIPTION]: { doctor: 259, billing: 260, fax: 261 },
};

export const FormStatusTypesSelection: Array<FormStatusType> = [
  { id: 0, title: 'All Statuses' },
  { id: FormStatusId.NeedsToBeFilled, title: FormStatus.NeedsToBeFilled },
  { id: FormStatusId.InProgress, title: FormStatus.InProgress },
  { id: FormStatusId.Complete, title: FormStatus.Complete },
  { id: FormStatusId.SentToPatient, title: FormStatus.SentToPatient },
];
