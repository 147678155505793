import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { LoginContainer } from 'pages/Login';
import PatientPage from 'pages/Patient';
import FaxPage from 'pages/Fax';
import DoctorPage from 'pages/Doctor';
import Form from 'pages/Form/Form.container';
import FormDocument from 'pages/FormDocument/FormDocument.container';
import DocumentEdit from 'pages/DocumentEdit/DocumentEdit.container';
import PatientFormContainer from 'pages/PatientForm/PatientForm.container';
import PatientFormDocumentContainer from 'pages/PatientFormDocument/PatientFormDocument.container';
import PatientMessageContainer from 'pages/MessageReply/MessageReply.container';
import { ResetPasswordContainer } from 'pages/ResetPassword';
import { AppointmentContainer } from 'pages/Appointments';
import { StaffContainer } from 'pages/Staff';
import { PatientListContainer } from 'pages/PatientList';
import { ForgotPasswordContainer } from 'pages/ForgotPassword';
import { LocationsContainer } from 'pages/Locations';
import { ROLE } from 'lib/user';

import { protectByRole } from './utils/protectByRole';
import WorkflowResultPage from 'pages/WorkflowResult';

const protectedForAdmin = protectByRole([ROLE.ADMIN], () => <Redirect to="/login" />);

const protectedForAdminAndReceptionist = protectByRole([ROLE.ADMIN, ROLE.RECEPTIONIST], () => (
  <Redirect to="/login" />
));

const protectedForStaff = protectByRole(
  [ROLE.ADMIN, ROLE.RECEPTIONIST, ROLE.DOCTOR, ROLE.TECHNICIAN, ROLE.NURSE],
  () => <Redirect to="/login" />
);

const protectedForAuthorized = protectByRole(
  [ROLE.ADMIN, ROLE.RECEPTIONIST, ROLE.DOCTOR, ROLE.TECHNICIAN, ROLE.NURSE, ROLE.PATIENT],
  () => <Redirect to="/login" />
);

const protectedForDoctor = protectByRole([ROLE.ADMIN, ROLE.DOCTOR], () => <Redirect to="/login" />);

const AppointmentsProtectedForAdminAndUser = protectedForStaff(AppointmentContainer);
const StaffProtectedForAdmin = protectedForAdmin(StaffContainer);
const PatientListProtectedForAdminAndUser = protectedForStaff(PatientListContainer);
const FormProtectedForAdminAndUser = protectedForStaff(Form);
const FormDocumentProtectedForAdminAndUser = protectedForStaff(FormDocument);
const DocumentEditProtectedForAdminAndUser = protectedForStaff(DocumentEdit);
const PatientProtectedForAuthorized = protectedForAuthorized(PatientPage);
const DoctorProtectedForAuthorized = protectedForAuthorized(DoctorPage);
const WorkflowResultProtectedForDoctor = protectedForDoctor(WorkflowResultPage);
const FaxProtectedForAdmin = protectedForAdminAndReceptionist(FaxPage);
const LocationsProtectedForAdmin = protectedForAdmin(LocationsContainer);

function Routing(): ReactElement {
  const [openChangesInProgressDialog, setOpenChangesInProgress] = useState<boolean>();
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={() => <Redirect to="/appointments" />} />
        <Route path="/login" component={LoginContainer} />
        <Route exact path="/appointments" component={AppointmentsProtectedForAdminAndUser} />
        <Route path="/forgot" component={ForgotPasswordContainer} />
        <Route path="/resetpassword/:jwtToken" component={ResetPasswordContainer} />
        <Route path="/case/:caseId/workflowResult" component={WorkflowResultProtectedForDoctor} />
        <Route
          path="/patient/:userId/documents/:documentId/edit"
          component={DocumentEditProtectedForAdminAndUser}
        />
        <Route path="/patient/:patientId" component={PatientProtectedForAuthorized} />
        <Route path="/doctor/:doctorId" component={DoctorProtectedForAuthorized} />
        <Route path="/form/:formId" component={FormProtectedForAdminAndUser} />
        <Route path="/formDocument/:formId" component={FormDocumentProtectedForAdminAndUser} />
        <Route path="/forms/fill/:jwtToken" component={PatientFormContainer} />
        <Route path="/formsDocument/fill/:jwtToken" component={PatientFormDocumentContainer} />
        <Route path="/messages/reply/:jwtToken" component={PatientMessageContainer} />
        <Route path="/patients" component={PatientListProtectedForAdminAndUser} />
        <Route path="/staff" component={StaffProtectedForAdmin} />
        <Route path="/locations" component={LocationsProtectedForAdmin} />
        <Route path="/fax" component={FaxProtectedForAdmin} />
        <Route path="/404" component={() => <div>404 Page Not Found</div>} />

        <Redirect from="*" to="/404" />
      </Switch>
    </Router>
  );
}

export default Routing;
