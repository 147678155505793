import { creatContextStore } from './base';
import { userIsAuthorized } from 'actions/userActions';

export interface AppInfo {
  Authenticated?: boolean;
  Authenticating?: boolean;
  AppPageLocation?: AppPageLocation;
}

export enum AppPageLocation {
  Appointments = 0,
  Patients = 1,
  Staff = 2,
  Locations = 3,
  LocationsHours = 4,
  Fax = 5,
}

const initialUserAuth = userIsAuthorized();
const initialState = {
  Authenticated: initialUserAuth,
  Authenticating: false,
  AppPageLocation: AppPageLocation.Appointments,
};

const [Provider, useState, useSetState, useMergeState] = creatContextStore<AppInfo>(initialState);

export {
  Provider as AppContextProvider,
  useState as useAppState,
  useSetState as useSetAppState,
  useMergeState as useMergeAppState,
};
