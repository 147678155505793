import {
  ScheduleResponse,
  UserResponse,
  User,
  Schedule,
  ScheduleItemResponse,
  OtherPhysicianResponse,
} from './user.interfaces';
import { User as UserType, OtherPhysician, Contact } from 'types';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import moment from 'moment';
import { responsiveFontSizes } from '@material-ui/core';

export function transformOneUser(response: UserResponse): User {
  return response;
}

export function transformOtherPhysician(response: OtherPhysicianResponse): OtherPhysician {
  return {
    id: response.id,
    firstName: response.first_name || '',
    lastName: response.last_name || '',
    fullName: `${response.first_name || ''} ${response.last_name || ''}`,
    email: response.email || '',
    fax: response.fax || '',
    phone: response.phone || '',
    billing: response.billing || '',
    physicianId: response.physician_id || '',
  };
}

export function transformFromOneScheduleItem(response: ScheduleResponse): Schedule {
  const schedule =
    response.schedule &&
    response.schedule[0] &&
    response.schedule.map((item: ScheduleItemResponse) => ({
      location: {
        id: item.location_id,
        name: item.name,
        color: item.color,
      },
      startTime: item.start_time,
      endTime: item.end_time,
      scheduleId: item.schedule_id,
      staffId: item.staff_id,
      startDate: item.start_date,
      endDate: item.end_date,
      needsToBeChanged: item.needs_to_be_changed,
      recurrence: item.recurrence,
      type: item.type,
      cancelled: item.cancelled,
    }));

  return {
    date: moment(response.date).toDate(),
    schedule: schedule || [],
  };
}

export function transformUserToStaff(users: UserType[]): UserStaff[] {
  const transformedUsers: UserStaff[] = [];

  if (users) {
    users.map(user => {
      const phoneContacts = user.contacts?.filter(contact => contact.type === 'phone');

      const phone = phoneContacts && phoneContacts.length > 0 ? phoneContacts[0].value : '';
      const staffLocation =
        user.staffInfo && user.staffInfo.locations.length > 0
          ? user.staffInfo.locations[0].name
          : '';

      /*
        Users without StaffInfo are not considered staff, since the StaffID (staffInfo.id) 
        is required for server requests. If a staff user does not appear in dropdowns it
        may be caused by this transform skipping it
      */
      if (user.staffInfo) {
        const transformedUser: UserStaff = {
          id: user.staffInfo.id ? user.staffInfo.id : user.id,
          email: user.email,
          name: user.firstName + ' ' + user.lastName,
          phone,
          type: user.type,
          location: staffLocation,
        };

        transformedUsers.push(transformedUser);
      }
    });
  }
  return transformedUsers;
}

export function fromGetUserResponse(data: string): User {
  try {
    return transformOneUser(JSON.parse(data));
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function fromGetOtherPhysicianResponse(data: string): OtherPhysician {
  try {
    return JSON.parse(data).map(transformOtherPhysician);
  } catch (err) {
    console.log(err);
    throw err;
  }
}
export function fromGetOtherPhysicianFromIdResponse(data: string): OtherPhysician {
  try {
    return transformOtherPhysician(JSON.parse(data));
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function fromGetUsersResponse(data: string): Array<User> {
  return JSON.parse(data).map(transformOneUser);
}

export function transformUserScheduleResponse(data: string): Array<Schedule> {
  return JSON.parse(data).map(transformFromOneScheduleItem);
}
