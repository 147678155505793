import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { UserApi, LocationApi } from 'api';
import PatientList from './PatientList.view';
import Layout from 'Layout';
import { NewPatientDialogContainer } from './components/NewPatientDialog';
import DeleteConfirmationDialog from './components/DeleteConfirmationDialog';
import { User, ReferrerStatusId, Location, LocationListItem } from 'types';
import _ from 'lodash';
import { DateType } from '@date-io/type';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';
import useAppointmentsApi from 'pages/Appointments/useAppointmentsApi.hook';

export interface Patient {
  id: number;
  name: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  healthCardProvinceCode: string;
  hcn: string;
  healthcard: string;
  referrerStatusId?: ReferrerStatusId;
  latestAppointmentStatus: string | undefined;
}

function PatientListContainer({ history }: any): ReactElement | null {
  const [open, setOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [locationIds, setLocationIds] = useState<Array<number>>([]);
  const [cancellationValue, setCancellationValue] = useState<boolean>(false);
  const [patientToDelete, setPatientToDelete] = useState<Patient | null>(null);
  const [patientList, setPatientList] = useState<Array<Patient>>([]);
  const [appointmentTypeId, setAppointmentTypeId] = useState<string | number | null>(null);
  const [appointmentStartDay, setAppointmentStartDay] = useState(moment());
  const [locations, setLocations] = useState<Array<LocationListItem>>([]);

  const { subTypes } = useAppointmentsApi();

  useEffect(() => {
    document.title = 'CVC - Patient List';
  }, []);

  function transformUsersToPatients(users: User[]): Patient[] {
    return users.map(user => {
      let phone = '';
      if (user.contacts) {
        const phoneContacts = user.contacts.filter(contact => contact.type === 'phone');
        if (phoneContacts && phoneContacts.length > 0) {
          phone = phoneContacts[0].value;
        }
      }
      return {
        id: user.id,
        dateOfBirth: user.patientInfo ? user.patientInfo.dateOfBirth : '',
        email: user.email,
        healthCardProvinceCode: user?.patientInfo?.healthCardProvinceCode || '',
        healthcard: user.patientInfo ? user.patientInfo.healthCard : '',
        hcn: user?.patientInfo?.hcn || '',
        name: user.firstName + ' ' + user.lastName,
        phone,
        referrerStatusId: user.patientInfo?.referrerStatusId,
        latestAppointmentStatus: user.patientInfo?.latestAppointmentStatus,
      };
    });
  }

  async function getPatientList(
    searchQuery?: string,
    locationIds?: number[],
    appointmentTypeId?: string | number | null,
    appointmentStartDate?: DateType,
    cancellationValue?: boolean
  ): Promise<void> {
    const cancellationValueUse: boolean | null = cancellationValue || null;
    const appointmentTypeIdUse: string | number | null =
      appointmentTypeId && Number(appointmentTypeId) > 0 ? appointmentTypeId : null;
    const appointmentStartDateUse: string | null =
      appointmentStartDate
        ?.clone()
        .toDate()
        .toISOString() || null;
    const locationIdsUse: Array<number | null> =
      locationIds && locationIds.length > 0 ? locationIds : [null];

    const getPatientListPromises = locationIdsUse.map(locationId => {
      return UserApi.getPatientList(
        searchQuery,
        'patient',
        locationId,
        appointmentTypeIdUse,
        appointmentStartDateUse,
        cancellationValueUse
      );
    });

    const results = await Promise.all(getPatientListPromises);
    const flattenedResults = results.flat();
    const uniqueResults = _.uniqBy(flattenedResults, 'id');
    const patients = transformUsersToPatients(uniqueResults);
    setPatientList(patients);
  }

  function onSaveSuccess(): void {
    getPatientList().catch();
    setOpen(false);
  }

  async function deletePatient(patientToDelete: Patient): Promise<void> {
    setDeleting(true);
    try {
      if (patientToDelete.id && patientList) {
        await UserApi.deletePatient(patientToDelete.id);
        setPatientToDelete(null);
      }
    } catch (e) {
      console.log(e);
    }
    setDeleting(false);
    getPatientList();
  }

  function openPatientCase(patient) {
    history.push('/patient/' + patient.id);
  }

  function createLocationsRowFromLocations(locations: Location[]): LocationListItem[] {
    const locationRow: LocationListItem[] = [];

    if (locations) {
      locations.forEach(location => {
        let email = '';
        let phone = '';
        let address = '';

        const phoneContacts = location.contacts?.filter(
          contact => contact.type === 'office' || contact.type === 'phone'
        );
        if (phoneContacts && phoneContacts.length > 0) {
          phone = phoneContacts[0].value;
        }

        const emailContacts = location.contacts?.filter(contact => contact.type === 'email');
        if (emailContacts && emailContacts.length > 0) {
          email = emailContacts[0].value;
        }

        if (location.addresses && location.addresses.length > 0) {
          address = `${location.addresses[0].line1}, ${location.addresses[0].city}`;
        }

        const locationListItem: LocationListItem = {
          id: location.id,
          email,
          name: location.name,
          phone,
          address,
          scheduleTypes: location.scheduleTypes,
        };

        locationRow.push(locationListItem);
      });
    }
    return locationRow;
  }

  async function getLocations(searchQuery?: string): Promise<void> {
    const locations = await LocationApi.getLocations(searchQuery);
    const locationsList = await createLocationsRowFromLocations(locations);
    setLocations(locationsList);
  }

  const searchPatients = () => {
    const query = searchQuery && searchQuery.length >= 2 ? searchQuery : '';
    getPatientList(query, locationIds, appointmentTypeId, appointmentStartDay, cancellationValue);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      searchPatients();
    }, 400);
    return (): void => clearTimeout(debounce);
  }, [searchQuery]);

  useEffect(() => {
    searchPatients();
  }, [locationIds, appointmentTypeId, appointmentStartDay, cancellationValue]);

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <Layout hideFooter>
      <PatientList
        appointmentTypeId={appointmentTypeId}
        selectAppointmentTypeId={setAppointmentTypeId}
        patientList={patientList}
        locationIds={locationIds}
        locations={locations}
        selectLocation={setLocationIds}
        searchQuery={searchQuery}
        onChangeSearchQuery={setSearchQuery}
        onNewPatientClick={useCallback(() => setOpen(true), [])}
        onDeletePatientClick={patient => setPatientToDelete(patient)}
        onClickPatient={openPatientCase}
        cancellationValue={cancellationValue}
        selectCancellationValue={setCancellationValue}
        appointmentStartDay={appointmentStartDay}
        selectAppointmentStartDate={setAppointmentStartDay}
        subTypes={subTypes}
      />

      <NewPatientDialogContainer
        open={open}
        onClose={() => setOpen(false)}
        onSaveSuccess={onSaveSuccess}
      />
      <DeleteConfirmationDialog
        patient={patientToDelete}
        loading={deleting}
        onClose={() => setPatientToDelete(null)}
        onConfirm={deletePatient}
      />
    </Layout>
  );
}

export default PatientListContainer;
