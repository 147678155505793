import { useState, useEffect } from 'react';
import { NewBlobDocument, Document } from 'types';

import {
  createBlobDocument as createBlobDocumentRequest,
  getDocument,
  getDocumentTypes,
} from 'api/document';

import { getCaseByUserId } from 'api/case';

export interface DocumentEditState {
  loading: boolean;
  createBlobDocument(data: NewBlobDocument): void;
  document?: Document;
  getDocumentTypeId(type: string): Promise<number>;
}

function useDocumentEditApi(userId, documentId): DocumentEditState {
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<Document>();

  async function createBlobDocument(data: NewBlobDocument): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(userId);
      if (cases.length) {
        await createBlobDocumentRequest(cases[0].id, data);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function getDocumentTypeId(type): Promise<number> {
    if (!type) {
      return -1;
    }
    try {
      const documentTypes = await getDocumentTypes();
      const documentType = documentTypes.find(
        typeObj => typeObj.type.toUpperCase() === type.toUpperCase()
      );
      if (documentType) {
        return documentType.id;
      }
    } catch (e) {
      console.log('Error', e);
    }

    return -1;
  }

  async function retrieveDocumentToEdit(): Promise<void> {
    try {
      const document = await getDocument(documentId);
      setDocument(document);
    } catch (e) {
      console.log('Error', e);
    }
  }

  useEffect(() => {
    retrieveDocumentToEdit().catch();
  }, []);

  return {
    loading,
    createBlobDocument,
    document,
    getDocumentTypeId,
  };
}

export default useDocumentEditApi;
