import React from 'react';
import styles from './CoverLetterStyles.module.scss';
import { CaseResult } from 'types';
import EditableParagraph from './components/EditableParagraph';
import EditableField from './components/EditableField';
import useCoverLetterHook from './useCoverLetter.hook';
// Head Office: 37 William St. N Lindsay, ON K9V 3Z9 Phone: 1-888-876-8346 Fax: 705-880-1211</div>
// DATE: Wed Jun 03 2020
// RE: test test
// D.O.B.: October 10, 1990
// Thank you kindly for allowing us to participate in the care of Mr. test who is a pleasant 29 year old male. He was seen regarding both legs. He has itching. He tried compression stockings and they have helped. He has allergies to surgical tape. Test has never been diagnosed with blood clots. He has been treated previously for veins but does not remember how. Medical history consists of diabetes. He is on a blood thinner - Plavix.
// With regards to the right leg we found signs of corona phelebectatica, and spider veins.
// With regards to the left leg we found spider veins.
// Ultrasound Doppler examination was performed. Right Leg GSV found Normal. SFJ found to be thrombosed on the right leg. SPJ found to be normal on the right leg. Right ASV found Thrombosed. Right PSV found Reflux. Right SSV found Ablated, and Reflux. SSV ablated in the right leg found lower. Giacomini on the right leg was found to be thrombosed. No incompetent perforators on the right leg. No superficial vein thrombosis right leg. Left GSV found Thrombosed, Ablated, and Stripped. Ablation in the left leg GSV found below knee, and above knee. Left GSV thrombosed below knee. The left GSV was found stripped below knee. Left leg SFJ shown to be reflux. Reflux left leg SPJ. Left PSV found Thrombosed, and Reflux. Left SSV found Reflux. Lower reflux in the SSV. Left Giacomini normal. Incompetent Perforators in the left leg found in the following areas: AK Anterior Lateral. Left leg no visible varicosities. Left leg superficial vein thrombosis found in the following areas: AK Anterior Lateral.
// Assessment & Plan
// I discussed the results with test today and explained that he has chronic venous insufficiency. He has C4b disease in the right leg according to the CEAP classification. He has C4a disease in the left leg according to the CEAP classification. We have discussed treatment options including venous stripping and endovenous ablation. For his treatment I recommend endovenous ablation. With regards to the left leg I recommend non-thermal ablation such as Clarivein.
// I have answered any of his questions or concerns including the possible complications and outcomes of treatment. We discussed that these procedures are not covered under the provincial health care plan and discussed the costs as well.
// I would recommend that he wear compression stockings with 20-30 mmHg. He will consider the treatment options and will let us know if he would like to proceed. Mr. Test might require compression sclerotherapy after the treatment, but we recommend waiting a few months in that case. Thank you again for the referral.
// Sincerely,
// Dictated, not read
// Dr. A. Matz MD, FACS, FRCSC.

export interface CoverLetterParagraphEntry {
  field?: boolean;
  hide?: boolean;
  text: string;
}

interface CoverLetterWorkflowResultProps {
  setParagraphText: (id: number, text: string) => void;
  setDoctorSignature: (text: string) => void;
  setCoverLetterTitle: (text: string) => void;
  onCoverLetterHeaderFooterSet?: (value) => void;
  paragraphTexts: Array<CoverLetterParagraphEntry>;
  editable?: boolean;
  coverLetterHeaderFooter?;
}

export function CoverLetterWorkflowResult({
  setParagraphText,
  setDoctorSignature,
  setCoverLetterTitle,
  paragraphTexts,
  editable,
  coverLetterHeaderFooter,
  onCoverLetterHeaderFooterSet,
}: CoverLetterWorkflowResultProps) {
  // TODO: come as an option

  return (
    <div className={styles.coverLetter}>
      <div className={styles.headerSection}>
        <div className={styles.header}>
          {coverLetterHeaderFooter && coverLetterHeaderFooter.header}
        </div>
        <div className={styles.date}>
          DATE: {coverLetterHeaderFooter && coverLetterHeaderFooter.date}
        </div>
        <div className={styles.title}>
          <EditableField
            fieldText={
              coverLetterHeaderFooter && coverLetterHeaderFooter.title
                ? coverLetterHeaderFooter.title
                : 'RE: No title'
            }
            setFieldText={val => {
              setCoverLetterTitle(val);
            }}
          />
        </div>
      </div>
      {paragraphTexts.map((pt, id) => {
        if (pt && pt.hide) {
          return (
            <EditableParagraph
              key={id}
              paragraphText={''}
              setParagraphText={val => {
                setParagraphText(id, val);
              }}
            />
          );
        }

        if (pt.field) {
          return (
            <div className={styles.title}>
              <EditableParagraph
                key={id}
                paragraphText={pt.text}
                setParagraphText={val => {
                  setParagraphText(id, val);
                }}
              />
            </div>
          );
        }

        return (
          <EditableParagraph
            key={id}
            paragraphText={pt.text}
            setParagraphText={val => {
              setParagraphText(id, val);
            }}
          />
        );
      })}
      <p>
        Sincerely,
        <br />
        Dictated, not read
        <br />
        <EditableField
          fieldText={
            coverLetterHeaderFooter && coverLetterHeaderFooter.doctorSignature
              ? coverLetterHeaderFooter.doctorSignature
              : 'Doctor Signature'
          }
          setFieldText={val => {
            setDoctorSignature(val);
          }}
        />
      </p>
    </div>
  );
}

interface ExampleCoverLetterWorkflowResultProps {
  caseId?: number;
  editable?: boolean;
  formCaseResult: CaseResult;
  coverLetterHeaderFooter?;
  onParagraphTextSet?: (a: Array<CoverLetterParagraphEntry>) => void;
  onCoverLetterHeaderFooterSet?: (value) => void;
}

export const ExampleCoverLetterWorkflowResult = ({
  caseId,
  editable,
  formCaseResult,
  coverLetterHeaderFooter,
  onParagraphTextSet,
  onCoverLetterHeaderFooterSet,
}: ExampleCoverLetterWorkflowResultProps) => {
  const {
    setParagraphText,
    setDoctorSignature,
    setCoverLetterTitle,
    paragraphTexts,
  } = useCoverLetterHook({
    caseId,
    editable,
    formCaseResult,
    onParagraphTextSet,
    onCoverLetterHeaderFooterSet,
  });

  return (
    <>
      <CoverLetterWorkflowResult
        {...{
          editable,
          setParagraphText,
          onCoverLetterHeaderFooterSet,
          paragraphTexts,
          setDoctorSignature,
          setCoverLetterTitle,
          coverLetterHeaderFooter,
        }}
      />
    </>
  );
};

interface ExampleCoverLetterWorkflowResultWrapperProps {
  caseId?: number;
  editable?: boolean;
  formCaseResult: CaseResult | null;
  coverLetterHeaderFooter?;
  onParagraphTextSet?: (a: Array<CoverLetterParagraphEntry>) => void;
  onCoverLetterHeaderFooterSet?: (value) => void;
}

// Only load once formCaseResult is populated.
export const ExampleCoverLetterWorkflowResultWrapper = ({
  caseId,
  editable,
  formCaseResult,
  coverLetterHeaderFooter,
  onParagraphTextSet,
  onCoverLetterHeaderFooterSet,
}: ExampleCoverLetterWorkflowResultWrapperProps) => {
  if (!formCaseResult) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className={styles.pageTitle}>Letter Result</div>
      <ExampleCoverLetterWorkflowResult
        {...{
          caseId,
          editable,
          formCaseResult,
          onParagraphTextSet,
          onCoverLetterHeaderFooterSet,
          coverLetterHeaderFooter,
        }}
      />
    </>
  );
};
export default ExampleCoverLetterWorkflowResultWrapper;
