import React, { Fragment, ReactElement, useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import { CreateReceipt, NewDocument } from 'types';
import { Context as ProfileContext } from 'context/profile';

import useReceiptApi from 'hook/useReceiptApi.hook';
import { PDFDownloadLink } from '@react-pdf/renderer';
import NewReceiptDialog from './components/NewReceiptDialog';

import Receipts from './Receipts.view';
import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';
import useReceiptPDFRender from './components/NewReceiptDialog/useReceiptPDFRender.hook';
import { getUser } from 'api/user';
import { User } from 'types';
import { MIME_TYPE_PDF } from 'lib/document';
import PagePopup from '../PagePopup';

export type CreatePdf = {
  id: number;
  header: string;
  signedUrl: string;
  fileName: string;
};

function ReceiptsContainer(props): ReactElement {
  const { patientId } = props?.match?.params;
  const { user } = useContext(ProfileContext);
  const [createPdfDetails, setCreatePdfDetails] = useState<CreatePdf>();
  const [pdfDetails, setPDFDetails] = useState({
    invoiceRows: [],
    paymentRows: [],
    header: '',
    contents: '',
    invoiceTotal: 0,
    patientName: '',
    healthCard: '',
    receiptDateOfBirth: undefined,
    receiptDate: undefined,
    serviceDate: undefined,
    messageToPatient: '',
    paidInFull: false,
    partiallyPaid: false,
    id: '',
    province: '',
    phoneNumber: '',
    reservationFee: false,
    refund: false,
  });

  const [pdfUrl, setPdfUrl] = useState('');
  const [patient, setPatient] = useState<User>();

  const [openPagePopup, setOpenPagePopup] = useState<boolean>(false);
  const [selectedUrl, setSelectedUrl] = useState<any>();
  const [isNewReceipt, setIsNewReceipt] = useState(false);
  const {
    loading,
    receipts,
    getReceipts,
    createReceipt,
    deleteReceipt,
    hardDeleteReceipt,
    pushReceiptToS3,
    sendReceipt,
    getReceiptUrl,
    finalizeReceipt,
  } = useReceiptApi(patientId);

  const { ReceiptLayout } = useReceiptPDFRender(pdfDetails);

  const formik = useFormik({
    initialValues: {
      header: '',
      createdBy: 0,
      createdAt: '',
      serviceDate: '',
      receiptDate: '',
      sendToPatient: true,
      total: 0,
      contents: '',
      province: '',
    },
    onSubmit: async (values: CreateReceipt) => {
      if (user?.staffInfo?.id) {
        values.createdAt = moment().format(DateTimeFormatString.APIDateAndTimeFormat);
        const receiptInfo = await createReceipt(
          values,
          pdfDetails.paymentRows,
          pdfDetails.invoiceRows
        );
        if (!receiptInfo) return;

        setCreatePdfDetails({
          id: receiptInfo.id,
          signedUrl: receiptInfo.signedRequest,
          fileName: receiptInfo.fileName,
          header: receiptInfo.header,
        });
      }
    },
  });

  async function fetchUser(patientId): Promise<void> {
    try {
      const user = await getUser(patientId);
      setPatient(user);
    } catch (e) {
      console.log('Error', e);
    }
  }

  useEffect(() => {
    if (patientId) fetchUser(patientId).catch();
  }, [patientId]);

  useEffect(() => {
    if (!!patientId) getReceipts().catch();
  }, [patientId]);

  const sendToS3 = async url => {
    if (!createPdfDetails) return;
    try {
      const fileResult = await fetch(url);
      const blob = await fileResult.blob();
      const fileName = createPdfDetails.fileName;
      const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

      const document: NewDocument = {
        name: fileName,
        typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
        document: fileObject,
      };

      const addedToS3 = await pushReceiptToS3(createPdfDetails.signedUrl, document);
      if (!addedToS3) {
        await hardDeleteReceipt(createPdfDetails.id);
      } else {
        finalizeReceipt(createPdfDetails.id, { header: createPdfDetails.header });
        setIsNewReceipt(false);
      }
    } catch (err) {
      console.log('Error');
    }

    setCreatePdfDetails(undefined);
  };

  async function viewReceipt(id): Promise<void> {
    setOpenPagePopup(true);
    const result = await getReceiptUrl(id);
    setSelectedUrl(result);
  }

  useEffect(() => {
    if (!pdfUrl) return;
    sendToS3(pdfUrl);
  }, [pdfUrl]);

  return (
    <Fragment>
      <Receipts
        receipts={receipts}
        onCreateReceiptClick={() => setIsNewReceipt(true)}
        onDeleteReceipt={deleteReceipt}
        onSendReceipt={sendReceipt}
        viewReceipt={viewReceipt}
      />
      <NewReceiptDialog
        open={isNewReceipt}
        onClose={() => setIsNewReceipt(false)}
        value={formik.values}
        onChange={formik.setFieldValue}
        onSave={formik.handleSubmit}
        loading={loading}
        setPdfDetails={setPDFDetails}
        pdfDetails={pdfDetails}
        patient={patient}
      />
      {createPdfDetails?.id ? (
        <PDFDownloadLink document={ReceiptLayout(createPdfDetails?.id)}>
          {({ blob, url, loading, error }) => {
            //setURLLoading(loading);
            if (url && createPdfDetails) {
              setPdfUrl(url);
            }
            return <div></div>;
          }}
        </PDFDownloadLink>
      ) : (
        <div></div>
      )}
      <PagePopup
        open={openPagePopup}
        url={selectedUrl}
        type={MIME_TYPE_PDF}
        onClose={() => setOpenPagePopup(false)}
      />
    </Fragment>
  );
}

export default ReceiptsContainer;
