import { useState, useEffect } from 'react';
import { Form } from 'types';
import moment from 'moment';
import { getCaseByUserId } from 'api/case';
import {
  getForms,
  sendForm as sendFormApi,
  generateNewSetOfForms,
  generateNewForm,
} from 'api/form';
import { FormStatus } from 'lib/form';
import { handleAPIErrors } from 'lib/handleAPIErrors';

export interface FormInfo {
  loading: boolean;
  forms: Array<Form>;
  formsReadyToSend: Array<Form>;
  actionSuccess: boolean;
  errorMessage: string;
  setActionSuccess(open: boolean): void;
  actionFailed: boolean;
  setActionFailed(open: boolean): void;
  sendForm(formId): Promise<void>;
  caseId: number;
  successSnackbarMessage: string;
  getNewSetsOfForms(caseId): Promise<void>;
  getNewCopyOfForm(formId): Promise<void>;
  setSuccessSnackbarMessage(message: string): void;
}

function useFormsApi(patientId): FormInfo {
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState<Array<Form>>([]);
  const [formsReadyToSend, setFormsReadyToSend] = useState<Array<Form>>([]);
  const [caseId, setCaseId] = useState<number>(-1);
  const [actionSuccess, setActionSuccess] = useState<boolean>(false);
  const [actionFailed, setActionFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState<string>('');

  async function fetchFormsAndReadyToSetForms(caseId, readyToSend) {
    try {
      const forms = await getForms(caseId, readyToSend);
      forms.sort((a, b) => {
        if (b.status == FormStatus.SentToPatient && a.status != b.status) return 1;
        if (a.status == FormStatus.SentToPatient) {
          if (moment(a.created_at).isBefore(b.created_at)) return 1;
          return -1;
        }
        return 0;
      });
      setForms(forms);
      const formsReadyToSend = await getForms(caseId, 1);
      setFormsReadyToSend(formsReadyToSend);
    } catch (e) {
      console.log('Error', e);
    }
  }

  async function fetchForms(patientId: number, readyToSend = 0): Promise<void> {
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        setCaseId(cases[0].id);
        fetchFormsAndReadyToSetForms(cases[0].id, readyToSend);
      }
    } catch (e) {
      console.log('Error', e);
    }
  }

  async function sendForm(formId: number): Promise<void> {
    setLoading(true);
    try {
      await sendFormApi(formId);
      await fetchForms(patientId);
      setSuccessSnackbarMessage('Form Sent Successful');
      setActionSuccess(true);
    } catch (e) {
      console.log('Error', e);
      setActionFailed(true);
    }
    setLoading(false);
  }

  async function getNewSetsOfForms(caseId): Promise<void> {
    try {
      await generateNewSetOfForms(caseId);
      await fetchFormsAndReadyToSetForms(caseId, 0);
      setSuccessSnackbarMessage('New Forms Created');
      setActionSuccess(true);
    } catch (e) {
      console.log('Error', e);
      setActionFailed(true);
    }
  }

  async function getNewCopyOfForm(formId): Promise<void> {
    try {
      await generateNewForm(formId, caseId);
      await fetchFormsAndReadyToSetForms(caseId, 0);
      setSuccessSnackbarMessage('New Form Created');
      setActionSuccess(true);
    } catch (e) {
      console.log('Error', e);
      const message = handleAPIErrors(e);
      setErrorMessage(message);
      setActionFailed(true);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchForms(patientId).then(() => setLoading(false));
  }, [patientId]);

  return {
    loading,
    forms,
    formsReadyToSend,
    caseId,
    actionFailed,
    actionSuccess,
    successSnackbarMessage,
    errorMessage,
    setActionFailed,
    setActionSuccess,
    sendForm,
    getNewSetsOfForms,
    getNewCopyOfForm,
    setSuccessSnackbarMessage,
  };
}

export default useFormsApi;
