import React, { ReactElement, memo, useState, useEffect } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { FormLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ValidationError } from 'components/Forms/ValidationError';

import { LocationSchedule, LocationScheduleValidationError } from 'types';

import {
  FrequencyType,
  FREQUENCY,
  getStartTimesArray,
  getEndTimesArray,
  displayDateForDatePicker,
} from 'lib/schedule';
import { ScheduleTypesNumberToString, ScheduleTypes, AllScheduleTypes } from 'lib/scheduleTypes';
import { convertTo12From24 } from 'lib/schedule';
import styles from './ScheduleDialog.module.scss';
import Select from 'components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import Checkbox from '../../../../components/Checkbox';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import { getStaffSchedulesByLocationId, deleteException } from 'api/location';

import { DialogButtonType } from 'components/DialogButton/DialogButton.view';

import {
  getLocationScheduleById,
  updateLocationSchedule,
  deleteLocationSchedule,
  createLocationSchedule,
  getAvailableHoursForLocationSchedule,
} from 'api/location';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';

interface ScheduleDialogProps {
  open?: boolean;
  title?: string;
  dialogType?: string;
  locationId: number;
  locationScheduleId: number;
  startDate: Date;
  onClose: () => void;
  onSaveSuccess: () => Promise<void>;
  createLocationSchedule: boolean;
  validLocationScheduleTypes: Array<number>;
}

function ScheduleDialog(props: ScheduleDialogProps): ReactElement {
  const [forever, setForever] = useState<boolean>(false);
  const [locationSchedule, setLocationSchedule] = useState<LocationSchedule>();
  const [startTimesArr, setStartTimesArr] = useState<Array<string>>([]);
  const [endTimesArr, setEndTimesArr] = useState<Array<string>>([]);
  const [fullEndTimesArr, setFullEndTimesArr] = useState<Array<string>>([]);
  const [fullStartTimesArr, setFullStartTimesArr] = useState<Array<string>>([]);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [endDate, setEndDate] = useState<Date | null | undefined>();
  const [frequency, setFrequency] = useState<FrequencyType>();
  const [scheduleType, setScheduleType] = useState<number>(0);
  const [changeAllFutureDates, setChangeAllFutureDates] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [untilDate, setUntilDate] = useState<Date | null | undefined>(props.startDate);
  const [disableChangeType, setDisableChangeType] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<LocationScheduleValidationError>>(
    []
  );
  const [selectedChangeType, setSelectedChangeType] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);

  function resetVariables() {
    setStartTime('');
    setEndTime('');
    setStartTimesArr([]);
    setEndTimesArr([]);
    setFullStartTimesArr([]);
    setFullEndTimesArr([]);
    setValidationErrors([]);
    setScheduleType(0);
    setForever(false);
    setFrequency('never');
    setSelectedChangeType(false);
  }

  const onLocalClose = () => {
    resetVariables();
    props.onClose();
  };

  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(onLocalClose);

  function validateForm(fullCheck): boolean {
    let validated = true;
    const validationErrors: Array<LocationScheduleValidationError> = [];

    if (fullCheck) {
      if (startTime === '' || !startTimesArr.includes(startTime)) {
        const validationError: LocationScheduleValidationError = {
          field: 'startTime',
          message: 'Please select a start time',
        };

        validationErrors.push(validationError);
        validated = false;
      }

      if (endTime === '' || !endTimesArr.includes(endTime)) {
        const validationError: LocationScheduleValidationError = {
          field: 'endTime',
          message: 'Please select a closing time',
        };

        validationErrors.push(validationError);
        validated = false;
      }

      if (!frequency) {
        const validationError: LocationScheduleValidationError = {
          field: 'frequency',
          message: 'Please select a recurrence',
        };
        validationErrors.push(validationError);
        validated = false;
      }

      if (!scheduleType) {
        const validationError: LocationScheduleValidationError = {
          field: 'scheduleType',
          message: 'Please choose a schedule type',
        };
        validationErrors.push(validationError);
        validated = false;
      }
    }

    if (!selectedChangeType && !props.createLocationSchedule) {
      const validationError: LocationScheduleValidationError = {
        field: 'changeType',
        message: 'Please select change type',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);
    return validated;
  }

  function setError(message: string) {
    const validationErrors: Array<LocationScheduleValidationError> = [];

    const validationError: LocationScheduleValidationError = {
      field: 'updateError',
      message: message,
    };

    validationErrors.push(validationError);
    setValidationErrors(validationErrors);
  }

  async function fetchLocationAvailableHours() {
    setLoading(true);
    let endDateUse: Date | undefined = untilDate || undefined;

    if (frequency === `never`) {
      endDateUse = props.startDate;
    } else if (forever) {
      endDateUse = undefined;
    }
    const body = {
      locationId: props.locationId,
      startDate: props.startDate,
      endDate: endDateUse,
      type: scheduleType,
      locationScheduleId: props.locationScheduleId,
      frequency: frequency,
    };

    // Get location schedule rescrictions from other location schedules
    const locationConflicts = await getAvailableHoursForLocationSchedule(body);

    let startTimes: Array<string> = getStartTimesArray(null, null);
    let endTimes: Array<string> = getEndTimesArray(startTimes[0], null);

    for (const conflict of locationConflicts) {
      startTimes = startTimes.filter(time => {
        return !(time < conflict.endTime && time >= conflict.startTime);
      });
      endTimes = endTimes.filter(time => {
        return !(time <= conflict.endTime && time > conflict.startTime);
      });
    }

    // Get location schedule rescrictions from staff schedules
    if (locationSchedule && locationSchedule.endDate) {
      const momentDate = moment(props.startDate).format('YYYY-MM-DD');
      const combinedDateStart = `${momentDate} ${locationSchedule.startTime}`;
      let combinedDateEnd = `${moment(locationSchedule.endDate).format('YYYY-MM-DD')} ${
        locationSchedule.endTime
      }`;
      if (frequency === 'never') {
        combinedDateEnd = `${momentDate} ${locationSchedule.endTime}`;
      }

      const staffSchedules = await getStaffSchedulesByLocationId(
        props.locationId,
        combinedDateEnd,
        combinedDateStart,
        scheduleType,
        frequency
      );

      if (staffSchedules.length > 0) {
        for (const staffSchedule of staffSchedules) {
          startTimes = startTimes.filter(time => {
            return time <= staffSchedule.startTime;
          });
          endTimes = endTimes.filter(time => {
            return time >= staffSchedule.endTime;
          });
        }
        let startPoint = endTimes.findIndex(element => element == locationSchedule.endTime);
        let topCutIndex = startPoint;
        let bottomCutIndex = startPoint;
        let topFound = false;
        let bottomFound = false;

        while (topCutIndex < endTimes.length - 1 || bottomCutIndex > 0) {
          if (
            moment(endTimes[topCutIndex + 1], 'HH:mm').diff(
              moment(endTimes[topCutIndex], 'HH:mm'),
              'minutes'
            ) != 15
          ) {
            topCutIndex++;
            topFound = true;
          }
          if (
            moment(endTimes[bottomCutIndex], 'HH:mm').diff(
              moment(endTimes[bottomCutIndex - 1], 'HH:mm'),
              'minutes'
            ) != 15
          ) {
            bottomFound = true;
          }

          if (!topFound) topCutIndex++;
          if (topCutIndex == startTimes.length - 1) topCutIndex++;
          if (!bottomFound) bottomCutIndex--;
          if (topFound && bottomFound) break;
        }
        endTimes = endTimes.slice(bottomCutIndex, topCutIndex + 1);

        startPoint = startTimes.findIndex(element => element == locationSchedule.startTime);
        topCutIndex = startPoint;
        bottomCutIndex = startPoint;
        topFound = false;
        bottomFound = false;

        while (topCutIndex < startTimes.length - 1 || bottomCutIndex > 0) {
          if (
            moment(startTimes[topCutIndex + 1], 'HH:mm').diff(
              moment(startTimes[topCutIndex], 'HH:mm'),
              'minutes'
            ) != 15
          ) {
            topFound = true;
            topCutIndex++;
          }
          if (
            moment(startTimes[bottomCutIndex], 'HH:mm').diff(
              moment(startTimes[bottomCutIndex - 1], 'HH:mm'),
              'minutes'
            ) != 15
          ) {
            bottomFound = true;
          }

          if (!topFound) topCutIndex++;
          if (topCutIndex == startTimes.length - 1) topCutIndex++;
          if (!bottomFound) bottomCutIndex--;
          if (topFound && bottomFound) break;
        }
        startTimes = startTimes.slice(bottomCutIndex, topCutIndex + 1);
      }
    }

    setFullEndTimesArr(endTimes);
    setFullStartTimesArr(startTimes);
    setLoading(false);
  }

  async function fetchLocationSchedule(locationId, locationScheduleId) {
    setLoading(true);
    try {
      const locationSchedule = await getLocationScheduleById(locationId, locationScheduleId);
      setLocationSchedule(locationSchedule);
      setStartTime(locationSchedule.startTime);
      setEndTime(locationSchedule.endTime);
      setEndDate(locationSchedule.endDate);
      setUntilDate(locationSchedule.endDate);
      setScheduleType(locationSchedule.type);
      setFrequency(locationSchedule.recurrence);
      setCancelled(!!locationSchedule.cancelled);
      if (locationSchedule.recurrence == 'never') {
        setChangeAllFutureDates(false);
        setSelectedChangeType(true);
      }

      if (locationSchedule.endDate && moment(locationSchedule.endDate).year() > 2099) {
        setForever(true);
      } else {
        setForever(false);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (props.locationScheduleId > 0 && !props.createLocationSchedule && props.open) {
      fetchLocationSchedule(props.locationId, props.locationScheduleId);
    }
  }, [props.locationScheduleId, props.open, props.validLocationScheduleTypes]);

  useEffect(() => {
    if (!props.open) return;
    const debounce = setTimeout(async () => {
      fetchLocationAvailableHours();
    }, 300);

    return (): void => clearTimeout(debounce);
  }, [props.open, locationSchedule, forever, frequency, untilDate, scheduleType]);

  useEffect(() => {
    if (changeAllFutureDates && locationSchedule) {
      setUntilDate(locationSchedule.endDate);
    } else {
      setUntilDate(props.startDate);
    }
  }, [changeAllFutureDates, selectedChangeType]);

  useEffect(() => {
    if (untilDate && endDate) {
      if (
        (moment(untilDate).diff(endDate, 'days') !== 0 &&
          moment(props.startDate).diff(untilDate, 'days') !== 0) ||
        (!forever && moment(endDate).year() > 2099) ||
        (forever && moment(endDate).year() < 2099)
      ) {
        setChangeAllFutureDates(true);
        setDisableChangeType(true);
      } else {
        setDisableChangeType(false);
      }
    }
  }, [untilDate, forever]);

  useEffect(() => {
    if (frequency === 'never') {
      setChangeAllFutureDates(false);
    }
  }, [frequency]);

  useEffect(() => {
    const startTimes = fullStartTimesArr;
    let endTimes = fullEndTimesArr;

    if (startTime && endTimes.length > 0) {
      endTimes = endTimes.filter(time => {
        return time > startTime;
      });
    }
    if (startTime) {
      let cutIndex = 1;
      for (; cutIndex < endTimes.length; cutIndex++) {
        if (
          moment(endTimes[cutIndex], 'HH:mm').diff(
            moment(endTimes[cutIndex - 1], 'HH:mm'),
            'minutes'
          ) != 15
        ) {
          break;
        }
      }
      endTimes = endTimes.slice(0, cutIndex);
    }
    setStartTimesArr(startTimes);
    setEndTimesArr(endTimes);
  }, [startTime, endTime, fullEndTimesArr, fullStartTimesArr]);

  const setValidScheduleType = type => {
    if (props.createLocationSchedule && props.validLocationScheduleTypes.includes(type)) {
      setScheduleType(type);
    }
  };

  const setValidFrequency = tag => {
    if (props.createLocationSchedule) {
      setFrequency(tag);
    }
  };

  const createServerScheduleBody = (scheduleId = -1) => {
    let endDateUse: Date | undefined | null = untilDate || moment().toDate();

    if (frequency === `never`) {
      endDateUse = props.startDate;
    } else if (forever) {
      endDateUse = undefined;
    }

    const body = {
      scheduleId: scheduleId,
      locationId: props.locationId,
      endTime: endTime,
      startTime: startTime,
      startDate: props.startDate,
      endDate: endDateUse,
      recurrence: frequency as FrequencyType,
      type: scheduleType,
      changeAllFutureDates: changeAllFutureDates,
      cancelSchedule: false,
    };

    return body;
  };
  async function onSave(): Promise<void> {
    if (validateForm(true)) {
      try {
        setLoading(true);
        const body = createServerScheduleBody();
        await createLocationSchedule(body);
        resetVariables();
        props.onSaveSuccess();
      } catch (e) {
        const error = e as any;
        if (error) {
          setError(error.response.data.message);
        }
        console.log(error);
      }
      setLoading(false);
    }
  }
  async function onUpdate(): Promise<void> {
    setLoading(true);
    if (props.locationScheduleId && validateForm(true)) {
      try {
        const body = createServerScheduleBody(props.locationScheduleId);
        await updateLocationSchedule(props.locationId, body);
        resetVariables();
        props.onSaveSuccess();
      } catch (e) {
        const error = e as any;
        if (error.response.data.message) {
          setError(error.response.data.message);
        }
        console.log(error);
      }
    }
    setLoading(false);
  }

  function createDeleteBody() {
    const body = {
      frequency: frequency,
      selectedDate: props.startDate,
      changeAllFutureDates: changeAllFutureDates,
    };

    return body;
  }

  async function onDelete(): Promise<void> {
    setLoading(true);
    try {
      if (props.locationScheduleId && validateForm(false)) {
        const body = createDeleteBody();
        await deleteLocationSchedule(props.locationScheduleId, body);
        resetVariables();
        props.onSaveSuccess();
      }
    } catch (e) {
      const error = e as any;
      if (error) {
        setError(error.response.data.message);
      }
      console.log(error);
    }
    setLoading(false);
  }

  async function onExceptionChange(): Promise<void> {
    setLoading(true);
    try {
      if (cancelled) {
        await deleteException(props.locationScheduleId);
      } else {
        const body = createServerScheduleBody(props.locationScheduleId);
        body.cancelSchedule = true;
        body.changeAllFutureDates = false;
        await updateLocationSchedule(props.locationId, body);
      }

      resetVariables();
      await props.onSaveSuccess();
    } catch (e) {
      const error = e as any;
      if (error) {
        setError(error.response.data.message);
      }
      console.log(error);
    }
    setLoading(false);
  }

  function onClose() {
    if (changesMade) {
      setWarningOpen(true);
      return;
    }
    onLocalClose();
  }

  function editDeleteButton() {
    if (!props.createLocationSchedule) {
      return (
        <div className={styles.deleteButton} onClick={onDelete}>
          Delete Schedule
        </div>
      );
    }
  }
  function editExceptionButton() {
    if (!props.createLocationSchedule) {
      return (
        <DialogButton
          buttonType={DialogButtonType.AffirmativeLink}
          onClick={onExceptionChange}
          loading={loading}
        >
          {cancelled ? 'Include Schedule' : 'Exclude Schedule'}
        </DialogButton>
      );
    }
  }

  function endUntil() {
    if (
      (frequency || 'never').toLowerCase() === 'never' ||
      (!props.createLocationSchedule && !changeAllFutureDates)
    ) {
      return <div></div>;
    } else {
      return (
        <div className={styles.until}>
          <div className={classNames(styles.repeatSchedule)}>
            <div className={styles.checkBoxArea}>
              <b>Repeat Until:</b>
              <Checkbox
                title={'Forever'}
                name="forever"
                onChange={e => setForever(e.target.checked)}
                checked={forever}
                disabled={loading}
              />
            </div>

            <div className={styles.checkBoxArea}>
              <DatePicker
                label="Until"
                value={displayDateForDatePicker(untilDate, props.startDate)}
                fullWidth
                onChange={value => {
                  setChangesMade(true);
                  setUntilDate(value!.toDate());
                }}
                format="DD/MM/YYYY"
                disabled={forever || loading}
                shouldDisableDate={day => {
                  if (!day) return true;
                  if (moment(props.startDate).isAfter(day)) return true;
                  return moment().diff(moment(day.toISOString()), 'days') > 0; //In Past
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  function createOrEdit() {
    if (!props.createLocationSchedule) {
      return (
        <div>
          <FormControl component="fieldset">
            {frequency !== 'never' && (
              <RadioGroup name="radio-buttons-group">
                <FormControlLabel
                  value="All future dates"
                  control={
                    <Radio
                      onChange={event => {
                        setChangesMade(true);
                        setChangeAllFutureDates(true);
                        setSelectedChangeType(true);
                      }}
                      checked={changeAllFutureDates && selectedChangeType}
                      disabled={loading}
                    />
                  }
                  label="All future dates"
                />
                <FormControlLabel
                  value="Only current date"
                  control={
                    <Radio
                      disabled={loading || disableChangeType}
                      onChange={event => {
                        setChangesMade(true);
                        setChangeAllFutureDates(false);
                        setSelectedChangeType(true);
                      }}
                      checked={!changeAllFutureDates && selectedChangeType}
                    />
                  }
                  label="Only current date"
                />
              </RadioGroup>
            )}
          </FormControl>
          <ValidationError field={'changeType'} validationErrors={validationErrors} />
        </div>
      );
    }
  }
  return (
    <Dialog open={props.open} title={`${props.title} Schedule`} onClose={onClose}>
      <div className={classNames(styles.dialogBody)}>
        <div className={styles.fields}>
          <p>
            <b>
              {formatDate(DateTimeFormat.MonthDayYear, props.startDate)}
              <br />
              {formatDate(DateTimeFormat.DayOnly, props.startDate)}
            </b>
          </p>
          {
            <div className={classNames(styles.buttonSection)}>
              {AllScheduleTypes.map(type => (
                <div
                  key={ScheduleTypesNumberToString(type)}
                  className={styles.dialogButtonContainer}
                >
                  <DialogButton
                    buttonType={DialogButtonType.Bordered}
                    className={styles.dialogButton}
                    key={ScheduleTypesNumberToString(type)}
                    onClick={() => {
                      setChangesMade(true);
                      setValidScheduleType(type);
                    }}
                    active={!!(scheduleType && scheduleType === type)}
                    disabled={loading || (!props.createLocationSchedule && scheduleType !== type)}
                  >
                    {ScheduleTypesNumberToString(type)}
                  </DialogButton>
                </div>
              ))}
            </div>
          }
          <ValidationError field={'scheduleType'} validationErrors={validationErrors} />
          {createOrEdit()}
          <div className={styles.openCloseContainer}>
            <div className={styles.selectContainer}>
              <Select
                fullWidth
                label="Opening time"
                name={'startTime'}
                onChange={({ target }) => {
                  setChangesMade(true);
                  setStartTime(target.value);
                }}
                value={startTime}
                disabled={loading}
              >
                {startTimesArr.map(item => (
                  <MenuItem key={item} value={item} className={styles.selectItem}>
                    {convertTo12From24(item)}
                  </MenuItem>
                ))}
              </Select>
              <ValidationError field={'startTime'} validationErrors={validationErrors} />
            </div>

            <div className={styles.selectContainer}>
              <Select
                fullWidth
                label="Closing time"
                name={'endTime'}
                onChange={({ target }) => {
                  setChangesMade(true);
                  setEndTime(target.value);
                }}
                value={endTime}
                disabled={loading}
              >
                {endTimesArr.map(item => (
                  <MenuItem key={item} value={item} className={styles.selectItem}>
                    {convertTo12From24(item)}
                  </MenuItem>
                ))}
              </Select>
              <ValidationError field={'endTime'} validationErrors={validationErrors} />
            </div>
          </div>
          <div className={styles.repeatContainer}>
            <Grid container direction="column">
              <Grid container>
                <Grid item xs={12}>
                  <FormLabel>Repeat:</FormLabel>
                  <div className={classNames(styles.buttonSection)}>
                    {FREQUENCY.map(item => (
                      <div key={item.id} className={styles.dialogButtonContainer}>
                        <DialogButton
                          key={item.id}
                          buttonType={DialogButtonType.Bordered}
                          onClick={() => {
                            setChangesMade(true);
                            setValidFrequency(item.tag);
                          }}
                          active={frequency! === item.tag}
                          disabled={
                            loading || (!props.createLocationSchedule && frequency! !== item.tag)
                          }
                        >
                          {item.name}
                        </DialogButton>
                      </div>
                    ))}
                  </div>
                  <ValidationError field={'frequency'} validationErrors={validationErrors} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {endUntil()}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <ValidationError field={'updateError'} validationErrors={validationErrors} />
        <div className={styles.dialogBottom}>
          {props.createLocationSchedule ? (
            <div className={styles.buttomButtonSection}>
              <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
                Cancel
              </DialogButton>
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                onClick={onSave}
                loading={loading}
              >
                Save
              </DialogButton>
            </div>
          ) : (
            <div className={styles.buttomButtonSection}>
              <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
                Cancel
              </DialogButton>
              {editDeleteButton()}
              {editExceptionButton()}
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                onClick={onUpdate}
                loading={loading}
              >
                Update
              </DialogButton>
            </div>
          )}
        </div>
      </div>
      {LocalPrompt}
    </Dialog>
  );
}

export default memo(ScheduleDialog);
