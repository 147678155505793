import React, { useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Drawer, DrawerProps, makeStyles, IconButton, Icon } from '@material-ui/core';
import { Context as AuthContext } from '../../../context/auth';
import DehazeIcon from '@material-ui/icons/Dehaze';
import MainLogo from 'assets/images/logo.png';
import { useAppState, useMergeAppState } from 'context';
import { AppPageLocation } from 'context/app';
import useProfileRoles from 'hook/useProfileRoles.hook';

interface SidebarComponentProps extends RouteComponentProps, DrawerProps {
  onCloseSidebar: () => void;
}

const useStyle = makeStyles({
  paper: {
    background: '#26315A',
    width: '15%',
    minWidth: '300px',
  },
});

const SidebarComponent = ({ history, onCloseSidebar, ...props }: SidebarComponentProps) => {
  const { logout } = useContext(AuthContext);
  const { isAdmin, isAdminOrReceptionist } = useProfileRoles();
  const appState = useAppState();
  const mergeAppState = useMergeAppState();

  useEffect(() => {
    const windowLocation = window.location.pathname.split('/')[1];
    if (windowLocation === 'appointments') {
      appState.AppPageLocation = AppPageLocation.Appointments;
    } else if (windowLocation === 'patients' || windowLocation === 'patient') {
      appState.AppPageLocation = AppPageLocation.Patients;
    } else if (windowLocation === 'staff' || windowLocation === 'doctor') {
      appState.AppPageLocation = AppPageLocation.Staff;
    } else if (windowLocation === 'locations') {
      appState.AppPageLocation = AppPageLocation.Locations;
    } else if (windowLocation === 'fax') {
      appState.AppPageLocation = AppPageLocation.Fax;
    }
  }, []);

  const onClickAppointments = () => {
    mergeAppState({ AppPageLocation: AppPageLocation.Appointments });
    history.push('/appointments');
  };

  const onClickPatients = () => {
    mergeAppState({ AppPageLocation: AppPageLocation.Patients });
    history.push('/patients');
  };

  const onClickStaff = () => {
    mergeAppState({ AppPageLocation: AppPageLocation.Staff });
    history.push('/staff');
  };

  const onClickLocations = () => {
    mergeAppState({ AppPageLocation: AppPageLocation.Locations });
    history.push('/locations');
  };

  const onClickFax = () => {
    mergeAppState({ AppPageLocation: AppPageLocation.Fax });
    history.push('/fax');
  };

  const classes = useStyle();

  return (
    <Drawer classes={classes} anchor="left" {...props}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <IconButton onClick={onCloseSidebar}>
            <Icon>
              <DehazeIcon />
            </Icon>
          </IconButton>
          <img className={styles.headerLogo} alt="Logo" src={MainLogo} />
        </div>
        <div
          onClick={onClickAppointments}
          className={
            appState.AppPageLocation === AppPageLocation.Appointments
              ? classNames(styles.item, styles.selected)
              : styles.item
          }
        >
          Appointments
        </div>
        <div
          onClick={onClickPatients}
          className={
            appState.AppPageLocation === AppPageLocation.Patients
              ? classNames(styles.item, styles.selected)
              : styles.item
          }
        >
          Patients
        </div>
        {isAdmin && (
          <div
            onClick={onClickStaff}
            className={
              appState.AppPageLocation === AppPageLocation.Staff
                ? classNames(styles.item, styles.selected)
                : styles.item
            }
          >
            Staff
          </div>
        )}
        {isAdmin && (
          <div
            onClick={onClickLocations}
            className={
              appState.AppPageLocation === AppPageLocation.Locations
                ? classNames(styles.item, styles.selected)
                : styles.item
            }
          >
            Clinic Scheduling
          </div>
        )}
        {isAdminOrReceptionist && (
          <div
            onClick={onClickFax}
            className={
              appState.AppPageLocation === AppPageLocation.Fax
                ? classNames(styles.item, styles.selected)
                : styles.item
            }
          >
            Fax Queue
          </div>
        )}
        {/* TODO:// REMOVED FOR DEMO */}
        {/* {isAdmin && (
          <div
            onClick={onClickLocationsHours}
            className={
              appState.AppPageLocation === AppPageLocation.LocationsHours
                ? classNames(styles.item, styles.selected)
                : styles.item
            }
          >
            Location Hours
          </div>
        )} */}
        <div onClick={logout} className={styles.item}>
          Logout
        </div>
      </div>
    </Drawer>
  );
};

export default withRouter(SidebarComponent);
