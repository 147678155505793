import { useState, useEffect } from 'react';
import { Task } from 'types';
import _ from 'lodash';
import moment from 'moment';

import {
  getTasks,
  getAllTasksForUser,
  createTask as createTaskApi,
  updateTask as updateTaskApi,
} from 'api/task';
import { getCaseByUserId } from 'api/case';

export interface TasksInfo {
  loading: boolean;
  tasks: Array<Task>;
  allUserTasks: Array<Task>;
  createTask: (patientId, task) => Promise<void>;
  fetchTasks: (patientId: number) => Promise<void>;
  updateTask: (taskId, taskToEdit) => Promise<void>;
  fetchAllUserTasks: () => Promise<void>;
}

type useTasksApiParams = {
  patientId: number;
  showCompleted?: boolean;
  hideTodo?: boolean;
};

function useTasksApi({ patientId, showCompleted, hideTodo }: useTasksApiParams): TasksInfo {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<Array<Task>>([]);
  const [allUserTasks, setAllUserTasks] = useState<Array<Task>>([]);

  async function createTask(patientId, task): Promise<void> {
    if (!patientId) return;
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        const response = await createTaskApi(cases[0].id, task);
        setTasks([...tasks, response]);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function updateTask(taskId, taskToEdit): Promise<void> {
    setLoading(true);
    try {
      await updateTaskApi(taskId, taskToEdit);
    } catch (e) {
      console.log('Error', e);
      setLoading(false);
      throw e;
    }
    setLoading(false);
  }

  async function fetchTasks(patientId: number): Promise<void> {
    if (!patientId) return;
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        const tasks = await getTasks(cases[0].id, showCompleted, hideTodo);
        const sortedTasks = _.orderBy(
          tasks,
          (o: Task) => {
            return moment(o.created_at);
          },
          ['desc']
        );
        setTasks(sortedTasks);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function fetchAllUserTasks(): Promise<void> {
    try {
      const tasks = await getAllTasksForUser(showCompleted, hideTodo);
      const sortedTasks = _.orderBy(
        tasks,
        (o: Task) => {
          return moment(o.created_at);
        },
        ['desc']
      );
      setAllUserTasks(sortedTasks);
    } catch (e) {
      console.log('Error', e);
    }
  }

  useEffect(() => {
    fetchTasks(patientId).catch();
  }, [patientId]);

  useEffect(() => {
    fetchAllUserTasks().catch();
  }, [patientId]);

  return {
    loading,
    tasks,
    allUserTasks,
    updateTask,
    fetchTasks,
    fetchAllUserTasks,
    createTask,
  };
}

export default useTasksApi;
