import React, { ReactElement, useEffect, useState } from 'react';

import Paper from 'components/Paper';
import TripleDot from 'components/TripleDot';
import ConditionDiv from 'components/ConditionDiv';

import styles from './FormStyles.module.scss';
import classNames from 'classnames';

interface FormProp {
  id?: string | number;
  key: string;
  className?: string;
  title?: string;
  info?: string;
  statusId?: number | string;
  createdAt?: string;
  completedAt?: string;
  onClick?: () => void;
  duplicateForm: (id) => void;
}

function Form({
  id,
  key,
  title,
  info,
  statusId,
  className,
  createdAt,
  completedAt,
  onClick,
  duplicateForm,
}: FormProp): ReactElement {
  const [displaySettings, setDisplaySettings] = useState<boolean>(false);

  const copyDocument = event => {
    event.stopPropagation();
    duplicateForm(id);
    setDisplaySettings(false);
  };

  useEffect(() => {
    const buttonDiv = document.getElementById('create');
    const checkIfClickedOutside = e => {
      e.stopPropagation();
      if (buttonDiv?.contains(e.target)) {
        return false;
      }
      setDisplaySettings(false);
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [displaySettings]);

  const menuSelect = event => {
    event.stopPropagation();
    setDisplaySettings(true);
  };

  return (
    <Paper className={className} onClick={onClick}>
      <div className={styles.main}>
        <div className={styles.description}>
          <div className={styles.title}>{title}</div>
          <div className={classNames(styles.statusDate, styles[statusId ? statusId : 0])}>
            {info}
          </div>
          <div className={styles.statusDate}>Created: {createdAt}</div>
          <ConditionDiv condition={!!completedAt}>
            <div className={styles.statusDate}>Completed: {completedAt}</div>
          </ConditionDiv>
        </div>

        <div className={styles.dots}>
          <div className={styles.dropdown} id={key}>
            {displaySettings && (
              <div className={styles.dropdownContent}>
                <a onClick={copyDocument} id={'create'}>
                  New Form
                </a>
              </div>
            )}
          </div>
          <div onClick={menuSelect}>
            <TripleDot />
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default Form;
