import _ from 'lodash';
import { useState, useEffect } from 'react';
import {
  NewDocument,
  Document,
  DocumentType,
  MultipleNewDocuments,
  NewDefaultDocument,
} from 'types';

import { getCaseByUserId } from 'api/case';
import {
  copyDefaultDocument as copyDefaultDocumentRequest,
  copyDocument as copyDocumentRequest,
  createDocument as createDocumentRequest,
  createDefaultDocument as createDefaultDocumentRequest,
  createMultipleDocuments as createMultipleDocumentRequest,
  getDefaultDocuments,
  getDocuments,
  getDocumentTypes,
  deleteDefaultDocument as deleteDefaultDocumentAPI,
  downloadDocument as downloadDocumentAPI,
  downloadDefaultDocument as downloadDefaultDocumentAPI,
  sendDocument as sendDocumentAPI,
  updateDocument as updateDocumentAPI,
  sendMultipleDocuments as sendMultipleEmailDocumentsAPI,
} from 'api/document';

export interface DocumentsApiHookInterface {
  loading: boolean;
  documents: Array<Document>;
  defaultDocuments: Map<string, Array<Document>>;
  documentTypes: Array<DocumentType>;
  fetchDefaultDocuments: () => Promise<void>;
  createDocument: (data: NewDocument) => Promise<void>;
  createDefaultDocument: (data: NewDefaultDocument) => Promise<void>;
  createMultipleDocuments: (data: MultipleNewDocuments) => Promise<any>;
  deleteDefaultDocument: (documentId: string | number) => Promise<void>;
  downloadDocument: (documentId: string | number) => Promise<void>;
  downloadDefaultDocument: (documentId: string | number) => Promise<string>;
  sendDocument: (
    documentId: string | number,
    string: string | undefined,
    patientId: string | number | undefined
  ) => void;
  sendMultipleEmailDocuments: (
    documentIds: (string | number)[],
    string: string | undefined,
    patientId: string | number | undefined,
    note: string | undefined
  ) => void;

  copyDefaultDocument: (
    docnewDocumentName: string,
    copyDefaultDocumentId: string | number,
    virtualStampType?: string
  ) => Promise<void>;
  copyDocument: (docnewDocumentName: string, copyDocumentId: string | number) => void;
  updateDocument: (documentId: string | number, params: any) => void;
}

function useDocumentsApi(patientId): DocumentsApiHookInterface {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [defaultDocuments, setDefaultDocuments] = useState<Map<string, Array<Document>>>(new Map());
  const [documentTypes, setDocumentTypes] = useState<Array<DocumentType>>([]);

  async function fetchDefaultDocuments(): Promise<void> {
    const defaultDocuments = await getDefaultDocuments();
    const documentsMap = new Map(Object.entries(_.groupBy(defaultDocuments, 'type')));
    setDefaultDocuments(documentsMap);
  }

  async function fetchDocuments(patientId: number): Promise<void> {
    setLoading(true);
    try {
      // Patient Documents
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        const documents = await getDocuments(cases[0].id);
        setDocuments(documents);
      }
      // Default Documents
      fetchDefaultDocuments();
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function copyDefaultDocument(
    newDocumentName: string,
    copyDefaultDocumentId: string | number,
    virtualStampType?: string
  ): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        await copyDefaultDocumentRequest(
          copyDefaultDocumentId,
          cases[0].id,
          newDocumentName,
          virtualStampType
        );
        const documents = await getDocuments(cases[0].id);
        setDocuments(documents);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function copyDocument(
    newDocumentName: string,
    copyDocumentId: string | number
  ): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        await copyDocumentRequest(copyDocumentId, cases[0].id, newDocumentName);
        const documents = await getDocuments(cases[0].id);
        setDocuments(documents);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  async function createDocument(data: NewDocument): Promise<void> {
    setLoading(true);
    let uploadStatus;
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        uploadStatus = await createDocumentRequest(cases[0].id, data);
        const documents = await getDocuments(cases[0].id);
        setDocuments(documents);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
    return uploadStatus;
  }

  async function createDefaultDocument(data: NewDefaultDocument): Promise<void> {
    setLoading(true);
    try {
      await createDefaultDocumentRequest(data);
      fetchDefaultDocuments();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  async function createMultipleDocuments(
    data: MultipleNewDocuments
  ): Promise<PromiseSettledResult<any>[]> {
    setLoading(true);
    let uploadStatus;
    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        uploadStatus = await createMultipleDocumentRequest(cases[0].id, data);
        const documents = await getDocuments(cases[0].id);
        setDocuments(documents);
      }
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
    return uploadStatus;
  }

  async function downloadDocument(documentId): Promise<any> {
    try {
      return (await downloadDocumentAPI(documentId)).signedRequest;
    } catch (e) {
      console.log('Error', e);
      return Promise.reject(e);
    }
  }

  async function downloadDefaultDocument(documentId): Promise<any> {
    try {
      return (await downloadDefaultDocumentAPI(documentId)).signedRequest;
    } catch (e) {
      console.log('Error', e);
      return Promise.reject(e);
    }
  }

  async function sendDocument(documentId, email, patientId): Promise<void> {
    try {
      return sendDocumentAPI(documentId, email, patientId);
    } catch (e) {
      console.log('Error', e);
      return Promise.reject(e);
    }
  }

  async function sendMultipleEmailDocuments(documentIds, email, patientId, note): Promise<void> {
    try {
      return sendMultipleEmailDocumentsAPI(documentIds, email, patientId, note);
    } catch (e) {
      console.log('Error', e);
      return Promise.reject(e);
    }
  }

  async function updateDocument(documentId, params): Promise<void> {
    setLoading(true);
    try {
      await updateDocumentAPI(documentId, params);
      await fetchDocuments(patientId).catch();
    } catch (e) {
      console.log(e);
      throw e;
    }
    setLoading(false);
  }

  async function deleteDefaultDocument(documentId): Promise<void> {
    setLoading(true);
    try {
      await deleteDefaultDocumentAPI(documentId);
      await fetchDefaultDocuments();
    } catch (e) {
      console.log(e);
      throw e;
    }
    setLoading(false);
  }

  async function fetchDocumentTypes(): Promise<void> {
    setLoading(true);
    try {
      const documents = await getDocumentTypes();
      setDocumentTypes(documents);
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchDocuments(patientId).catch();
  }, [patientId]);

  useEffect(() => {
    fetchDocumentTypes().catch();
  }, []);

  return {
    loading,
    documents,
    defaultDocuments,
    documentTypes,
    deleteDefaultDocument,
    createDefaultDocument,
    fetchDefaultDocuments,
    createDocument,
    createMultipleDocuments,
    downloadDocument,
    downloadDefaultDocument,
    copyDefaultDocument,
    copyDocument,
    sendDocument,
    sendMultipleEmailDocuments,
    updateDocument,
  };
}

export default useDocumentsApi;
