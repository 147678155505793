import React, { memo, ReactElement } from 'react';
import moment from 'moment';

import CVCButton from 'components/CVCButton';
import ProfileInfoRecord from 'components/ProfileInfoRecord';
import ConditionDiv from 'components/ConditionDiv';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import RoomIcon from '@material-ui/icons/Room';
import { NavLink } from 'react-router-dom';
import styles from './PatientInfoStyles.module.scss';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { Address, Document } from 'types';

interface Contact {
  type: string;
  value: string;
}

interface PatientProps {
  patientId?: number;
  firstName?: string;
  lastName?: string;
  gender?: string;
  date?: string;
  email?: string;
  hcn?: string;
  contacts?: Array<Contact>;
  healthCardNumber?: string;
  address?: Address[];
  nextAppointment?: AppointmentInfo | null;
  provinceCode?: string;
  referrerFirstName?: string;
  referrerLastName?: string;
  referrerEmail?: string;
  referrerPhone?: string;
  referrerFax?: string;
  referrerBilling?: string;
  referrerDocument?: Document;
  referrerDocumentStatus?: boolean;
  familyPhysicianFirstName?: string;
  familyPhysicianLastName?: string;
  familyPhysicianEmail?: string;
  familyPhysicianPhone?: string;
  familyPhysicianFax?: string;
  familyPhysicianBilling?: string;
  onEditProfileClick?: () => void;
  onEditAddressClick?: () => void;
}

function Patient({
  patientId,
  firstName,
  lastName,
  gender,
  date,
  email,
  contacts = [],
  healthCardNumber,
  hcn = '',
  nextAppointment,
  address,
  provinceCode = '',
  referrerFirstName = '',
  referrerLastName = '',
  referrerEmail = '',
  referrerPhone = '',
  referrerFax = '',
  referrerBilling = '',
  referrerDocumentStatus = false,
  familyPhysicianFirstName = '',
  familyPhysicianLastName = '',
  familyPhysicianEmail = '',
  familyPhysicianPhone = '',
  familyPhysicianFax = '',
  familyPhysicianBilling = '',
  onEditProfileClick,
  onEditAddressClick,
}: PatientProps): ReactElement {
  const hasReferingDoctorInfo =
    referrerFirstName ||
    referrerLastName ||
    referrerEmail ||
    referrerPhone ||
    referrerFax ||
    referrerBilling;
  const hasFamilyDoctorInfo =
    familyPhysicianFirstName ||
    familyPhysicianLastName ||
    familyPhysicianEmail ||
    familyPhysicianPhone ||
    familyPhysicianFax ||
    familyPhysicianBilling;

  return (
    <div className={styles.patientInfo}>
      <div className={styles.top}>
        <div className={styles.patientName}>
          {firstName} {lastName}
        </div>
        <div className={styles.date}>
          {date ? `${date},` : ''} <div className={styles.gender}>{gender}</div>
        </div>
        <div className={styles.buttonMargin}>
          <CVCButton onClick={onEditProfileClick}>Edit Profile</CVCButton>
          <CVCButton onClick={onEditAddressClick}>Edit Address</CVCButton>
        </div>
        <NavLink
          onClick={event => event.stopPropagation()}
          to={{
            pathname: '/appointments',
            state: { presetPatient: { id: patientId, name: `${firstName} ${lastName}` } },
          }}
          title="Go to appointments"
          className={styles.newAppointment}
        >
          <CVCButton>New Appointment</CVCButton>
        </NavLink>
      </div>
      <ProfileInfoRecord value={email} label="Email" />
      {contacts
        .filter(c => !!c.value)
        .map(contact => (
          <ProfileInfoRecord
            // Check if phone number or email
            value={contact.value.includes('@') ? contact.value : formatPhoneInput(contact.value)}
            label={contact.type}
            key={contact.type}
          />
        ))}
      {!healthCardNumber ? (
        <></>
      ) : (
        <>
          <ProfileInfoRecord value={healthCardNumber} label="Healthcard #" />
          {hcn ? <ProfileInfoRecord value={hcn} label="Healthcard Version" /> : <></>}
          <ProfileInfoRecord value={provinceCode} label="Healthcard Province" />
        </>
      )}
      {address?.map(address => (
        <div key={address.id}>
          <ProfileInfoRecord key={address.line1} value={address.line1} label={'Address Line 1'} />
          <ProfileInfoRecord key={address.line2} value={address.line2} label={'Address Line 2'} />
          <ProfileInfoRecord key={address.city} value={address.city} label={'City'} />
          <ProfileInfoRecord
            key={address.postalCode}
            value={address.postalCode}
            label={'Postal Code'}
          />
          <ProfileInfoRecord key={address.province} value={address.province} label={'Province'} />
        </div>
      ))}
      {hasReferingDoctorInfo ? (
        <div>
          <ProfileInfoRecord value={referrerFirstName} label="Referring Doc First Name" />
          <ProfileInfoRecord value={referrerLastName} label="Referring Doc Last Name" />
          <ProfileInfoRecord value={referrerEmail} label="Referring Doc Email" />
          <ProfileInfoRecord
            value={formatPhoneInput(referrerPhone)}
            label="Referring Doc Phone #"
          />
          <ProfileInfoRecord value={formatPhoneInput(referrerFax)} label="Referring Doc Fax #" />
          <ProfileInfoRecord value={referrerBilling} label="Referring Doc Billing Number" />
          <ProfileInfoRecord
            value={referrerDocumentStatus ? 'Received' : 'Missing'}
            label="Referrer Document Status"
          />
        </div>
      ) : null}

      {hasFamilyDoctorInfo ? (
        <div>
          <ProfileInfoRecord value={familyPhysicianFirstName} label="Family Doc First Name" />
          <ProfileInfoRecord value={familyPhysicianLastName} label="Family Doc Last Name" />
          <ProfileInfoRecord value={familyPhysicianEmail} label="Family Doc Email" />
          <ProfileInfoRecord
            value={formatPhoneInput(familyPhysicianPhone)}
            label="Family Doc Phone #"
          />
          <ProfileInfoRecord
            value={formatPhoneInput(familyPhysicianFax)}
            label="Family Doc Fax #"
          />
          <ProfileInfoRecord value={familyPhysicianBilling} label="Family Doc Billing Number" />
        </div>
      ) : null}

      <div className={styles.title}>Next Appointment</div>
      <ConditionDiv condition={!!nextAppointment}>
        <ProfileInfoRecord
          value={moment(nextAppointment?.start).format('D MMMM YYYY [at] h:mmA')}
          label="Appointment start"
        />
        <ProfileInfoRecord
          value={nextAppointment?.location?.name}
          label="Location"
          rightContent={<RoomIcon className={styles.marginRight} />}
        />
        <ProfileInfoRecord value={nextAppointment?.staff?.fullName} label="Staff" />
      </ConditionDiv>
      <ConditionDiv className={styles.noInfo} condition={!nextAppointment}>
        No Appointment
      </ConditionDiv>
    </div>
  );
}

export default memo(Patient);
