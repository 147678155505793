import React, { Fragment, ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

import useDocumentsApi from './useDocumentsApi.hook';
import usePatientApi from '../PatientInfo/usePatientApi.hook';

import Documents from './components/Documents';
import DefaultDocuments from './components/DefaultDocuments';

import styles from './DocumentsStyles.module.scss';

function Document(props): ReactElement {
  const patientId = props?.match?.params?.patientId;
  const documentsApiHook = useDocumentsApi(patientId);
  const patientApiHook = usePatientApi(patientId);

  return (
    <Fragment>
      <Grid container>
        <Grid className={styles.left} item sm={6} xs={12}>
          <Documents
            patientId={patientId}
            history={props?.history}
            documentsApiHook={documentsApiHook}
            familyPhysicianEmail={patientApiHook.patient?.patientInfo?.familyPhysicianEmail}
            familyPhysicianFax={patientApiHook.patient?.patientInfo?.familyPhysicianFax}
            patientName={patientApiHook.patient?.fullName ? patientApiHook.patient?.fullName : ''}
            patientEmail={
              patientApiHook.patient?.email ? patientApiHook.patient?.email : 'No Email Found.'
            }
            patient={patientApiHook.patient}
          />
        </Grid>
        <Grid className={styles.right} item sm={6} xs={12}>
          <DefaultDocuments
            patientId={patientId}
            history={props?.history}
            documentsApiHook={documentsApiHook}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Document;
