export const PROMISE_ALL_SETTLED_RESULT = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

type PROMISE_ALL_SETTLED_POLYFILL_TYPE = {
  status: string;
  value?: any;
  reason?: any;
};

export async function allSettledPolyfill(
  promises: Array<Promise<any>>
): Promise<Array<PROMISE_ALL_SETTLED_POLYFILL_TYPE>> {
  const settledPromises: Array<Promise<PROMISE_ALL_SETTLED_POLYFILL_TYPE>> = promises.map(p => {
    return p
      .then(value => {
        return {
          status: PROMISE_ALL_SETTLED_RESULT.FULFILLED,
          value,
        };
      })
      .catch(reason => {
        return {
          status: PROMISE_ALL_SETTLED_RESULT.REJECTED,
          reason,
        };
      });
  });
  return Promise.all(settledPromises);
}
