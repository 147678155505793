import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './EditableParagraph.module.scss';
import classNames from 'classnames';

interface EditableParagraphProps {
  paragraphText?: string;
  setParagraphText: (string) => void;
}

export const EditableParagraph = ({ paragraphText, setParagraphText }: EditableParagraphProps) => {
  const [text, setText] = useState<string>(paragraphText || '');

  const cleanupWhitespace = textFullOfWhitespace => {
    return textFullOfWhitespace.replace(/\n/g, '').replace(/\s+/g, ' ');
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setParagraphText(text);
    }, 250);
    return (): void => clearTimeout(debounce);
  }, [text]);

  if (!paragraphText) {
    return <p className={styles.paragraph}>{null}</p>;
  }

  return (
    <TextField
      className={styles.textArea}
      multiline={true}
      fullWidth
      value={cleanupWhitespace(text)}
      onChange={e => {
        setText(e.target.value);
      }}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default EditableParagraph;
