import React, { ReactElement, useState, useEffect } from 'react';
import { EditableUser, OtherPhysician, PossiblePatient } from 'types';
import classNames from 'classnames';
import moment from 'moment';
import { Moment } from 'moment';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Checkbox } from '@material-ui/core';
import Dialog from '../Dialog';
import Select from '../Select';
import DialogButton from '../DialogButton';
import CVCHealthCardInput from 'components/CVCHealthCardInput';

import styles from './PatientEditDialogStyles.module.scss';
import CVCDatePicker from '../CVCDatePicker';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { GENDERS, PREFIXES } from 'lib/patient';
import { formatEmail, ADD_NEW_PHYSICIAN } from 'lib/user';
import { MAX_PHONE_CHARACTER_LENGTH } from 'lib/phoneNumFormatter';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { AlertDialog } from 'components/AlertDialog';
import SelectPhysician from 'pages/Appointments/components/Select/Select.view';
import { UserApi } from 'api';
import OtherPhysicianAutoComplete from 'components/OtherPhysicianAutoComplete';
import OtherPhysicianEditDialog from 'components/OtherPhysicianEditDialog';
import InfoRow from 'components/InfoRow';
import { create } from 'lodash';
import PossiblePatientItem from './components/PossiblePatientItem';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';
import IFrame from 'components/IFrame';
import ValidateHealthcardDialog from 'components/ValidateHealthcardDialog';
import { CanadaProvinceCodes } from 'lib/patient';
import { MIME_TYPE_PDF } from 'lib/document';

interface Error {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  cellPhone?: string;
  homePhone?: string;
  province?: string;
  healthCard?: string;
  hcn?: string;
  gender?: string;
  provinceCode?: string;
  patientInfoId?: number;
  other?: string;
  dateOfBirth?: any;
  familyDoctorId?: string;
  familyPhysicianFirstName?: string;
  familyPhysicianLastName?: string;
}

interface PatientEditDialogProps {
  open: boolean;
  value: EditableUser;
  errors?: Error;
  title?: string;
  loading?: boolean;
  newPatient?: boolean;
  pdfUrl?: string;
  onChange?: (name: string, value: any) => void;
  onClose?: () => void;
  onSave?: () => void;
}

function PatientEditDialog({
  open = false,
  value,
  errors,
  title,
  pdfUrl,
  onChange = (name, value) => null,
  onClose,
  onSave,
  loading = false,
  newPatient = false,
}: PatientEditDialogProps): ReactElement {
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<Moment | undefined>(undefined);
  const [valueUpdated, setValueHasBeenUpdated] = useState<boolean>(false);
  const [patientInfoState, setPatientInfoState] = useState({
    healthCareProvinceCode: '',
    healthCardNumber: '',
    email: '',
    phone: '',
    cellPhone: '',
    homePhone: '',
  });
  const [updateFamilyPhysician, setUpdateFamilyPhysician] = useState<boolean>(false);
  const [selectedFamilyPhysician, setSelectedFamilyPhysician] = useState<OtherPhysician>();
  const [otherPhysicians, setOtherPhysicians] = useState<Array<OtherPhysician>>([]);
  const [familyPhysicianOptions, setFamilyPhysicianOptions] = useState<any | undefined>([]);
  const [familyPhysicianSearchQuery, setFamilyPhysicianSearchQuery] = useState<string>('');
  const [createNewFamilyPhysician, setCreateNewFamilyPhysician] = useState<boolean>(false);
  const [openCreatePhysicianDialog, setOpenCreatePhysicianDialog] = useState<boolean>(false);
  const [openValidateHealthcardDialog, setOpenValidateHealthcardDialog] = useState<boolean>(false);
  const [possiblePatients, setPossiblePatients] = useState<Array<PossiblePatient>>([]);
  const [openNavigationWarning, setOpenNavigationWarning] = useState<boolean>(false);
  const [showPdf, setShowPdf] = useState<boolean>(false);

  const resetFamilyPhysician = () => {
    setSelectedFamilyPhysician(undefined);
  };

  const resetState = () => {
    setPatientInfoState({
      healthCareProvinceCode: '',
      healthCardNumber: '',
      email: '',
      phone: '',
      cellPhone: '',
      homePhone: '',
    });
    setShowPdf(false);
    resetFamilyPhysician();
    setUpdateFamilyPhysician(false);
    setOpenNavigationWarning(false);
  };
  const fullClose = () => {
    if (!onClose) return;
    onClose();
    resetState();
  };
  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(fullClose);

  const onCreatePhysicianSaveSuccess = (physician: OtherPhysician) => {
    setSelectedFamilyPhysician(physician);
    setOpenCreatePhysicianDialog(false);
  };

  function onChangeValue(name, value) {
    onChange(name, value);
    setChangesMade(true);
    setValueHasBeenUpdated(true);
  }

  const onValidateHealthcardSuccess = healthcardInfo => {
    onChangeValue('firstName', healthcardInfo.firstName);
    onChangeValue('lastName', healthcardInfo.lastName);
    if (healthcardInfo.dateOfBirth) {
      setSelectedDateOfBirth(moment(healthcardInfo.dateOfBirth));
      onChange('dateOfBirth', moment(healthcardInfo.dateOfBirth));
    }
    onChangeValue('gender', healthcardInfo.gender);

    setOpenValidateHealthcardDialog(false);
  };

  function onChangeDateOfBirth(newValue) {
    setSelectedDateOfBirth(moment(newValue));
    onChange('dateOfBirth', newValue);
    setValueHasBeenUpdated(true);
  }

  const healthCardObj = {
    hcn: value.hcn,
    healthCard: value.healthCard,
    healthCardProvinceCode: value.healthCardProvinceCode,
  };

  const handleFamilyPhysicianChange = (event, value): void => {
    if (value?.fullName != ADD_NEW_PHYSICIAN) {
      const physician = value;
      setSelectedFamilyPhysician(physician);
    } else {
      setOpenCreatePhysicianDialog(true);
      setSelectedFamilyPhysician(undefined);
    }
  };

  const localClose = () => {
    if (changesMade) {
      setWarningOpen(true);
      return;
    }
    fullClose();
  };

  async function getOtherPhysicians(searchQuery: string | undefined): Promise<any> {
    if (searchQuery === ADD_NEW_PHYSICIAN) return [];
    const otherPhysicians = await UserApi.getOtherPhysiciansList(searchQuery);

    const autoComplete = otherPhysicians?.map(physician => {
      const lastNameLetter = physician.lastName ? physician.lastName[0].toUpperCase() : '';
      return {
        lastNameLetter: /[0-9]/.test(lastNameLetter) ? '0-9' : lastNameLetter,
        ...physician,
      };
    });

    return autoComplete.sort((a, b) => {
      return a.lastNameLetter.localeCompare(b.lastNameLetter);
    });
  }

  const getFamilyPhysicianList = async (searchQuery: string | undefined = undefined) => {
    const filteredPhysicians = await getOtherPhysicians(searchQuery);
    setFamilyPhysicianOptions(filteredPhysicians);
  };

  async function getFamilyPhysicianById(id: number): Promise<any> {
    const physician = await UserApi.getOtherPhysicianById(id);
    setChangesMade(true);
    setSelectedFamilyPhysician(physician);
  }

  const handleHealthCardCheckBox = event => {
    if (value.healthCard != '') {
      setOpenWarningDialog(true);
      return;
    }
    setChangesMade(true);
    onChangeValue('hcn', '');
    onChangeValue('healthCardProvinceCode', '');
    onChangeValue(event.target.name, event.target.checked);
  };

  const handleFamilyPhysicianCheckBox = event => {
    const checked = event.target.checked;
    setUpdateFamilyPhysician(checked);
    setChangesMade(true);
    resetFamilyPhysician();
    onChangeValue(event.target.name, checked);
  };

  const findPossiblePatients = async () => {
    const params = {
      firstName: value.firstName ? value.firstName : undefined,
      lastName: value.lastName ? value.lastName : undefined,
      healthCard: value.healthCard ? value.healthCard : undefined,
      phone: value.phone ? value.phone : undefined,
      email: value.email ? value.email : undefined,
    };
    if (
      !open ||
      !newPatient ||
      (!params.firstName &&
        !params.lastName &&
        !params.healthCard &&
        !params.phone &&
        !params.email)
    ) {
      setPossiblePatients([]);
      return;
    }
    const possiblePatients = await UserApi.getPossiblePatients(params);
    setPossiblePatients(possiblePatients);
  };

  useEffect(() => {
    const debounce = setTimeout(async () => {
      findPossiblePatients();
    }, 300);

    return (): void => clearTimeout(debounce);
  }, [value]);

  useEffect(() => {
    const id = selectedFamilyPhysician ? selectedFamilyPhysician.id : null;
    onChange('familyDoctorId', id);
    setChangesMade(true);
  }, [selectedFamilyPhysician]);

  useEffect(() => {
    onChange('createFamilyPhysician', createNewFamilyPhysician);
    setChangesMade(true);
  }, [createNewFamilyPhysician]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getFamilyPhysicianList(familyPhysicianSearchQuery);
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [familyPhysicianSearchQuery]);

  useEffect(() => {
    if (!open) {
      resetState();
      setSelectedDateOfBirth(undefined);
      return;
    }
    if (value.familyDoctorId) {
      getFamilyPhysicianById(value.familyDoctorId);
    }

    onChange('newPatient', newPatient);
    setShowPdf(!!pdfUrl);

    // Get Family Physician Here
  }, [open]);

  return (
    <Dialog fullWidth={showPdf} open={open} title={title || 'Edit patient'} onClose={localClose}>
      <div className={styles.container}>
        <div>
          <form className={styles.formContainer}>
            {showPdf && (
              <div className={styles.pdfColumn}>
                {showPdf && <IFrame url={pdfUrl} type={MIME_TYPE_PDF} title={'Fax Preview'} />}
              </div>
            )}
            <div
              className={classNames(
                styles.formColumn,
                showPdf ? styles.showingPdf : styles.hidingPdf
              )}
            >
              {!!pdfUrl && (
                <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                  <Checkbox
                    name={'displayPdf'}
                    checked={showPdf}
                    onChange={event => setShowPdf(event.target.checked)}
                  />
                  <div>{'Display Pdf'}</div>
                </div>
              )}
              <p className={styles.steps}>
                <span>Step 1:</span>
                {newPatient ? ' Create a new patient' : ' Edit existing patient'}
              </p>
              <div className={styles.formFieldContainer}>
                <div className={classNames(styles.availableOpening, styles.openingTime)}>
                  <CVCDatePicker
                    label="Date of Birth"
                    value={newPatient ? selectedDateOfBirth : value.dateOfBirth}
                    onChange={value => onChangeDateOfBirth(value)}
                    shouldShowError={!!errors?.dateOfBirth}
                  />
                </div>
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name={'firstName'}
                  label="First name"
                  error={!!errors?.firstName}
                  placeholder="Enter first name"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.firstName}
                  helperText={errors?.firstName}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  error={!!errors?.lastName}
                  name={'lastName'}
                  label="Last name"
                  placeholder="Enter last name"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.lastName}
                  helperText={errors?.lastName}
                />
              </div>

              <div className={styles.formFieldContainer}>
                <Select
                  error={errors?.gender}
                  fullWidth
                  label="Gender"
                  name={'gender'}
                  renderValue={value => <div className={styles.selectItem}>{value}</div>}
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.gender}
                >
                  {GENDERS.map(item => (
                    <MenuItem key={item} value={item} className={styles.selectItem}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  name={'noHealthCard'}
                  checked={value.noHealthCard}
                  onChange={event => handleHealthCardCheckBox(event)}
                />
                <div>{'No Health Card'}</div>
              </div>

              <div className={styles.formFieldContainer}>
                <DialogButton
                  buttonType={DialogButtonType.AffirmativeLink}
                  className={styles.btn}
                  onClick={() => setOpenValidateHealthcardDialog(true)}
                  disabled={healthCardObj.healthCardProvinceCode !== CanadaProvinceCodes.ON}
                >
                  Validate Healthcard
                </DialogButton>
              </div>

              <div className={styles.formFieldContainer}>
                <CVCHealthCardInput
                  disabled={value.noHealthCard}
                  value={healthCardObj}
                  onChange={onChangeValue}
                  hcnError={!!errors?.hcn}
                  healthCardError={value.noHealthCard === true ? false : !!errors?.healthCard}
                  hcnHelperText={errors?.hcn}
                  healthCardProvinceError={errors?.province}
                  healthCardHelperText={errors?.healthCard}
                  starFromEditAppt={false}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  error={!!errors?.email}
                  fullWidth
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Enter email"
                  onChange={({ target }) => onChangeValue(target.name, formatEmail(target.value))}
                  value={value.email}
                  helperText={errors?.email}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  error={!!errors?.phone}
                  name="phone"
                  label="Phone"
                  placeholder="Enter phone"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={formatPhoneInput(value.phone)}
                  helperText={errors?.phone}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name="cellPhone"
                  label="Cell phone (optional)"
                  placeholder="Enter phone"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={formatPhoneInput(value.cellPhone)}
                  helperText={errors?.cellPhone}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name="homePhone"
                  label="Home phone (optional)"
                  placeholder="Enter phone"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={formatPhoneInput(value.homePhone)}
                  helperText={errors?.homePhone}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                />
              </div>
            </div>
            <div
              className={classNames(
                styles.formColumn,
                showPdf ? styles.showingPdf : styles.hidingPdf
              )}
            >
              <p className={styles.steps}>
                <span>Step 2:</span> Add or edit a family physcian.
              </p>
              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  checked={updateFamilyPhysician}
                  name={'updateFamilyPhysician'}
                  onChange={event => handleFamilyPhysicianCheckBox(event)}
                />
                {newPatient === false ? (
                  <div>{'Update Family Physician'}</div>
                ) : (
                  <div>{'Add Family Physician'}</div>
                )}
              </div>
              <div className={styles.formFieldContainer}>
                <OtherPhysicianAutoComplete
                  options={familyPhysicianOptions}
                  disabled={!updateFamilyPhysician}
                  value={selectedFamilyPhysician}
                  handleChange={handleFamilyPhysicianChange}
                  searchQuery={familyPhysicianSearchQuery}
                  setSearchQuery={setFamilyPhysicianSearchQuery}
                  helperText={errors?.familyDoctorId}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow
                  label={'Phone Number'}
                  value={formatPhoneInput(selectedFamilyPhysician?.phone || '')}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Email'} value={selectedFamilyPhysician?.email || ''} />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow
                  label={'Fax'}
                  value={formatPhoneInput(selectedFamilyPhysician?.fax || '')}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Billing'} value={selectedFamilyPhysician?.billing || ''} />
              </div>
              {selectedFamilyPhysician ? (
                <div className={styles.buttonSection}>
                  <DialogButton
                    buttonType={DialogButtonType.AffirmativeLink}
                    className={styles.btn}
                    onClick={() => setOpenCreatePhysicianDialog(true)}
                    disabled={!selectedFamilyPhysician}
                  >
                    Edit Physician
                  </DialogButton>
                </div>
              ) : (
                <></>
              )}
              {newPatient && (
                <div className={styles.possiblePatients}>
                  <p className={styles.steps}>
                    <span>Possible Patients:</span>
                  </p>
                  {possiblePatients.map(patient => {
                    return (
                      <div key={patient.id}>
                        <PossiblePatientItem patient={patient} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </form>
          <div className={styles.textError}>{errors?.other}</div>
          <div className={styles.buttonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={localClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onSave}
              loading={loading}
            >
              Save
            </DialogButton>
          </div>
        </div>
      </div>
      <OtherPhysicianEditDialog
        open={openCreatePhysicianDialog}
        onClose={(): void => setOpenCreatePhysicianDialog(false)}
        onSaveSuccess={onCreatePhysicianSaveSuccess}
        selectedPhysician={selectedFamilyPhysician}
      />
      {LocalPrompt}

      <ValidateHealthcardDialog
        open={openValidateHealthcardDialog}
        onClose={(): void => setOpenValidateHealthcardDialog(false)}
        onSaveSuccess={onValidateHealthcardSuccess}
        healthcardNumber={healthCardObj?.healthCard as string}
        healthcardCode={healthCardObj?.hcn as string}
        oldFirstName={value.firstName as string}
        oldLastName={value.lastName as string}
        oldDateOfBirth={
          newPatient ? (selectedDateOfBirth as moment.Moment) : (value.dateOfBirth as moment.Moment)
        }
        oldGender={value.gender as string}
        includeFirstName={true}
        includeLastName={true}
        includeDateOfBirth={true}
        includeGender={true}
      />

      <AlertDialog
        open={openWarningDialog}
        title={'Error'}
        message={'Please remove health card before selecting'}
        onClose={() => setOpenWarningDialog(false)}
      />
    </Dialog>
  );
}

export default PatientEditDialog;
