import React, { ReactElement, useContext } from 'react';
import Layout from 'Layout';

import PDFAnnotator from 'components/PDFAnnotator';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';

import styles from './FormDocumentStyles.module.scss';
import { buildDate } from 'lib/config';
import { Context as AuthContext, ROLE } from 'context/auth';

interface DocumentEditorProps {
  url: string;
  onCancel(): void;
  onSave(blob): void;
  setSaving(state): void;
  saving: boolean;
  sendToPatient: boolean;
}

function FormDocumentView({ sendToPatient, saving, ...props }: DocumentEditorProps): ReactElement {
  const saveButtonText = sendToPatient ? 'Send' : 'Complete';
  const saveDialogTitle = sendToPatient ? 'Sending Form Document' : 'Saving Form Document';
  const saveDialogText = sendToPatient
    ? 'Saving and Sending the Form Document'
    : 'The Form Document is being saved';
  const saveDialogButtonText = sendToPatient ? 'Sending...' : 'Saving...';
  const { role } = useContext(AuthContext);

  return (
    <Layout hideFooter>
      {role == ROLE.ANONYMOUS && (
        <div style={{ position: 'absolute' }}> Build Date: {buildDate}</div>
      )}
      <PDFAnnotator {...props} saveText={saveButtonText}></PDFAnnotator>
      <Dialog open={saving} title={saveDialogTitle}>
        <div className={styles.container}>
          <p className={styles.text}>{saveDialogText}</p>
          <div className={styles.buttons}>
            <DialogButton disabled={saving}>
              {saving ? saveDialogButtonText : 'Complete'}
            </DialogButton>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}

export default FormDocumentView;
