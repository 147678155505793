import React, { ReactElement, useEffect, useState } from 'react';
import Selector from 'components/Selector';
import Button from 'components/Button';
import { Icon, IconButton } from '@material-ui/core';
import Checkbox from 'components/Checkbox';
import FirstAppointment from './components/FirstAppointment';
import { SLOT_LENGTH_MINIMUM_5_MINUTES } from 'lib/appointments';
import styles from './ResponsiveTopBar.module.scss';
import AppointmentStatusSelector from './AppointmentStatusSelector.container';
import { AppointmentsApi, LocationApi } from 'api';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { SubType, AppointmentAvailabilityNext } from 'types';

interface Location {
  id: number;
  name: string;
}

interface AppointmentType {
  id: number;
  name: string;
}

interface SelectedLength {
  id: number;
  offsetSpaceUse: number;
  name: string;
}

interface ResponsiveTopBarProps {
  searchQuery: string | null;
  types: Array<any>;
  appointmentStatus?: number | null;
  referrerStatusId?: number | null;
  typeId: string | number | null;
  staffListForLocation: Array<UserStaff>;
  selectedStaff: number | null | undefined;
  highlightOnlyCancellationAppts: boolean;
  selectedLocation: number;
  staffList: Array<UserStaff> | undefined;
  setSelectedStaff: (staff) => void;
  changeDate: (Moment) => void;
  setSelectedLocation: (locationId) => void;
  onNewAppointmentClick: (basicSetup: boolean, details?: any) => void;
  onChangeSearchQuery: (query) => void;
  onNewAppointment: (startTime, endTime, scheduleType, availabilityID) => void;
  selectType: (type) => void;
  selectAppointmentStatus: (number) => void;
  selectReferrerStatusId: (number) => void;
  setHighlightOnlyCancellationAppts: (boolean) => void;
}

function ResponsiveTopBar({
  typeId,
  types,
  appointmentStatus,
  staffListForLocation,
  selectedStaff,
  highlightOnlyCancellationAppts,
  selectedLocation,
  staffList,
  setSelectedStaff,
  setSelectedLocation,
  onNewAppointmentClick,
  changeDate,
  selectType,
  selectAppointmentStatus,
  setHighlightOnlyCancellationAppts,
}: ResponsiveTopBarProps): ReactElement {
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [locations, setLocations] = useState<Array<Location>>([]);
  const [location, setLocation] = useState<Location>();

  const [prevSelectedLocation, setPrevSelectedLocation] = useState<number>();
  const [allAppointmentTypes, setAllAppointmentTypes] = useState<Array<any>>([]);
  const [hideFilters, setHideFilters] = useState<boolean>(false);

  const resetFilters = (): void => {
    selectAppointmentStatus(-1);
    selectType(-1);
    setSelectedStaff(-1);
  };

  useEffect(() => {
    if (
      (typeId && typeId != -1) ||
      (selectedStaff && selectedStaff != -1) ||
      (appointmentStatus && appointmentStatus != -1) ||
      highlightOnlyCancellationAppts
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [typeId, selectedStaff, appointmentStatus, highlightOnlyCancellationAppts]);

  const getLocations = async (): Promise<void> => {
    const locations = await LocationApi.getLocations();
    setLocations(locations);
  };

  const resetForm = (): void => {
    setOpen(false);
    setLoading(false);
    setLocation(undefined);
    setShowError(false);
  };

  const closeModal = (): void => {
    resetForm();
    setSelectedLocation(prevSelectedLocation);
  };

  const onSelectOpening = (date, location, appointmentDetails) => {
    changeDate(date);
    setSelectedLocation(location);
    onNewAppointmentClick(true, appointmentDetails);
    resetForm();
  };

  useEffect(() => {
    setPrevSelectedLocation(selectedLocation);
    getLocations();
  }, [open]);

  useEffect(() => {
    const data: any = [];
    types.forEach(type => {
      if (type.completeName) {
        data.push({
          id: type.id,
          name: type.completeName,
        });
      }
    });
    setAllAppointmentTypes(data);
  }, [types]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Appointments</div>

      <div className={styles.filterControl}>
        <IconButton onClick={() => setHideFilters(!hideFilters)}>
          <Icon>{hideFilters ? <ChevronRight /> : <ExpandMore />}</Icon>
        </IconButton>
      </div>
      {!hideFilters && (
        <div className={styles.toolbar}>
          <div className={styles.selectors}>
            <AppointmentStatusSelector
              value={appointmentStatus}
              onChange={event => selectAppointmentStatus(event.target.value)}
            />
            <Selector
              label={'Type'}
              items={allAppointmentTypes}
              value={typeId}
              onChange={event => selectType(event.target.value || null)}
            />
            <Selector
              label={'Staff'}
              items={staffListForLocation}
              value={selectedStaff}
              onChange={event => {
                setSelectedStaff(event.target.value || null);
              }}
            />
            <div className={styles.checkbox}>
              <Checkbox
                title="Cancellation List"
                checked={highlightOnlyCancellationAppts}
                onChange={event => setHighlightOnlyCancellationAppts(event.target.checked)}
              />
            </div>
            {isFiltered && <Button onClick={resetFilters}>Reset Filters</Button>}
            <Button onClick={() => setOpen(true)}>First Appointment</Button>
            {/* TO DO: searchQuery */}
          </div>
          {/* <div className={styles.selectors} style={{ justifyContent: 'flex-end' }}>
            <div style={{ margin: '2px' }}>
              <InputSearch
                value={searchQuery}
                onChange={event => onChangeSearchQuery(event.target.value)}
              />
            </div>
          </div> */}
        </div>
      )}

      <FirstAppointment
        open={open}
        showError={showError}
        staffList={staffList}
        locations={locations}
        closeModal={closeModal}
        onSelectOpening={onSelectOpening}
      />
    </div>
  );
}

export default ResponsiveTopBar;
