import React, { useState } from 'react';
import { stylesPdf } from 'lib/workflowPdf';
import styles from './WorkflowPDFStyles.module.scss';
import {
  Page,
  Text,
  View,
  Document,
  Image as PDFImage,
  PDFDownloadLink,
} from '@react-pdf/renderer';

import Button from 'components/Button';
import FullLogoTagline from 'assets/images/full-logo-tagline.png';
import useWorkflowPDF from './useWorkflowPDF.hook';
import {
  PatientLabels,
  PatientMetaLabels,
  parseText,
  calculatePatientResultsMap,
} from 'pages/WorkflowResult/components/PatientResults/lib/calculatePatientResults';
import { TechResultPDFFragment } from '../TechResultPDFFragment';

interface WorkflowPDFComponentProps {
  caseId?: number;
  formId?: number;
  patientId?: number;
  paragraphTexts?;
  coverLetterHeaderFooter?;
  history;
  everyFormCompleted: boolean;
}

export const WorkflowPDFComponent = ({
  formId,
  caseId,
  patientId,
  paragraphTexts,
  coverLetterHeaderFooter,
  history,
  everyFormCompleted,
}: WorkflowPDFComponentProps) => {
  const [pdfDocumentURL, setPDFDocumentURL] = useState('');
  const [pdfDocumentCoverLetterURL, setPDFDocumentCoverLetterURL] = useState('');

  const { formCaseResult, saveReport, saveCoverLetter, loading } = useWorkflowPDF({
    pdfDocumentURL,
    pdfDocumentCoverLetterURL,
    patientId,
    formId,
    caseId,
  });

  const cleanupWhitespace = textFullOfWhitespace => {
    return textFullOfWhitespace.replace(/^\s+|\s+$/gm, '');
  };

  const answersMap = calculatePatientResultsMap(formCaseResult?.answers);
  const labelKeysPatientMeta = Array.from(PatientMetaLabels.keys());
  const labelKeysPatientAnswers = Array.from(PatientLabels.keys());

  const CoverLetterDocument = (
    <Document>
      <Page size="A4" style={stylesPdf.letterPage}>
        <View style={stylesPdf.letterSection}>
          <View style={stylesPdf.letterHeader}>
            <View style={stylesPdf.headerImage}>
              <PDFImage src={FullLogoTagline}></PDFImage>
            </View>
          </View>
          <Text style={stylesPdf.headerNote}>Phone – 888-876-8346 Fax – 705-880-1211</Text>
          <Text style={stylesPdf.headerLocations}>
            Richmond Hill – Vaughan – Markham – Toronto – Mississauga – Halifax – Moncton – Ottawa –
            Orleans – Kanata – Kawartha Lakes
          </Text>
          <View style={stylesPdf.letterContent}>
            <Text>{coverLetterHeaderFooter && coverLetterHeaderFooter.header}</Text>
            <Text style={stylesPdf.letterContentDate}>
              Date: {coverLetterHeaderFooter && coverLetterHeaderFooter.date}
            </Text>
            <Text style={stylesPdf.letterContentTitle}>
              {coverLetterHeaderFooter && coverLetterHeaderFooter.title}
            </Text>
            <View style={stylesPdf.letterBody}>
              {paragraphTexts.map((pt, id) => {
                if (pt && !pt.hide) {
                  return (
                    <View style={stylesPdf.paragraph}>
                      <Text>{cleanupWhitespace(pt.text)}</Text>
                    </View>
                  );
                }
              })}
            </View>
            <View>
              <Text>Sincerely,</Text>
              <Text>Dictated, not read</Text>
              <Text>{coverLetterHeaderFooter && coverLetterHeaderFooter.doctorSignature}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const FullResultsDocument = (
    <Document>
      <Page size="A4" style={stylesPdf.page}>
        <View style={stylesPdf.section}>
          <View>
            <View style={stylesPdf.header}>
              <Text>Patient Data</Text>
            </View>
            <View>
              <Text>Basic Info</Text>
              <View>
                {answersMap &&
                  labelKeysPatientMeta.map(pmk => {
                    return (
                      <View key={answersMap.get(pmk) && answersMap.get(pmk).id}>
                        {answersMap.get(pmk) && (
                          <Text style={stylesPdf.infoMeta}>
                            {PatientMetaLabels.get(pmk)}: {parseText(answersMap.get(pmk))}
                          </Text>
                        )}
                      </View>
                    );
                  })}
              </View>
              <Text>Patient Form Result</Text>
              <View>
                {answersMap &&
                  labelKeysPatientAnswers.map(pmk => {
                    return (
                      <View key={answersMap.get(pmk) && answersMap.get(pmk).id}>
                        {answersMap.get(pmk) && (
                          <Text style={stylesPdf.infoMeta}>
                            {PatientLabels.get(pmk)}: {parseText(answersMap.get(pmk))}
                          </Text>
                        )}
                      </View>
                    );
                  })}
              </View>
            </View>
          </View>
          <View break>
            <TechResultPDFFragment caseId={caseId} formId={formId}></TechResultPDFFragment>
          </View>

          <View break style={stylesPdf.header}>
            <Text>Letter to Family Doctor</Text>
          </View>
          <Text style={stylesPdf.headerNote}>
            Ready to send to family doctor. This is a final letter.
          </Text>
          <View style={stylesPdf.letterContent}>
            <Text>{coverLetterHeaderFooter && coverLetterHeaderFooter.header}</Text>
            <Text>DATE: {coverLetterHeaderFooter && coverLetterHeaderFooter.date}</Text>
            <Text>{coverLetterHeaderFooter && coverLetterHeaderFooter.title}</Text>
            <View style={stylesPdf.letterBody}>
              {paragraphTexts.map((pt, id) => {
                if (pt && !pt.hide) {
                  return (
                    <View style={stylesPdf.paragraph}>
                      <Text>{cleanupWhitespace(pt.text)}</Text>
                    </View>
                  );
                }
              })}
            </View>
            <View>
              <Text>Sincerely,</Text>
              <Text>Dictated, not read</Text>
              <Text>{coverLetterHeaderFooter && coverLetterHeaderFooter.doctorSignature}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  async function onSave() {
    await saveReport();
    await saveCoverLetter();
    history.push(`/patient/${patientId}/documents`);
  }

  async function previous() {
    history.push(`/patient/${patientId}/`);
  }

  return (
    <div className={styles.container}>
      <div className={styles.generateButton}>
        <Button className={styles.backToPatient} onClick={previous}>
          Back to Patient
        </Button>
        <div className={styles.divider}></div>
        <Button disabled={!everyFormCompleted} className={styles.generateButton} onClick={onSave}>
          Generate Report
        </Button>
      </div>
      <PDFDownloadLink document={FullResultsDocument}>
        {({ blob, url, loading, error }) => {
          if (url) {
            setPDFDocumentURL(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      <PDFDownloadLink document={CoverLetterDocument}>
        {({ blob, url, loading, error }) => {
          if (url) {
            setPDFDocumentCoverLetterURL(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      {loading && (
        <div className={styles.generatingReportTakeover}>
          <p>Your report is being generated...</p>
        </div>
      )}
    </div>
  );
};
