import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { UserApi, LocationApi } from 'api';
import { ScheduledStaff, User } from 'types';
import { Staff } from 'api/user/user.interfaces';
import { LocationsSchedule } from './useLocations.Interface';
import { getStaffSchedulesByLocationId } from 'api/location';
/* 
  Will GET and keep as useState all locationSchedules with the corresponding staffSchedules
*/
function useLocations(locationIds: Array<number>, dateStart: Moment) {
  const [locationSchedules, setLocationsSchedules] = useState<Array<LocationsSchedule>>([]);
  const [staffList, setStaffList] = useState<Array<Staff>>([]);

  // Staff Transform Function
  function transformUserToStaff(users: User[]): Staff[] {
    const transformedUsers: Staff[] = [];

    if (users) {
      for (const user of users) {
        let phone = '';
        let staffLocation = '';
        const phoneContacts = user.contacts?.filter(contact => contact.type === 'phone');
        if (phoneContacts && phoneContacts.length > 0) {
          phone = phoneContacts[0].value;
        }

        if (user.staffInfo && user.staffInfo.locations.length > 0) {
          staffLocation = user.staffInfo.locations[0].name;
        }

        if (user.staffInfo) {
          const transformedUser: Staff = {
            id: user.id,
            email: user.email,
            name: user.firstName + ' ' + user.lastName,
            phone,
            type: user.type,
            location: staffLocation,
            staffId: user.staffInfo?.id,
          };
          transformedUsers.push(transformedUser);
        }
      }
    }
    return transformedUsers;
  }

  async function getStaffList(searchQuery?: string, locationIds?: string): Promise<void> {
    const users = await UserApi.getStaff(
      searchQuery && searchQuery.length > 0 ? searchQuery : '',
      undefined,
      locationIds && locationIds.length > 0 ? locationIds : ''
    );

    const filteredUsers = users.filter(
      user =>
        user.type === 'doctor' ||
        user.type === 'technician' ||
        user.type === 'receptionist' ||
        user.type === 'nurse' ||
        user.type === 'noStaff'
    );

    let staffList: Staff[] = [];

    if (filteredUsers) {
      staffList = await transformUserToStaff(filteredUsers);
    }

    setStaffList(staffList);
  }

  // Integrating with APIS
  async function getLocationSchedules(locationIds: Array<number>, date: Moment) {
    // Get's specified location schedules for entire week
    const locationHours = await LocationApi.getMultipleLocationsSchedule(
      locationIds,
      date
        .clone()
        .startOf('week')
        .toDate()
        .toISOString(),
      date
        .clone()
        .endOf('week')
        .toDate()
        .toISOString(),
      null
    );
    const out: Array<LocationsSchedule> = [];
    const staffSchedulePromises: Array<Promise<ScheduledStaff[]>> = [];

    // Flatten Data
    locationHours.forEach(locationHour => {
      locationHour.schedule.forEach(schedule => {
        const momentStartTime = moment(schedule.startTime, 'HH:mm:ss').format('HH:mm');
        const momentEndTime = moment(schedule.endTime, 'HH:mm:ss').format('HH:mm');
        const momentDate = moment(locationHour.date).format('YYYY-MM-DD');

        const combinedDateStart = `${momentDate} ${momentStartTime}`;
        const combinedDateEnd = `${momentDate} ${momentEndTime}`;
        staffSchedulePromises.push(
          getStaffSchedulesByLocationId(
            schedule.location.id,
            combinedDateEnd,
            combinedDateStart,
            schedule.type
          )
        );
        const tmp: LocationsSchedule = {
          id: schedule.location.id,
          scheduleId: schedule.scheduleId,
          start: combinedDateStart.toString(),
          end: combinedDateEnd.toString(),
          type: schedule.type,
          title: schedule.location.name,
          staffSchedules: [],
          recurrence: schedule.recurrence,
          recurrenceEndDate: `${moment(schedule.endDate)}`,
          cancelled: schedule.cancelled,
        };
        out.push(tmp);
      });
    });

    const staffScheduleResults = await Promise.all(staffSchedulePromises);
    staffScheduleResults.forEach((staffScheduleResult, index) => {
      out[index].staffSchedules = staffScheduleResult;
    });

    setLocationsSchedules(out);
  }

  const refresh = () => {
    getLocationSchedules(locationIds, dateStart);
  };

  useEffect(() => {
    getLocationSchedules(locationIds, dateStart);
  }, [locationIds, dateStart]);

  // Get inital Staff list
  useEffect(() => {
    getStaffList();
  }, []);

  useEffect(() => {
    refresh();
  }, []);

  return {
    locationSchedules,
    staffList,
    refresh,
  };
}

export default useLocations;
