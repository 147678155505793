import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useAppointmentApi from 'hook/useAppointmentApi.hook';
import TitledSection from 'components/TitledSection';
import ConditionDiv from 'components/ConditionDiv';
import { formatDate, DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import { AppointmentsApi } from 'api';
import { NavLink } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import OddPaper from './components/OddPaper';
import { AppointmentStatus, APPOINTMENT_STATUSES } from 'lib/appointments';

import styles from './AppointmentsHistoryStyles.module.scss';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import UpdateAppointmentStatusDialog from 'components/UpdateAppointmentStatusDialog';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import { AlertDialog } from 'components/AlertDialog';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface HeadProps {
  sortDown: boolean;
  changeOrder(): void;
}

function Head({ sortDown, changeOrder }: HeadProps): ReactElement {
  const Arrow = sortDown ? ArrowDropDownIcon : ArrowDropUpIcon;

  return (
    <OddPaper className={styles.head} isOdd>
      <Grid container>
        <Grid item xs={1} className={styles.dateContent}>
          <div className={styles.date}>Date</div>
          <Arrow onClick={changeOrder} />
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Time slot</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Type</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.location}>Location</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Doctor</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Status</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Created By</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Updated By</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>Resend Mail</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>Go To</div>
        </Grid>
      </Grid>
    </OddPaper>
  );
}

function isOdd(num): boolean {
  return !!(num % 2);
}

function getTimeSlot(startTime, endTime): string {
  const startString = moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyCompact
  );
  const endString = moment(endTime, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyCompact
  );
  return `${startString} - ${endString}`;
}

interface AppointmentHistoryRowProps {
  date: string;
  timeSlot: string;
  type?: string;
  location?: string;
  locationId?: number;
  appointmentId?: number;
  doctor?: string;
  status?: string;
  isOdd: boolean;
  deleted?: boolean;
  createdBy?: string;
  updatedBy?: string;
  updateAppointmentStatus?: (id: number | undefined) => void;
}

function AppointmentHistoryRow({
  date,
  timeSlot,
  type,
  location,
  locationId,
  appointmentId,
  doctor,
  isOdd,
  status,
  deleted,
  createdBy,
  updatedBy,
  updateAppointmentStatus,
}: AppointmentHistoryRowProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const [sentConfirmation, setSentConfirmation] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  async function resendEmail(): Promise<void> {
    try {
      await AppointmentsApi.mailPatientAppointment(appointmentId, deleted);
      setResponseMessage('Email confirmation has been resent to the patient successfully!');
    } catch (e) {
      const message = handleAPIErrors(e, 'Error sending email');
      setResponseMessage(message);
    }
    setSentConfirmation(true);
  }

  function onCloseDialog(): void {
    setOpen(false);
    setResponseMessage('');
  }

  const canUpdate = () => {
    const statusId = APPOINTMENT_STATUSES.find(apptStatus => apptStatus.name == status);
    return statusId?.id != AppointmentStatus.Cancelled && statusId?.id != AppointmentStatus.Deleted;
  };

  return (
    <OddPaper className={styles.head} isOdd={isOdd}>
      <Grid container className={styles.container}>
        <Grid item xs={1}>
          <div className={styles.contentItem}>{date}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.contentItem}>{timeSlot}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.contentItem}>{type}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.contentItem}>{location}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.contentItem}>{doctor}</div>
        </Grid>
        <Grid item xs={1}>
          <div
            className={classNames(styles.contentItem, canUpdate() && styles.canUpdate)}
            onClick={() => {
              return updateAppointmentStatus && canUpdate()
                ? updateAppointmentStatus(appointmentId)
                : null;
            }}
          >
            {status}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.contentItem}>{createdBy}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.contentItem}>{updatedBy}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>
            <MailIcon onClick={(): void => setOpen(true)} fontSize="small" />
          </div>
        </Grid>
        {!deleted ? (
          <Grid item xs={1}>
            <div className={styles.goToItem}>
              <NavLink
                to={{
                  pathname: '/appointments',
                  state: { date: date, locationId: locationId, appointmentId: appointmentId },
                }}
                className={styles.icon}
                title="Go to appointments"
              >
                <ArrowForwardOutlinedIcon fontSize="small" />
              </NavLink>
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Dialog open={open} onClose={onCloseDialog} title={'Resend Appointment Email'}>
        {sentConfirmation ? (
          <div className={styles.container}>
            <div className={styles.containerHeader}>
              <div className={styles.appointmentOpeningInfo}>
                <p>{responseMessage}</p>
              </div>
            </div>
            <div className={classNames(styles.buttonSection)}>
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                className={styles.btn}
                onClick={onCloseDialog}
              >
                Ok
              </DialogButton>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.containerHeader}>
              <div className={styles.appointmentOpeningInfo}>
                <p>Are you sure you want to resend the appointment details to the patient?</p>
              </div>
            </div>
            <div className={classNames(styles.buttonSection)}>
              <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onCloseDialog}>
                No
              </DialogButton>
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                className={styles.btn}
                onClick={resendEmail}
              >
                Yes
              </DialogButton>
            </div>
          </div>
        )}
      </Dialog>
    </OddPaper>
  );
}

function AppointmentsHistory(props): ReactElement {
  const patientId = props?.match?.params?.patientId;
  const [sortDown, setSortDown] = useState(true);

  const { appointments, loading, changeOrder, refresh } = useAppointmentApi(patientId, true);
  const [openUpdateStatus, setOpenUpdateStatus] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentInfo>();

  const updateAppointmentStatus = id => {
    const appointment = appointments.find(appt => appt.id == id);
    setSelectedAppointment(appointment);
    setOpenUpdateStatus(true);
  };

  return (
    <TitledSection title="Appointments history">
      <Head
        sortDown={sortDown}
        changeOrder={() => {
          setSortDown(!sortDown);
          changeOrder();
        }}
      />
      <ConditionDiv condition={!!appointments.length}>
        {appointments.map((appt, index) => (
          <AppointmentHistoryRow
            key={appt.id}
            timeSlot={getTimeSlot(appt.startTimeOnly, appt.endTimeOnly)}
            isOdd={isOdd(index)}
            date={formatDate(DateTimeFormat.DayMonthYear, appt.start)}
            type={`${appt.type} (${appt.subTypeName})`}
            location={appt.location?.name}
            locationId={appt.location?.id}
            appointmentId={appt.id}
            doctor={appt?.staff?.fullName || ''}
            status={appt?.status?.status || ''}
            updatedBy={appt?.updatedBy}
            createdBy={appt?.createdBy}
            deleted={appt?.deleted}
            updateAppointmentStatus={updateAppointmentStatus}
          />
        ))}
      </ConditionDiv>
      <UpdateAppointmentStatusDialog
        open={openUpdateStatus}
        appointmentId={selectedAppointment?.id}
        onClose={() => {
          setSelectedAppointment(undefined);
          setOpenUpdateStatus(false);
        }}
        refresh={refresh}
      />

      <ConditionDiv condition={!appointments.length}>
        {loading ? 'Loading...' : 'Currently no appointments history'}
      </ConditionDiv>
    </TitledSection>
  );
}

export default AppointmentsHistory;
