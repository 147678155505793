import { useState, useEffect } from 'react';
import { NewBlobDocument, Document, DocumentType, Form } from 'types';

import {
  createBlobDocument as createBlobDocumentRequest,
  getDocument,
  getDocumentTypes,
  deleteDocument,
} from 'api/document';

import { sendForm, submitFormDocument } from 'api/form';

import { getCaseByUserId } from 'api/case';

import { getForm } from 'api/form';
import { FormStatusId } from 'lib/form';
import { bind } from 'lodash';

export interface DocumentEditState {
  loading: boolean;
  createBlobDocument(data: NewBlobDocument): void;
  form?: Form;
}

function useDocumentEditApi(patientId, caseId, formId, sendToPatient): DocumentEditState {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Form>();

  async function createBlobDocument(data: NewBlobDocument): Promise<void> {
    setLoading(true);
    try {
      data.softDelete = sendToPatient;
      const createdDocumentInfo = await submitFormDocument(formId, data);

      if (sendToPatient && createdDocumentInfo.id) {
        // send to patient
        await sendForm(formId);
      }
    } catch (e) {
      console.log('Error', e);
      throw e;
    }
    setLoading(false);
  }

  async function retrieveFormToEdit(): Promise<void> {
    try {
      const form = await getForm(formId);
      setForm(form);
    } catch (e) {
      console.log('Error', e);
    }
  }

  useEffect(() => {
    retrieveFormToEdit().catch();
  }, []);

  return {
    loading,
    createBlobDocument,
    form,
  };
}

export default useDocumentEditApi;
