import React, { ReactElement, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

import { QuestionForm } from 'types';
import { FormApi } from 'api';
import InfoModal from './components/InfoModal';
import { AlertDialog } from 'components/AlertDialog';

import Form from '../Form/Form.view';
import BirthDateCheckDialog from './components/BirthDateCheckDialog.container';
import { FormStatusId } from 'lib/form';
import sleep from 'lib/sleep';
import { handleAPIErrors } from 'lib/handleAPIErrors';

//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImVAZS5jIiwib25seUZvcm0iOjR9.Jvk4Iw8d8UNI6VD5pmZQUJeqxpVvIB8_aXyjKW2Ost8

interface TokenBody {
  id: number;
  email: string;
  onlyForm: number;
  firstName: string;
  lastName: string;
}

function PatientFormContainer(props): ReactElement {
  const [form, setForm] = useState<QuestionForm | null>(null);
  const [formId, setFormId] = useState(-1);
  const [hasBirthdateBeenAnswered, setHasBirthdateBeenAnswered] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const token = props?.match?.params?.jwtToken;
  const [open, setOpen] = useState(false);
  const [formAlreadyCompleted, setFormAlreadyCompleted] = useState(false);

  useEffect(() => {
    if (token) {
      try {
        const tokenBody: TokenBody = jwtDecode(token);
        setFormId(tokenBody.onlyForm);
        FormApi.getForm(tokenBody.onlyForm, token).then(setForm);
        setFirstName(tokenBody.firstName);
        setLastName(tokenBody.lastName);
      } catch (e) {
        console.log('ERROR');
        props?.history?.push('/404');
      }
    }
  }, [hasBirthdateBeenAnswered]);

  useEffect(() => {
    if (form?.status_id === FormStatusId.Complete) {
      setFormAlreadyCompleted(true);
    }
  }, [form]);

  function onClose() {
    setOpen(false);
    const win = window.open('https://www.google.com/', '_self');
    if (win) {
      win.location.assign('https://www.canadaveinclinics.ca/');
      win.focus();
      return false;
    }
  }

  async function onSave(sectionsAnswer): Promise<void> {
    try {
      let answ: Array<any> = [];

      sectionsAnswer.forEach(section =>
        section.forEach(answers => answers.forEach(answer => answ.push(answer)))
      );

      answ = answ.filter(a => {
        return a.id || a.value || a.questionOptionId;
      });
      if (answ.length > 0) {
        await FormApi.updateFormAnswers(
          formId,
          { templateQuestions: answ },
          props?.match?.params?.jwtToken
        );
      }
      setOpen(true);
    } catch (e) {
      const message = handleAPIErrors(e, 'Error Updating Form Answers, Please Try Again');
      setAlertMessage(message);
      setOpenAlert(true);
      console.log(e);
    }
  }

  const onBirthDateCheck = (birthDateExists: boolean) => {
    setHasBirthdateBeenAnswered(birthDateExists);
  };

  return (
    <>
      <BirthDateCheckDialog
        formId={formId}
        token={token}
        hasBirthdateBeenAnswered={hasBirthdateBeenAnswered}
        onBirthDateCheck={onBirthDateCheck}
      />
      <AlertDialog
        open={openAlert}
        title="Error"
        message={alertMessage}
        onClose={() => setOpenAlert(false)}
      />
      {hasBirthdateBeenAnswered && !formAlreadyCompleted && (
        <Form
          sections={form?.sections || []}
          onCancel={() => props?.history?.push('/')}
          onSave={onSave}
          firstName={firstName}
          lastName={lastName}
        />
      )}
      <InfoModal open={open} onClose={onClose} text={'Thank you for completing this form.'} />
      {hasBirthdateBeenAnswered && (
        <InfoModal
          open={formAlreadyCompleted}
          onClose={onClose}
          text={'This form has already been completed thank you.'}
        />
      )}
    </>
  );
}

export default PatientFormContainer;
