import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
/** If Updating material Ui change ExpansionPanel to Accordion */
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ScheduleTypes } from 'lib/scheduleTypes';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import styles from './MainSchedule.module.scss';
import ScheduleGrid from '../ScheduleGrid';
import LocationDayScheduleOptions from '../LocationDayScheduleOptions';
import StaffScheduleDialog from '../StaffScheduleDialog';
import useLocationApi from 'hook/useLocationApi.hook';
import formatDate, { DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';

import useLocationSchedule from '../../useLocationSchedule';
import { ScheduleDialog } from '../ScheduleDialog';
import { LocationsSchedule } from '../../useLocations.Interface';
import { Staff } from 'api/user/user.interfaces';
import { excludeDate, includeDate } from 'api/location';

interface MainScheduleProps {
  locationId: number;
  locationName: string;
  locationExceptions?: Array<string>;
  date: Moment;
  locationScheduleTypes: Array<number>;
  scheduleMap: Map<string, Array<LocationsSchedule>>;
  onEditLocationClick: (locationToEdit) => void;
  staffList: Array<Staff>;
  onDialogSaveSuccess: () => Promise<void>;
  highlightedStaff: number;
  onLocationScheduleSaveSuccess: () => Promise<void>;
}

function MainSchedule({
  locationScheduleTypes,
  locationId,
  locationName,
  date,
  highlightedStaff,
  scheduleMap,
  locationExceptions,
  onEditLocationClick,
  onDialogSaveSuccess,
  staffList,
  onLocationScheduleSaveSuccess,
}: MainScheduleProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('new');
  const [scheduleDialogType, setScheduleDialogType] = useState<string>('new');
  const [openStaffScheduleDialog, setOpenStaffScheduleDialog] = useState<boolean>(false);
  const [openLocationScheduleDialog, setOpenLocationScheduleDialog] = useState<boolean>(false);
  const { location, fetchLocationWithId } = useLocationApi(locationId);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [scheduleType, setScheduleType] = useState<number>(ScheduleTypes.Procedure);
  const [createStaffSchedule, setCreateStaffSchedule] = useState<boolean>(true);
  const [createLocationSchedule, setCreateLocationSchedule] = useState<boolean>(false);
  const [openExceptionWarningDialog, setOpenExceptionWarningDialog] = useState<boolean>(false);

  // TODO : Add way to keep track of which staffschedule information to load based on staffschedule click
  // Currently only first object in array information applies to all staffschedule
  const [staffScheduleInfo, setStaffScheduleInfo] = useState<any>([
    {
      staffType: 'Dr',
      staffName: 'House',
      scheduleStartTime: '9:00AM',
      scheduleEndTime: '5:00PM',
      selectedStaffScheduleFrequency: 'Never',
      color: '#008000',
      location: {
        id: -1,
        name: '',
      },
      scheduleId: -1,
    },
  ]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [locationRecurrence, setLocationRecurrence] = useState('');
  const [locationRecurrenceEndDate, setLocationRecurrenceEndDate] = useState('');

  const [selectedLocationScheduleID, setSelectedLocationScheduleID] = useState<number>(-1);
  const [selectedStaffScheduleID, setSelectedStaffScheduleID] = useState<number>(0);
  const [selectedUserID, setSelectedUserID] = useState<number>(0);
  const [cancelLocationId, setCancelLocationId] = useState<number>(0);
  const [cancelSelectedDate, setCancelSelectedDate] = useState<string>('');

  // Will represent the dates for the columns in the schedule
  const [datesOfWeek, setDatesOfWeek] = useState<Array<string>>([]);

  useEffect((): void => {
    const newDatesOfWeek: Array<string> = [];
    for (let i = 0; i < 7; i++) {
      const dateBuilder = moment(date);
      dateBuilder.add(i, 'days');
      const dateString = formatDate(DateTimeFormat.DateOnly, dateBuilder);
      newDatesOfWeek.push(dateString);
    }
    setDatesOfWeek(newDatesOfWeek);
  }, [date]);

  function handleScheduleDialogOpen(scheduleId, dateUse, scheduleType) {
    setStartDate(moment(dateUse).toDate());
    setScheduleType(scheduleType);
    setSelectedLocationScheduleID(scheduleId);
    setOpenLocationScheduleDialog(true);
  }

  function handleStaffScheduleDialogOpen(
    locationRecurrence,
    locationRecurrenceEndDate,
    scheduleId,
    start,
    end,
    scheduleType
  ) {
    setLocationRecurrenceEndDate(locationRecurrenceEndDate);
    setLocationRecurrence(locationRecurrence);
    setStartDate(moment(start).toDate());
    setEndDate(moment(end).toDate());
    setScheduleType(scheduleType);
    setSelectedStaffScheduleID(scheduleId);
    setOpenStaffScheduleDialog(true);
    setOpen(true);
  }

  function handleEditStaffScheduleDialogOpen(
    locationRecurrence,
    locationRecurrenceEndDate,
    scheduleId,
    userId,
    start,
    end,
    scheduleType
  ) {
    handleStaffScheduleDialogOpen(
      locationRecurrence,
      locationRecurrenceEndDate,
      scheduleId,
      start,
      end,
      scheduleType
    );
    setSelectedUserID(userId);
  }

  function handleOpenLocationScheduleDialog(dateUse) {
    setStartDate(moment(dateUse).toDate());
    setOpenLocationScheduleDialog(true);
  }

  const handleCancleClick = (locationId, dateString) => {
    setOpenExceptionWarningDialog(true);
    setCancelLocationId(locationId);
    setCancelSelectedDate(dateString);
  };

  const dateException = (date: string) => {
    if (!locationExceptions) return false;
    return !!locationExceptions.find(exception => moment(exception).isSame(date, 'day'));
  };

  const confirmCancelDayClick = async () => {
    try {
      const date = formatDate(DateTimeFormat.APIDateFormat, cancelSelectedDate);

      if (dateException(cancelSelectedDate)) {
        await includeDate(cancelLocationId, date);
      } else {
        await excludeDate(cancelLocationId, date);
      }
      onLocationScheduleSaveSuccess();
    } catch (error) {
      console.log(error);
    }
    setOpenExceptionWarningDialog(false);
  };

  const onCloseStaffSchedule = async (): Promise<void> => {
    await onDialogSaveSuccess();
    setOpenStaffScheduleDialog(false);
    setSelectedStaffScheduleID(0);
  };

  const onCloseScheduleDialog = async (): Promise<void> => {
    await onLocationScheduleSaveSuccess();
    setOpenLocationScheduleDialog(false);
    setSelectedLocationScheduleID(-1);
  };

  const onLocalChangesDiscarded = () => {
    setOpenLocationScheduleDialog(false);
    setOpenStaffScheduleDialog(false);
  };

  return (
    <ExpansionPanel
      key={locationId}
      square={false}
      className={styles.container}
      classes={{ expanded: styles.expanded }}
      expanded={expanded}
    >
      <div className={styles.summary}>
        <ExpansionPanelSummary className={styles.panelSummary} IconButtonProps={{ edge: 'start' }}>
          <div
            onClick={() => {
              setExpanded(!expanded);
            }}
            className={styles.inlineWrapper}
          >
            <ExpandMoreIcon
              className={expanded ? styles.panelRowArrowOpen : styles.panelRowArrowClose}
            />
            <Typography className={styles.panelRow}>{locationName}</Typography>
          </div>
          <EditIcon
            className={styles.panelRowEditIcon}
            title="Edit Location"
            onClick={() => {
              onEditLocationClick(location);
            }}
          />

          {datesOfWeek.map((dateString, index) => {
            // TODO: Move types to constants
            const hasTypeOfScheduleForDay = {
              consultationAndFollowUp: false,
              procedure: false,
              ultrasound: false,
              staffSchedule: false,
            };

            scheduleMap.get(dateString)?.forEach((schedule: LocationsSchedule) => {
              if (schedule.type === 1) {
                hasTypeOfScheduleForDay.consultationAndFollowUp = true;
              } else if (schedule.type === 2) {
                hasTypeOfScheduleForDay.procedure = true;
              } else if (schedule.type === 3) {
                hasTypeOfScheduleForDay.ultrasound = true;
              }
              if (schedule.staffSchedules.length > 0) {
                hasTypeOfScheduleForDay.staffSchedule = true;
              }
            });

            return (
              <div key={index}>
                <LocationDayScheduleOptions
                  handleNewScheduleButtonClick={() => {
                    setCreateLocationSchedule(true);
                    handleOpenLocationScheduleDialog(dateString);
                  }}
                  consultationAndFollowUp={hasTypeOfScheduleForDay.consultationAndFollowUp}
                  procedure={hasTypeOfScheduleForDay.procedure}
                  ultrasound={hasTypeOfScheduleForDay.ultrasound}
                  staffSchedule={hasTypeOfScheduleForDay.staffSchedule}
                  date={dateString}
                  locationId={locationId}
                  handleCancelDayClick={handleCancleClick}
                  excludeLocation={dateException(dateString)}
                />
              </div>
            );
          })}
        </ExpansionPanelSummary>
      </div>
      <ExpansionPanelDetails className={styles.expanded}>
        <Typography>
          <ScheduleGrid
            datesOfWeek={datesOfWeek}
            scheduleMap={scheduleMap}
            locationScheduleTypes={locationScheduleTypes}
            locationExceptions={locationExceptions}
            staffScheduleInfo={staffScheduleInfo}
            highlightedStaff={highlightedStaff}
            handleLocationScheduleEditIconClick={(locationScheduleId, dateUse, scheduleType) => {
              setCreateLocationSchedule(false);
              handleScheduleDialogOpen(locationScheduleId, dateUse, scheduleType);
            }}
            handleStaffScheduleEditIconClick={(
              locationRecurrence,
              locationRecurrenceEndDate,
              staffScheduleId,
              staffId,
              start,
              end,
              scheduleType
            ) => {
              setCreateStaffSchedule(false);
              handleEditStaffScheduleDialogOpen(
                locationRecurrence,
                locationRecurrenceEndDate,
                staffScheduleId,
                staffId,
                start,
                end,
                scheduleType
              );
            }}
            handleProfileIconClick={(
              locationRecurrence,
              locationRecurrenceEndDate,
              start,
              end,
              scheduleType
            ) => {
              setCreateStaffSchedule(true);
              handleStaffScheduleDialogOpen(
                locationRecurrence,
                locationRecurrenceEndDate,
                0,
                start,
                end,
                scheduleType
              );
            }}
          />
        </Typography>

        <ActionConfirmationDialog
          open={openExceptionWarningDialog}
          title={'Exceptions'}
          description={
            dateException(cancelSelectedDate)
              ? 'Are you sure you would like to remove restriction?'
              : 'Are you sure you would like to add an exception to this location?'
          }
          onConfirm={confirmCancelDayClick}
          onClose={() => setOpenExceptionWarningDialog(false)}
        />

        <StaffScheduleDialog
          createStaffSchedule={createStaffSchedule}
          scheduleType={scheduleType}
          title={locationName}
          open={openStaffScheduleDialog}
          locationId={locationId}
          staffScheduleId={selectedStaffScheduleID}
          startDate={startDate}
          endDate={endDate}
          userId={selectedUserID}
          onClose={onCloseStaffSchedule}
          onSaveSuccess={onCloseStaffSchedule}
          dialogType={dialogType}
          validLocationScheduleTypes={locationScheduleTypes}
          locationRecurrence={locationRecurrence}
          locationRecurrenceEndDate={locationRecurrenceEndDate}
        />

        <ScheduleDialog
          createLocationSchedule={createLocationSchedule}
          title={locationName}
          open={openLocationScheduleDialog}
          locationId={locationId}
          locationScheduleId={selectedLocationScheduleID}
          startDate={startDate}
          onClose={onCloseScheduleDialog}
          onSaveSuccess={onCloseScheduleDialog}
          dialogType={scheduleDialogType}
          validLocationScheduleTypes={locationScheduleTypes}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default MainSchedule;
