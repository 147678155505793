export enum AppointmentStatus {
  Unconfirmed = 1,
  NeedsToBeMoved = 2,
  Confirmed = 3,
  Cancelled = 4,
  Deleted = 5,
  Arrived = 6,
  Departed = 7,
  Billed = 8,
  FollowUpScheduled = 9,
  NoShow = 10,
  NotPerformed = 11,
}

export const ROLE = {
  ANONYMOUS: 'anonymous',
  USER: 'user',
  ADMIN: 'admin',
  DOCTOR: 'doctor',
  NURSE: 'nurse',
  TECHNICIAN: 'technician',
  PATIENT: 'patient',
  RECEPTIONIST: 'receptionist',
};

export const ROLE_ID = {
  ADMIN: 1,
  DOCTOR: 2,
  TECHNICIAN: 3,
  PATIENT: 4,
  RECEPTIONIST: 5,
  NURSE: 6,
};

export const formatEmail = (email: string) => {
  return email.trim();
};

export const ADD_NEW_PHYSICIAN = '+ Add New Physician';
