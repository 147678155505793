import axios from 'axios';

import { Form, QuestionForm, NewBlobDocument, Document } from 'types';
import { GET_CASE, GET_FORM } from 'lib/config';

import { getDefaultHeader } from '../utils';
import { DOCUMENT_STATUS_IDS } from 'lib/document';
import { stampDocument, updateDocument } from 'api/document';
import { PROMISE_ALL_SETTLED_RESULT, allSettledPolyfill } from 'lib/promise';

const putFile = async (endpoint: string, data: any) => {
  return axios.put(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export async function getForms(
  caseId: number | string,
  sendToPatient: number
): Promise<Array<Form>> {
  const response = await axios.get(`${GET_CASE}/${caseId}/forms`, {
    params: {
      sendToPatient,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getForm(formId: number | string, formKey?: string): Promise<QuestionForm> {
  const response = await axios.get(`${GET_FORM}/${formId}`, {
    headers: getDefaultHeader(formKey),
  });

  return response.data;
}

export async function getFormUrl(formId: number | null, patientId): Promise<string> {
  const response = await axios.get(`${GET_FORM}/${formId}/formUrl`, {
    headers: getDefaultHeader(),
    params: {
      patientId,
    },
  });

  return response.data;
}

export async function updateFormAnswers(
  formId: number | string,
  data: object,
  formKey?: string
): Promise<QuestionForm> {
  const response = await axios.put(`${GET_FORM}/${formId}/answers`, data, {
    headers: getDefaultHeader(formKey),
  });

  return response.data;
}

export async function generateNewSetOfForms(caseId: number): Promise<QuestionForm> {
  const response = await axios(`${GET_CASE}/${caseId}/newSetOfForms`, {
    method: 'POST',
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function generateNewForm(
  formId: number | string,
  caseId: number
): Promise<QuestionForm> {
  const response = await axios.post(
    `${GET_CASE}/${caseId}/newForm`,
    {
      formId: formId,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function sendForm(formId: number | string): Promise<QuestionForm> {
  const response = await axios(`${GET_FORM}/${formId}/send`, {
    method: 'POST',
    headers: getDefaultHeader(),
  });

  return response.data;
}

interface CheckBirthDateResult {
  isBirthDate: boolean;
}

export async function checkBirthDateForPatient(
  formId: number | string,
  dateOfBirth: string,
  formKey?: string
): Promise<CheckBirthDateResult> {
  const response = await axios.get(`${GET_FORM}/${formId}/dateOfBirth`, {
    params: {
      date: dateOfBirth,
    },
    headers: getDefaultHeader(formKey),
  });

  return response.data;
}

export async function submitFormDocument(
  formId: number | string,
  data: NewBlobDocument,
  token?: string
): Promise<Document> {
  /* The Server Endpoint will
   * Gets Correct Document type from form type
   * Creates completed document in the user case
   * Replaces form document url with new one
   * Creates signed request to upload document
   */

  const fd = new FormData();

  fd.append('name', data.name);
  fd.append('mimeType', data.mimeType);
  fd.append('fileName', data.fileName);

  const response = await axios.post(
    `${GET_FORM}/${formId}/submitFormDocument`,
    {
      name: data.name,
      mimeType: data.mimeType,
      fileName: data.fileName,
      softDeleteDocument: data.softDelete,
    },
    {
      headers: getDefaultHeader(token),
    }
  );
  const uploadUrl = response.data.signedRequest;

  const uploadStatuses = await allSettledPolyfill([putFile(uploadUrl, data.document)]);
  const uploadStatusId =
    uploadStatuses[0].status == PROMISE_ALL_SETTLED_RESULT.FULFILLED
      ? DOCUMENT_STATUS_IDS.SUCCESSFUL
      : DOCUMENT_STATUS_IDS.FAILED;

  await updateDocument(
    response.data.id,
    {
      documentStatusId: uploadStatusId,
    },
    token
  );

  if (uploadStatusId != DOCUMENT_STATUS_IDS.FAILED) {
    await stampDocument(response.data.id, formId, token);
  }

  return response.data;
}
