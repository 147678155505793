import React, { memo, Fragment, ReactElement, useState, useEffect } from 'react';
import moment from 'moment';

import { Contact, ContactType, ReferrerStatusId } from 'types';

import PatientEditDialog from 'components/PatientEditDialog';
import PatientEditAddressDialog from 'components/PatientEditAddressDialog';

import PatientInfo from './PatientInfo.view';
import usePatientInfo from './usePatientApi.hook';
import useNextAppointment from './useNextAppointment.hook';
import { useFormik } from 'formik';
import NewPatientDialogValidator from '../../../PatientList/components/NewPatientDialog/utils/NewPatientDialog.validator';
import dateFormatter, { DateTimeFormat } from 'lib/dateFormatter';
import { FamDocInfoConfirmDialog } from 'components/FamDocInfoConfirmDialog';
import { FamDocInfo, formatHealthCard, HAS_HEALTH_CARD } from 'lib/patient';
import { CanadaProvinceCodes } from 'lib/addresses';
import { fetchUser } from 'actions/userActions';

interface PatientInfoContainerProps {
  id: string | number;
}

function getContactByName(contacts: Array<Contact>, name: string): string {
  const contact = contacts.find(item => item.type === name);

  return contact?.value || '';
}

function getContactIdByName(contacts: Array<Contact>, name: string): number | undefined {
  const contact = contacts.find(item => item.type === name);

  return contact?.id;
}

function PatientInfoContainer({ id }: PatientInfoContainerProps): ReactElement {
  const { patient, updatePatient, fetchUser } = usePatientInfo(id);
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [famDocInfoConfirmDialogOpen, setFamDocInfoConfirmDialogOpen] = useState<boolean>(false);
  const { appointment } = useNextAppointment(id);
  let birthDate = '';
  if (patient?.patientInfo?.dateOfBirth) {
    birthDate = dateFormatter(
      DateTimeFormat.DateWithFullMonthName,
      patient?.patientInfo?.dateOfBirth
    );
  }

  const formikAddr = useFormik({
    initialValues: {
      line1: '',
      line2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      other: '',
    },
    onSubmit: async ({ line1, line2, city, province, postalCode, country }) => {
      try {
        setLoading(true);
        const body = {
          addresses: [
            {
              line1,
              line2,
              city,
              province,
              postalCode,
              country,
            },
          ],
        };
        await updatePatient(body);
        setLoading(false);
        setOpenEditAddress(false);
      } catch (e) {
        console.log(e, e.response);
        formikAddr.setErrors({
          other: e?.response?.data?.message || 'Cant update patient address',
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      cellPhone: '',
      homePhone: '',
      healthCardProvinceCode: '',
      healthCard: '',
      hcn: '',
      gender: '',
      prefix: '',
      dateOfBirth: moment() as any,
      other: '',
      needReferral: false,
      noHealthCard: false,
      familyDoctorId: 0,
    },
    validate: NewPatientDialogValidator,
    onSubmit: async ({
      firstName,
      lastName,
      email,
      phone,
      cellPhone,
      homePhone,
      hcn,
      healthCard,
      healthCardProvinceCode,
      gender,
      dateOfBirth,
      prefix,
      familyDoctorId,
      noHealthCard,
    }) => {
      try {
        setLoading(true);
        const sex = gender === 'Female' ? 'F' : 'M';
        const body = {
          firstName,
          lastName,
          email,
          userType: 'patient',
          contacts: [
            {
              id: getContactIdByName(patient?.contacts || [], 'phone'),
              type: ContactType.Phone,
              value: phone,
            },
            {
              id: getContactIdByName(patient?.contacts || [], 'cell'),
              type: ContactType.Cell,
              value: cellPhone,
            },
            {
              id: getContactIdByName(patient?.contacts || [], 'home'),
              type: ContactType.Home,
              value: homePhone,
            },
          ],
          patientInfo: {
            healthCard: formatHealthCard(healthCard),
            gender,
            prefix,
            sex,
            hcnVersion: healthCardProvinceCode === CanadaProvinceCodes.ON ? hcn : '',
            healthCardProvinceCode,
            dateOfBirth: dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : undefined,
            familyDoctorId: familyDoctorId ? familyDoctorId : null,
            noHealthCard,
          },
        };

        await updatePatient(body);
        setOpenEdit(false);
      } catch (e) {
        console.log(e, e.response);
        formik.setErrors({ other: e?.response?.data?.message || 'Cant create patient' });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!openEdit) fetchUser(id);
  }, [openEdit]);

  useEffect(() => {
    if (patient) {
      document.title = `CVC - ${patient?.fullName}`;
      const phone = getContactByName(patient?.contacts || [], 'phone');
      const cellPhone = getContactByName(patient?.contacts || [], 'cell');
      const homePhone = getContactByName(patient?.contacts || [], 'home');
      const dateOfBirth = patient?.patientInfo?.dateOfBirth
        ? moment(patient?.patientInfo?.dateOfBirth)
        : undefined;

      formik.setValues({
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        email: patient?.email,
        phone: phone,
        cellPhone: cellPhone,
        homePhone: homePhone,
        healthCardProvinceCode: patient?.patientInfo?.healthCardProvinceCode || '',
        healthCard: patient?.patientInfo?.healthCard || '',
        hcn: patient?.patientInfo?.hcn || '',
        gender: patient?.patientInfo?.gender || '',
        prefix: patient?.patientInfo?.prefix || '',
        dateOfBirth: dateOfBirth || undefined,
        other: '',
        needReferral:
          patient?.patientInfo?.referrerStatusId === ReferrerStatusId.NeedReferral ||
          patient?.patientInfo?.referrerStatusId === ReferrerStatusId.ReferralReceived ||
          !!patient?.patientInfo?.referrerFirstName ||
          !!patient?.patientInfo?.referrerLastName,

        noHealthCard: patient?.patientInfo?.healthCard ? !HAS_HEALTH_CARD : HAS_HEALTH_CARD,
        familyDoctorId: patient?.patientInfo?.familyDoctorId || 0,
      });

      formikAddr.setValues({
        line1: patient?.addresses[0]?.line1,
        line2: patient?.addresses[0]?.line2,
        city: patient?.addresses[0]?.city,
        postalCode: patient?.addresses[0]?.postalCode,
        province: patient?.addresses[0]?.province,
        country: patient?.addresses[0]?.country,
        other: '',
      });
    }
  }, [patient]);

  return (
    <Fragment>
      <PatientInfo
        patientId={patient?.id}
        firstName={patient?.firstName}
        lastName={patient?.lastName}
        email={patient?.email}
        contacts={patient?.contacts}
        nextAppointment={appointment}
        healthCardNumber={patient?.patientInfo?.healthCard}
        hcn={patient?.patientInfo?.hcn}
        gender={patient?.patientInfo?.gender}
        date={birthDate}
        address={patient?.addresses}
        provinceCode={patient?.patientInfo?.healthCardProvinceCode}
        referrerFirstName={patient?.patientInfo?.referrerFirstName}
        referrerLastName={patient?.patientInfo?.referrerLastName}
        referrerEmail={patient?.patientInfo?.referrerEmail}
        referrerPhone={patient?.patientInfo?.referrerPhone}
        referrerFax={patient?.patientInfo?.referrerFax}
        referrerBilling={patient?.patientInfo?.referrerBilling}
        referrerDocumentStatus={!!patient?.patientInfo?.referrerDocument}
        familyPhysicianFirstName={patient?.patientInfo?.familyPhysicianFirstName}
        familyPhysicianLastName={patient?.patientInfo?.familyPhysicianLastName}
        familyPhysicianEmail={patient?.patientInfo?.familyPhysicianEmail}
        familyPhysicianPhone={patient?.patientInfo?.familyPhysicianPhone}
        familyPhysicianFax={patient?.patientInfo?.familyPhysicianFax}
        familyPhysicianBilling={patient?.patientInfo?.familyPhysicianBilling}
        onEditProfileClick={(): void => setOpenEdit(true)}
        onEditAddressClick={(): void => setOpenEditAddress(true)}
      />
      <PatientEditDialog
        open={openEdit}
        loading={loading}
        value={formik.values}
        onChange={formik.setFieldValue}
        errors={formik.errors}
        onSave={formik.handleSubmit}
        onClose={(): void => setOpenEdit(false)}
      />
      <PatientEditAddressDialog
        open={openEditAddress}
        loading={loading}
        value={formikAddr.values}
        onChange={formikAddr.setFieldValue}
        errors={formikAddr.errors}
        onSave={formikAddr.handleSubmit}
        onClose={(): void => setOpenEditAddress(false)}
      />
    </Fragment>
  );
}

export default memo(PatientInfoContainer);
