import React, { ReactElement, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

import { QuestionForm, Section } from 'types';
import { MessageApi } from 'api';
import InfoModal from './components/InfoModal';
import { AlertDialog } from 'components/AlertDialog';

import Form from '../Form/Form.view';
import BirthDateCheckDialog from './components/BirthDateCheckDialog.container';
import sleep from 'lib/sleep';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface TokenBody {
  id: number;
  email: string;
  historyId: number;
  historyType: string;
  firstName: string;
  lastName: string;
}

function MessageReplyContainer(props): ReactElement {
  const [form, setForm] = useState<QuestionForm | null>(null);
  const [historyId, setHistoryId] = useState(-1);
  const [historyType, setHistoryType] = useState('');
  const [hasBirthdateBeenAnswered, setHasBirthdateBeenAnswered] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const token = props?.match?.params?.jwtToken;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (token) {
      try {
        const tokenBody: TokenBody = jwtDecode(token);
        setHistoryId(tokenBody.historyId);
        setHistoryType(tokenBody.historyType);
        setFirstName(tokenBody.firstName);
        setLastName(tokenBody.lastName);
      } catch (e) {
        console.log('ERROR');
        props?.history?.push('/404');
      }
    }
  }, [hasBirthdateBeenAnswered]);

  function onClose() {
    setOpen(false);
    const win = window.open('https://www.google.com/', '_self');
    if (win) {
      win.location.assign('https://www.canadaveinclinics.ca/');
      win.focus();
      return false;
    }
  }

  async function onSave(sectionsAnswer): Promise<void> {
    try {
      let answ: Array<any> = [];

      sectionsAnswer.forEach(section =>
        section.forEach(answers => answers.forEach(answer => answ.push(answer)))
      );

      // We only have one question so only let us know if the user answered it
      answ = answ.filter(a => {
        return a.value;
      });

      const response = answ.length > 0 ? answ[0].value : null;

      if (response) {
        // API call to save response
        await MessageApi.sendResponse(historyId, historyType, response, token);

        setOpen(true);
      }
    } catch (e) {
      const message = handleAPIErrors(e, 'Error Sending Response, Please Try Again');
      setAlertMessage(message);
      setOpenAlert(true);
      console.log(e);
    }
  }

  const onBirthDateCheck = (birthDateExists: boolean) => {
    setHasBirthdateBeenAnswered(birthDateExists);
  };

  // Creates empty message response form for user to complete
  const messageForm: Array<Section> = [
    {
      sectionName: '1',
      templateQuestions: [
        {
          id: 1,
          type: 'text',
          title: 'Response',
          order: 1,
          section: 'A',
          templateQuestionId: 1,
          userCanWrite: true,
          showField: true,
          // eslint-disable-next-line @typescript-eslint/camelcase
          default_value: '',
          requiresPrevious: [],
          description: 'Please let us know what you have to say',
          options: [],
          answers: [],
          required: true,
        },
      ],
    },
  ];

  return (
    <>
      <BirthDateCheckDialog
        historyId={historyId}
        historyType={historyType}
        token={token}
        hasBirthdateBeenAnswered={hasBirthdateBeenAnswered}
        onBirthDateCheck={onBirthDateCheck}
      />
      <AlertDialog
        open={openAlert}
        title="Error"
        message={alertMessage}
        onClose={() => setOpenAlert(false)}
      />
      {hasBirthdateBeenAnswered && (
        <Form
          sections={messageForm}
          onCancel={() => props?.history?.push('/')}
          onSave={onSave}
          firstName={firstName}
          lastName={lastName}
        />
      )}
      <InfoModal open={open} onClose={onClose} text={'Thank you for your response.'} />
    </>
  );
}

export default MessageReplyContainer;
