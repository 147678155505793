import axios from 'axios';

import {
  AppointmentAvailability,
  AppointmentAvailabilityByDay,
  AppointmentAvailabilityNext,
  AvailableBookingTimes,
  AvailableDoctor,
  DailyAppointment,
  QuickViewAppointmentAvailability,
  ScheduledStaff,
  SubType,
  SubTypes,
} from 'types';
import { GET_APPOINTMENTS, GET_CASES, GET_LOCATION, GET_LOCATIONS } from 'lib/config';
import {
  fromGetAppointmentResponse,
  fromGetOneAppointmentResponse,
} from './appointment.transformer';
import { AppointmentInfo, AppointmentResponse } from './appointment.interfaces';
import { ACCESS_TOKEN } from 'lib/const';
import { getDefaultHeader } from '../utils';
import moment from 'moment';

export async function getAppointments(
  startTime: string = moment()
    .startOf('day')
    .toDate()
    .toISOString(),
  endTime: string = moment()
    .endOf('day')
    .toDate()
    .toISOString(),
  locationIds: Array<number> = [],
  ignoreStatusIds: Array<number> = [],
  type: string | number | null = null,
  userType: number | null = null,
  searchName: string | null = null,
  staffId: number | null = null,
  statusId: number | null = null,
  referrerStatusId: number | null = null
): Promise<Array<AppointmentInfo>> {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const response = await axios(GET_APPOINTMENTS, {
    method: 'GET',
    params: {
      startTime,
      endTime,
      locationIds: `${locationIds}`,
      userType,
      staffId,
      type,
      searchName,
      statusId,
      ignoreStatusIds: `${ignoreStatusIds}`,
      referrerStatusId,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: fromGetAppointmentResponse,
  });

  return response.data;
}

export async function getAppointmentsByCaseId(
  caseId: number,
  includeDeleteAppt = false
): Promise<Array<AppointmentInfo>> {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const response = await axios.get(`${GET_CASES}/${caseId}/appointments`, {
    params: {
      includeDeleteAppt,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: fromGetAppointmentResponse,
  });

  return response.data;
}

export async function getAppointmentsById(
  appointmentId: number,
  includeDeleteAppt = false
): Promise<AppointmentInfo> {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const response = await axios.get(`${GET_APPOINTMENTS}/${appointmentId}/appointment`, {
    method: 'GET',
    params: {
      includeDeleteAppt,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: fromGetOneAppointmentResponse,
  });

  return response.data;
}

export async function createAppointment(
  staffId: number | null = null,
  nurseStaffId: number | null = null,
  technicianStaffId: number | null = null,
  referralPhysicianId: number | null = null,
  requiredStaffTypeId: number | null = null,
  caseId: number | null = null,
  locationId: number | null = null,
  startTime: string | null = null,
  endTimeExpected: string | null = null,
  cancellationList: boolean,
  anyDoctor = false,
  typeId: number | null = null,
  mainTypeId: number | null = null,
  statusId: number | null = null,
  description: string | null = null
): Promise<any | null> {
  const response = await axios.post(
    GET_APPOINTMENTS,
    {
      staffId,
      nurseStaffId,
      technicianStaffId,
      requiredStaffTypeId,
      referralPhysicianId,
      caseId,
      locationId,
      startTime,
      endTimeExpected,
      cancellationList,
      anyDoctor,
      typeId,
      mainTypeId,
      statusId,
      description,
    },
    {
      headers: getDefaultHeader(),
    }
  );
  return response.data;
}

export async function getAppointmentAvailabilityByDay(
  start: Date,
  end: Date,
  length: number,
  locationId: number,
  type: number,
  staffId?: number,
  ignoreId?: number
): Promise<Array<AppointmentAvailabilityByDay>> {
  const response = await axios.get(`${GET_APPOINTMENTS}/availability/day`, {
    params: {
      staffId,
      start,
      end,
      length,
      locationId,
      type,
      ignoreId,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getDailyAppointmentInfo({
  locationId,
  date,
}): Promise<Array<AppointmentAvailabilityByDay>> {
  const response = await axios.get(`${GET_LOCATION}/${locationId}/todayAppointmentInfo`, {
    params: {
      date,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getAvailableBookingTimes(
  locationId: number,
  date: string
): Promise<AvailableBookingTimes> {
  const response = await axios.get(`${GET_LOCATIONS}/${locationId}/availableBookingTimes`, {
    params: {
      date,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getNextAvailableAppointment(
  type: number,
  length?: number,
  locationIds?: Array<number>,
  staffId?: number,
  end?: string
): Promise<Array<AppointmentAvailabilityNext>> {
  const response = await axios.get(`${GET_APPOINTMENTS}/availability/next`, {
    params: {
      locationIds,
      type,
      staffId,
      end,
      length,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getAppointmentAvailability(
  locationId: number,
  type: number,
  day?: string,
  length?: number,
  staffId?: number,
  ignoreId?: number
): Promise<Array<AppointmentAvailability>> {
  const response = await axios.get(`${GET_APPOINTMENTS}/availability`, {
    params: {
      staffId,
      day,
      locationId,
      type,
      length,
      ignoreId,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getWeeklyAppointmentAvailability(
  date: string,
  locationId: number
): Promise<Array<QuickViewAppointmentAvailability>> {
  const response = await axios.get(`${GET_APPOINTMENTS}/weeklyAvailableAppointments`, {
    params: {
      date,
      locationId,
    },
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function deleteAppointment(id: number): Promise<void> {
  await axios(`${GET_APPOINTMENTS}/${id}`, {
    method: 'DELETE',
    headers: getDefaultHeader(),
  });
}
export async function deletePersonalTime(id: number): Promise<void> {
  await axios(`${GET_APPOINTMENTS}/personalTime/${id}`, {
    method: 'DELETE',
    headers: getDefaultHeader(),
  });
}

export async function update(id, appointment: AppointmentInfo): Promise<void> {
  const response = await axios.put(`${GET_APPOINTMENTS}/${id}`, appointment, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getScheduledDoctors(
  locationId: number,
  end: string,
  start?: string
): Promise<Array<AvailableDoctor>> {
  const response = await axios.get(`${GET_APPOINTMENTS}/scheduledDoctors`, {
    params: {
      locationId,
      end,
      start,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getDoctorsAvailableForTimeSlot(
  day: string,
  locationId: number,
  startTime: string,
  endTimeExpected: string
): Promise<Array<AvailableDoctor>> {
  const response = await axios.get(`${GET_APPOINTMENTS}/availableDoctors`, {
    params: {
      day,
      locationId,
      startTime,
      endTimeExpected,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function mailSchedule(
  date: string,
  location: number,
  staffEmails: Array<string>,
  additionalEmails: Array<string>
): Promise<Array<any>> {
  const response = await axios.post(
    `${GET_APPOINTMENTS}/mailSchedule`,
    {
      date,
      location,
      staffEmails,
      additionalEmails,
    },
    {
      headers: getDefaultHeader(),
    }
  );
  return response.data;
}

export async function mailPatientAppointment(
  appointmentId: number | undefined,
  deleted: boolean | undefined
): Promise<void> {
  const response = await axios.post(
    `${GET_APPOINTMENTS}/mailPatientAppointment`,
    {
      appointmentId,
      deleted,
    },
    {
      headers: getDefaultHeader(),
    }
  );
  return response.data;
}

export async function getTypes(): Promise<SubTypes> {
  const response = await axios.get(`${GET_APPOINTMENTS}/types`, {
    headers: getDefaultHeader(),
  });
  return response.data;
}
