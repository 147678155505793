import React, { ReactElement } from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import styles from './WeekNavigation.module.scss';

interface WeekNavigationProps {
  date: Moment;
  onDateChange: (date: Moment) => void;
  type: string;
}

function WeekNavigation({ date, onDateChange, type }: WeekNavigationProps): ReactElement {
  function handleNextWeekArrowClick(): void {
    const res = date.clone().add('1', 'week');
    onDateChange(res);
  }

  function handlePreviousWeekArrowClick(): void {
    const res = date.clone().subtract('1', 'week');
    onDateChange(res);
  }

  return (
    <div className={type === 'appointments' ? styles.appointments : styles.container}>
      <DatePicker
        className={styles.datePicker}
        openTo="year"
        format="YYYY/MMM/DD"
        label="Date"
        views={['date']}
        value={date}
        onChange={newDate => (!newDate ? null : onDateChange(newDate))}
      />

      <div className={styles.week}>
        <div
          id="leftCalenderArrow"
          className={styles.calenderArrow}
          onClick={handlePreviousWeekArrowClick}
        >
          &#9664;
        </div>
        {//Dynamic array of changable week days
        [...Array(7)].map((element, index) => {
          const dateBuilder = moment(date).startOf('week');
          dateBuilder.add(index, 'days');
          const dateInMonth = dateBuilder.date();
          const dayOfWeek = dateBuilder.format('ddd');

          //Changing color of selected day on week navigator by datepicker to red
          let otherStyle = [styles.day];
          if (dateInMonth === moment(date).date()) {
            otherStyle = [styles.selected, styles.day];
          }
          return (
            <div key={index} className={otherStyle.join(' ')}>
              {dateInMonth}
              <br />
              {dayOfWeek}
            </div>
          );
        })}
        <div
          id="rightCalenderArrow"
          className={styles.calenderArrow}
          onClick={handleNextWeekArrowClick}
        >
          &#9654;
        </div>
      </div>
    </div>
  );
}

export default WeekNavigation;
