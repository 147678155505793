import { useState, useEffect } from 'react';
import { Location } from 'types';

import { getLocations, getLocation, updateLocation } from 'api/location';

export interface LocationState {
  loading: boolean;
  locations: Array<Location>;
  location?: Location;
  fetchLocationWithId: (locationId: any) => void;
  setLocation: (location) => void;
}

function useLocationApi(locationId): LocationState {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [location, setLocation] = useState<Location>();

  async function fetchLocation(): Promise<void> {
    setLoading(true);
    try {
      const locations = await getLocations();
      setLocations(locations);
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  }

  const fetchLocationWithId = async (locationId): Promise<void> => {
    setLoading(true);
    try {
      const location = await getLocation(locationId);
      setLocation(location);
    } catch (e) {
      console.log('Error', e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLocation().catch();
  }, []);

  useEffect(() => {
    if (locationId) {
      fetchLocationWithId(locationId).catch();
    }
  }, [locationId]);

  return {
    loading,
    locations,
    location,
    fetchLocationWithId,
    setLocation,
  };
}

export default useLocationApi;
