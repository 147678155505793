import React, { ReactElement, memo, useState, useEffect } from 'react';
import { Moment } from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  AppointmentInfo,
  AppointmentTypes,
  Location,
  DynamicAppointmentSelection,
  OperationInfo,
  RescheduleInfo,
  DoctorSchedule,
  LocationListItem,
} from 'types';
import { ROLE } from 'lib/user';
import ResponsiveTopBar from './components/ResponsiveTopBar';
import styles from './Appointments.module.scss';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import AppointmentsByTypeColumn from './components/AppointmentsByTypeColumn/AppointmentsByTypeColumn';
import LocationSideBar from './components/LocationSideBar';
import NewAppointmentDialog from './components/NewAppointmentDialog';
import AvailabilityByTypeHeader from './components/AvailabilityByTypeHeader/AvailabilityByTypeHeader';
import { DateTimeFormatString } from 'lib/dateFormatter';
import LocationSelector from 'components/LocationSelector/LocationSelector.container';
import OperationInProgress from './components/OperationInProgress/OperationInProgress.view';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Icon, IconButton } from '@material-ui/core';
import { SaveAppointmentDialog } from './components/SaveAppointmentsDialog';
import Button from 'components/Button';

interface AppointmentsProps {
  date: Moment;
  changeDate: (Moment) => void;
  user?: any;
  open: boolean;
  error: string;
  message: string;
  loading: boolean;
  responseAppt?: any;
  appointmentCreated: boolean;
  filteredLocations: Array<Location>;
  locationIdsInLocationsFilter: Array<number>;
  selectedLocation: number;
  staffListForLocation: Array<UserStaff>;
  staffSchedules: Array<DoctorSchedule>;
  selectedStaff: number | null;
  highlightOnlyCancellationAppts: boolean;
  presetPatientInfo: OperationInfo | undefined;
  rescheduleAppointmentInfo: RescheduleInfo | undefined;
  goToAppointmentId: number | undefined;
  eventToDelete: any;
  deleting: boolean;
  exceptions: Array<any>;
  deleteAppointment: () => Promise<boolean>;
  deletePersonalTime: () => Promise<void>;
  setEventToDelete: (event) => void;
  cancelOperation: (id) => void;
  setRescheduleAppointmentInfo: (data) => void;
  setSelectedStaff: (staff) => void;
  setSelectedLocation: (locationId) => void;
  setLocationIdsInLocationsFilter: (number) => void;
  onChangeSearchQuery: (string) => void;
  onUpdateAppt: (id, data, referralFile, selectedPatientId) => void;
  onNewAppointment: (startTime, endTime, scheduleType, staffId) => void;
  onNewAppointmentClick: (basicSetup: boolean, details?: any) => void;
  selectProvider: (number) => void;
  editAppointment: (Appt) => void;
  selectType: (number) => void;
  selectAppointmentStatus: (number) => void;
  selectReferrerStatusId: (number) => void;
  onNewAppointmentClose: () => void;
  onMailScheduleClick: () => void;
  onNewAppointmentSave: (data, referralFile, selectedPatientId) => void;
  onUpdateAppointmentSuccess: () => void;
  setHighlightOnlyCancellationAppts: (boolean) => void;
  setShowConfirmation: (boolean) => void;
  todaysAppointmentInfo: any;
  dayCancelled: boolean;
  showConfirmation?: boolean;
  locations: Array<LocationListItem>;
  types?: Array<any>;
  subTypes: any;
  typeId: string | number | null;
  providers?: Array<string>;
  appointmentStatus?: number | null;
  referrerStatusId?: number | null;
  events?: Array<any>;
  staffList?: Array<any>;
  searchQuery: string | null;
  appointmentValues: AppointmentInfo | undefined;
  readOnly: boolean;
  onUpdateApptStatus: (appointment) => void;
  openAddEditPersonalTimeDialog: () => void;
  openStaffNotes: (doctor) => void;
}

function Appointments({
  exceptions,
  date,
  changeDate,
  providers,
  error,
  loading,
  message,
  appointmentStatus,
  referrerStatusId,
  user,
  types = [],
  subTypes,
  typeId,
  responseAppt,
  locations,
  open,
  readOnly,
  filteredLocations,
  locationIdsInLocationsFilter,
  selectedLocation,
  dayCancelled,
  todaysAppointmentInfo,
  appointmentValues,
  appointmentCreated,
  staffListForLocation,
  staffSchedules,
  staffList,
  selectedStaff,
  highlightOnlyCancellationAppts,
  setHighlightOnlyCancellationAppts,
  presetPatientInfo,
  rescheduleAppointmentInfo,
  goToAppointmentId,
  showConfirmation,
  eventToDelete,
  deleting,
  deleteAppointment,
  deletePersonalTime,
  setEventToDelete,
  setShowConfirmation,
  setRescheduleAppointmentInfo,
  cancelOperation,
  setSelectedStaff,
  setSelectedLocation,
  setLocationIdsInLocationsFilter,
  editAppointment,
  onUpdateAppt,
  selectType,
  selectAppointmentStatus,
  selectReferrerStatusId,
  onChangeSearchQuery,
  onNewAppointment,
  onNewAppointmentClick,
  onNewAppointmentClose,
  onNewAppointmentSave,
  onUpdateAppointmentSuccess,
  onMailScheduleClick,
  searchQuery,
  onUpdateApptStatus,
  openAddEditPersonalTimeDialog,
  openStaffNotes,
}: AppointmentsProps): ReactElement {
  const appointmentTypes = [
    AppointmentTypes.Consultation,
    AppointmentTypes.Procedure,
    AppointmentTypes.Ultrasound,
  ];

  const [hideAvailability, setHideAvailability] = useState<boolean>(false);
  const [deleteResponseAppt, setDeleteResponseAppt] = useState<any>();
  const [dateError, setDateError] = useState<boolean>(false);
  const [selectLocations, setSelectLocations] = useState<Array<LocationListItem>>([]);
  const [dynamicSelection, setDynamicSelection] = useState<DynamicAppointmentSelection>({
    startTime: '',
    endTime: '',
    type: '',
  });
  const [showEditDeleteConfirmation, setShowEditDeleteConfirmation] = useState<boolean>(false);

  function getFilteredAvailability(appointmentType) {
    switch (appointmentType) {
      case AppointmentTypes.Consultation:
        return todaysAppointmentInfo?.locationAppointmentAvailability.Consultation;
      case AppointmentTypes.Procedure:
        return todaysAppointmentInfo?.locationAppointmentAvailability.Procedure;
      case AppointmentTypes.Ultrasound:
        return todaysAppointmentInfo?.locationAppointmentAvailability.Ultrasound;
    }
  }

  function onChangeDate(newDate): void {
    if (newDate && newDate.isValid()) {
      changeDate(newDate);
      setDateError(false);
    } else {
      setDateError(true);
    }
  }

  function handleNextDayArrowClick(): void {
    const res = date.clone().add('1', 'day');
    changeDate(res);
  }

  function handlePreviousDayArrowClick(): void {
    const res = date.clone().subtract('1', 'day');
    changeDate(res);
  }

  function getScheduledAppointments() {
    return todaysAppointmentInfo?.locationAppointments?.filter(
      appt => appt.appointmentType === appointmentValues?.appointmentType
    );
  }

  function getFilteredAppointments(appointmentType) {
    return todaysAppointmentInfo?.locationAppointments?.filter(appointment => {
      if (readOnly) {
        const userStaffId = user.staffInfo.id;
        const doctorFound = appointment.doctor.id == userStaffId;
        const optionalTechFound = appointment.optionalTechnicianId == userStaffId;
        const optionalNurseFound = appointment.optionalNurseId == userStaffId;
        return (
          appointment.appointmentType === appointmentType &&
          (doctorFound || optionalNurseFound || optionalTechFound)
        );
      }
      return appointment.appointmentType === appointmentType;
    });
  }

  function operationInProgress() {
    if (presetPatientInfo) {
      return (
        <OperationInProgress
          disabled={!presetPatientInfo}
          title={'Patient Selected'}
          description={presetPatientInfo ? presetPatientInfo?.name : ''}
          onClick={cancelOperation}
        />
      );
    } else if (rescheduleAppointmentInfo) {
      return (
        <OperationInProgress
          disabled={!rescheduleAppointmentInfo}
          title="Reschedule"
          description={rescheduleAppointmentInfo ? rescheduleAppointmentInfo?.patientName : ''}
          onClick={cancelOperation}
        />
      );
    } else if (goToAppointmentId) {
      return (
        <OperationInProgress
          disabled={!goToAppointmentId}
          title={'Selected Appointment'}
          description={''}
          onClick={cancelOperation}
        />
      );
    }
  }

  useEffect(() => {
    if (selectLocations.length === 0) {
      setSelectLocations(JSON.parse(JSON.stringify(locations)));
    }
  }, [locations]);

  return (
    <>
      <div className={styles.topBarContainer}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            {(user.type === ROLE.ADMIN || user.type === ROLE.RECEPTIONIST) && (
              <ResponsiveTopBar
                types={types}
                typeId={typeId}
                appointmentStatus={appointmentStatus}
                referrerStatusId={referrerStatusId}
                staffListForLocation={staffListForLocation}
                selectedStaff={selectedStaff}
                searchQuery={searchQuery}
                highlightOnlyCancellationAppts={highlightOnlyCancellationAppts}
                selectedLocation={selectedLocation}
                staffList={staffList}
                setSelectedStaff={setSelectedStaff}
                setSelectedLocation={setSelectedLocation}
                changeDate={changeDate}
                onNewAppointmentClick={onNewAppointmentClick}
                selectType={selectType}
                selectAppointmentStatus={selectAppointmentStatus}
                selectReferrerStatusId={selectReferrerStatusId}
                onChangeSearchQuery={onChangeSearchQuery}
                onNewAppointment={onNewAppointment}
                setHighlightOnlyCancellationAppts={setHighlightOnlyCancellationAppts}
              />
            )}
          </div>
          <div className={styles.appointmentPageContainer}>
            <div className={styles.locationAndDateNavigator}>
              <div className={styles.operationInProgress}>{operationInProgress()}</div>
              <div className={styles.weekOf}>
                Current Week:{' '}
                {date
                  .clone()
                  .startOf('week')
                  .format(DateTimeFormatString.DateMonthYear)}{' '}
                -{' '}
                {date
                  .clone()
                  .endOf('week')
                  .format(DateTimeFormatString.DateMonthYear)}
              </div>
              <div className={styles.dayNavigator}>
                <div
                  id="leftCalenderArrow"
                  className={styles.calenderArrow}
                  onClick={handlePreviousDayArrowClick}
                >
                  &#9664;
                </div>
                <KeyboardDatePicker
                  autoOk
                  className={styles.datePicker}
                  variant="dialog"
                  openTo="date"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  label="Date"
                  value={date}
                  onChange={(newDate): void => onChangeDate(newDate)}
                  error={dateError}
                  allowKeyboardControl
                />
                <div
                  id="rightCalenderArrow"
                  className={styles.calenderArrow}
                  onClick={handleNextDayArrowClick}
                >
                  &#9654;
                </div>
              </div>
              <div className={styles.personalTime}>
                <Button onClick={openAddEditPersonalTimeDialog}>Add Personal Time</Button>
              </div>
              <div className={styles.locationSelector}>
                <LocationSelector
                  locations={selectLocations}
                  value={locationIdsInLocationsFilter}
                  onChange={setLocationIdsInLocationsFilter}
                  readOnly={readOnly}
                />
              </div>
              <LocationSideBar
                filteredLocations={filteredLocations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                onMailScheduleClick={onMailScheduleClick}
              />
            </div>
            <div className={styles.dailyAppointmentContainer}>
              <h4 className={styles.dateNavHeader}>{date.format('dddd, MMMM Do')}</h4>

              <div className={styles.availabilityByTypeContainer}>
                <div className={styles.filterControl}>
                  <IconButton onClick={() => setHideAvailability(!hideAvailability)}>
                    <Icon>{hideAvailability ? <ChevronRight /> : <ExpandMore />}</Icon>
                  </IconButton>
                </div>

                {!loading &&
                  appointmentTypes?.map((appointmentType, index) => {
                    // TODO: There's probably a better place to put this
                    // (maybe as a state variable?) but for now it lives here

                    return (
                      <AvailabilityByTypeHeader
                        key={appointmentType}
                        appointmentHeaderType={appointmentType}
                        locationDailyAppointmentAvailability={getFilteredAvailability(
                          appointmentType
                        )}
                        staffHeader={providers ? providers[index] : ''}
                        onNewAppointment={onNewAppointment}
                        rescheduleAppointmentInfo={rescheduleAppointmentInfo}
                        readOnly={readOnly}
                        hideAvailability={hideAvailability}
                      />
                    );
                  })}
              </div>
              <div className={styles.appointmentsByTypeContainer}>
                <AppointmentsByTypeColumn isHoursColumn={true} />
                {!loading ? (
                  appointmentTypes?.map(appointmentType => {
                    return (
                      <AppointmentsByTypeColumn
                        exceptions={exceptions}
                        appointments={getFilteredAppointments(appointmentType)}
                        date={date}
                        locationDailyAppointmentAvailability={getFilteredAvailability(
                          appointmentType
                        )}
                        setShowDeleteConfirmation={setShowEditDeleteConfirmation}
                        key={appointmentType}
                        onNewAppointmentClick={onNewAppointmentClick}
                        dynamicSelection={dynamicSelection}
                        setDynamicSelection={setDynamicSelection}
                        filterAppointmentStatus={appointmentStatus}
                        type={appointmentType}
                        filterAppointmentType={typeId}
                        types={types}
                        setResponseAppt={setDeleteResponseAppt}
                        responseAppt={deleteResponseAppt}
                        filterSelectedStaff={selectedStaff}
                        highlightOnlyCancellationAppts={highlightOnlyCancellationAppts}
                        selectedLocation={selectedLocation}
                        eventToDelete={eventToDelete}
                        deleting={deleting}
                        deleteAppointment={deleteAppointment}
                        deletePersonalTime={deletePersonalTime}
                        setEventToDelete={setEventToDelete}
                        onSaveSuccess={onUpdateAppointmentSuccess}
                        editAppointment={editAppointment}
                        rescheduleAppointmentInfo={rescheduleAppointmentInfo}
                        patientPreset={!!presetPatientInfo}
                        goToAppointmentId={goToAppointmentId}
                        readOnly={readOnly}
                        exception={dayCancelled}
                        onUpdateApptStatus={onUpdateApptStatus}
                        openStaffNotes={openStaffNotes}
                      />
                    );
                  })
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SaveAppointmentDialog
        open={showEditDeleteConfirmation}
        error={''}
        message={'Appointment deleted successfully!'}
        responseAppt={deleteResponseAppt}
        existingAppointmentId={-1}
        appointmentCreated={true}
        closeConfirmation={() => setShowEditDeleteConfirmation(false)}
        onClose={() => setShowEditDeleteConfirmation(false)}
      />
      <NewAppointmentDialog
        open={open}
        error={error}
        message={message}
        responseAppt={responseAppt}
        appointmentCreated={appointmentCreated}
        staffListForLocation={staffListForLocation}
        staffSchedules={staffSchedules}
        date={date}
        subTypes={subTypes}
        onClose={onNewAppointmentClose}
        onSave={(createAppointment, referralFile, selectedPatient) => {
          onNewAppointmentSave(createAppointment, referralFile, selectedPatient);
        }}
        onUpdate={onUpdateAppt}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        locationId={selectedLocation}
        appointmentsScheduled={getScheduledAppointments()}
        appointmentValues={appointmentValues}
        presetPatientId={presetPatientInfo?.id}
        rescheduleAppointmentInfo={rescheduleAppointmentInfo}
        setRescheduleAppointmentInfo={setRescheduleAppointmentInfo}
      />
    </>
  );
}

export default memo(Appointments);
