import React, { ReactElement, useEffect, useState } from 'react';
import { convertEditableMimeTypeToFileExtension, MIME_TYPE_PDF } from 'lib/document';

import useDocumentsApi from './useDocumentEditApi.hook';

import DocumentEditView from './DocumentEdit.view';

import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';

function DocumentEditContainer(props): ReactElement {
  const documentId = props?.match?.params?.documentId;
  const userId = props?.match?.params?.userId;

  const { createBlobDocument, getDocumentTypeId, document } = useDocumentsApi(userId, documentId);

  const [saving, setSaving] = useState<any>(null);

  const goBack = () => {
    props?.history?.goBack();
  };

  const onSave = async blob => {
    setSaving(true);
    if (!document) {
      setSaving(false);
      alert('SAVE ERROR NO DOCUMENT LOADED');
      return;
    }

    const nameSplited = document.title.split('_');
    let documentName = nameSplited[0];
    if (nameSplited.length > 1) {
      documentName = nameSplited[1];
    }

    const fileExtenstion = convertEditableMimeTypeToFileExtension(document.fileType);
    const documentTypeId = await getDocumentTypeId(document.type || '');
    if (!fileExtenstion || documentTypeId < 0) {
      setSaving(false);
      alert('SAVE ERROR - Please Reload Page');
      return;
    }

    const curDate = moment();
    const dateOnly = moment().format(DateTimeFormatString.APIDateFormat);

    //in case they want the date hour min in the title of the file.................
    //${dateOnly}_${curDate.hour()}_${curDate.minute()}

    const documentOut = {
      document: blob,
      name: `EDITED_${documentName}`,
      typeId: documentTypeId,
      mimeType: MIME_TYPE_PDF,
      fileName: `EDITED_${documentName}.${fileExtenstion}`,
      replacedDocumentId: document.id,
    };

    await createBlobDocument(documentOut);
    setSaving(false);
    goBack();
  };

  const onCancel = () => {
    goBack();
  };

  return (
    <>
      <DocumentEditView
        onCancel={onCancel}
        onSave={onSave}
        url={document?.url || ''}
        setSaving={setSaving}
        saving={saving}
      />
    </>
  );
}

export default DocumentEditContainer;
