import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import moment from 'moment';
import Appointments from './Appointments.view';
import Layout from 'Layout';
import { MailScheduleDialog } from './components/MailScheduleDialog';
import useAppointmentsApi from './useAppointmentsApi.hook';
import { APPOINTMENT_TYPES, APPOINTMENT_TYPE_IDS, findByTypeName } from 'lib/appointments';
import { DateTimeFormatString, DateTimeFormat } from 'lib/dateFormatter';
import { AppointmentsApi, LocationApi, UserApi, CaseApi, DocumentApi } from 'api';
import { getStaffSchedulesByLocationId } from 'api/location';
import {
  AppointmentInfo,
  Location,
  LocationListItem,
  OperationInfo,
  RescheduleInfo,
  DoctorSchedule,
  User,
} from 'types';
import createLocationsRowFromLocations from 'lib/locations';
import { getStaff } from 'api/user';
import { ROLE_ID } from 'lib/user';
import _ from 'lodash';
import format from 'lib/dateFormatter';
import { getLocationExceptions } from 'api/scheduleException';
import { getDailyAppointmentInfo } from 'api/appointment';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import { AlertDialog } from 'components/AlertDialog';
import UpdateAppointmentStatusDialog from 'components/UpdateAppointmentStatusDialog';
import { AppointmentInfo as AppointmentInfoInterface } from 'api/appointment/appointment.interfaces';
import useAppointmentApi from 'hook/useAppointmentApi.hook';
import AddPersonalTimeDialog from './components/AddPersonalTimeDialog';
import StaffNotesDialog from 'components/StaffNotesDialog';

interface PropsApp {
  locationId: number;
  date: string;
  presetPatient: OperationInfo;
  appointmentId: number;
}

function AppointmentContainer({ history }: any): ReactElement | null {
  const [open, setOpen] = useState<boolean>(false);
  const [openAddPersonalTimeDialog, setOpenAddPersonalTimeDialog] = useState<boolean>(false);
  const [mailOpen, setMailOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [responseAppt, setResponseAppt] = useState<any>();
  const [appointmentCreated, setAppointmentCreated] = useState<boolean>(false);
  const [appointmentValues, setAppointmentValues] = useState<AppointmentInfo | undefined>(
    undefined
  );
  const [allAppointmentLocations, setAllAppointmentLocations] = useState<Array<Location>>([]);
  const [locations, setLocations] = useState<Array<LocationListItem>>([]);
  const [locationIdsInLocationsFilter, setLocationIdsInLocationsFilter] = useState<Array<number>>(
    []
  );
  const [presetPatientInfo, setPresetPatientInfo] = useState<OperationInfo | undefined>(undefined);
  const [goToAppointmentId, setGoToAppointmentId] = useState<number | undefined>(undefined);
  const [rescheduleAppointmentInfo, setRescheduleAppointmentInfo] = useState<
    RescheduleInfo | undefined
  >(undefined);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [providers, setProviders] = useState<Array<string>>([]);
  const [filteredLocations, setFilteredLocations] = useState<Array<Location>>([]);
  const [todaysAppointmentInfo, setTodaysAppointmentInfo] = useState<any>();
  const [dayCancelled, setDayCancelled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [staffSchedules, setStaffSchedules] = useState<Array<DoctorSchedule>>([]);
  const [retreiveInfoError, setRetreiveInfoError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [allAppointmentTypes, setAllAppointmentTypes] = useState<Array<any>>([]);
  const [exceptions, setExceptions] = useState<any>([]);
  const {
    user,
    events,
    staffList,
    provider,
    appointmentStatus,
    referrerStatusId,
    date,
    typeId,
    searchQuery,
    selectedLocationId,
    staffListForLocation,
    selectedStaff,
    subTypes,
    highlightOnlyCancellationAppts,
    eventToDelete,
    deleting,
    deleteAppointment,
    deletePersonalTime,
    setEventToDelete,
    setSelectedStaff,
    setHighlightOnlyCancellationAppts,
    setSelectedLocationId,
    setSearchQuery,
    setTypeId,
    changeDate,
    setProvider,
    setAppointmentStatus,
    setReferrerStatusId,
    updateAppointment,
    setLocationIds,
  } = useAppointmentsApi();

  const propsApp = useLocation<PropsApp>();
  const refreshMinutes = 15;
  const refreshTimeoutTime = refreshMinutes * 60 * 1000;

  const [openUpdateStatus, setOpenUpdateStatus] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentInfoInterface>();

  const [openStaffNotes, setOpenStaffNotes] = useState<boolean>(false);
  const [selectedEditStaff, setSelectedEditStaff] = useState<User | undefined>();

  const sortLocationsByAvailability = locations => {
    const dayFormat = date.format('dd');
    return locations.sort((a, b) => {
      const location1Consulation = a.weeklyScheduleAvailability.consultation.some(
        day => day == dayFormat
      );
      const location1Procedure = a.weeklyScheduleAvailability.procedure.some(
        day => day == dayFormat
      );
      const location1Ultrasound = a.weeklyScheduleAvailability.ultrasound.some(
        day => day == dayFormat
      );
      const location2Consulation = b.weeklyScheduleAvailability.consultation.some(
        day => day == dayFormat
      );
      const location2Procedure = b.weeklyScheduleAvailability.procedure.some(
        day => day == dayFormat
      );
      const location2Ultrasound = b.weeklyScheduleAvailability.ultrasound.some(
        day => day == dayFormat
      );

      const location1HasSchedule =
        location2Consulation || location2Procedure || location2Ultrasound;
      const location2HasSchedule =
        location1Consulation || location1Procedure || location1Ultrasound;

      if (location2HasSchedule == location1HasSchedule) {
        if (a.sortOrder == 0) {
          return 1;
        }
        if (b.sortOrder == 0) {
          return -1;
        }
        return a.sortOrder - b.sortOrder;
      }
      if (location1HasSchedule) return 1;
      return -1;
    });
  };

  async function getLocations(userId): Promise<void> {
    const formattedDate = moment(date.format());
    const locations = await LocationApi.getLocations(
      searchQuery,
      formattedDate.format(DateTimeFormatString.APIDateFormat),
      userId
    );
    setAllAppointmentLocations(locations);
    if (filteredLocations?.length != 0) {
      setFilteredLocations(prevFilteredLocations =>
        _.intersectionBy(locations, prevFilteredLocations, 'id')
      );
    } else {
      setFilteredLocations(locations);
    }
    const locationsList = createLocationsRowFromLocations(locations);
    const tempLocationIds = locations.map(location => parseInt(`${location.id}`, 10));
    setLocations(locationsList);
    setLocationIds(tempLocationIds);
    if (propsApp.state && propsApp.state.locationId) {
      setSelectedLocationId(propsApp.state.locationId);
    }
  }

  useEffect(() => {
    if (propsApp.state && propsApp.state.date) {
      changeDate(moment(propsApp.state.date));
    }
    if (propsApp.state && propsApp.state.locationId) {
      setSelectedLocationId(propsApp.state.locationId);
    }

    setGoToAppointmentId(propsApp.state?.appointmentId);
    setPresetPatientInfo(propsApp.state?.presetPatient);

    history.replace({
      ...propsApp,
      state: { ...propsApp.state, date: '', presetPatient: undefined, appointmentId: undefined },
    });
  }, [useLocation]);

  useEffect(() => {
    document.title = 'CVC - Appointments';
  }, []);

  useEffect(() => {
    const allSubTypes = subTypes.map(subType => {
      return { id: subType.id, completeName: subType.completeName, name: subType.subTypeName };
    });
    setAllAppointmentTypes(allSubTypes);
  }, [subTypes]);

  useEffect(() => {
    if (!user) return;
    const readOnly =
      user != null &&
      (user.typeId === ROLE_ID.DOCTOR ||
        user.typeId === ROLE_ID.NURSE ||
        user.typeId === ROLE_ID.TECHNICIAN);

    setReadOnly(readOnly);
    if (readOnly) {
      getLocations(user.id);
      setSelectedStaff(user.staffInfo?.id);
      const locationIds = user.staffInfo.locations.map(location => location.id);
      setLocationIdsInLocationsFilter(locationIds);
      return;
    }
    setSelectedStaff(-1);
    getLocations(undefined);
  }, [user, date]);

  const viewLocationAppointmentsSchedule = async (locationId: number) => {
    let dailyAppointmentData;
    if (locationId > 0) {
      dailyAppointmentData = await getDailyAppointmentInfo({
        date: date.format(DateTimeFormatString.APIDateFormat),
        locationId: locationId,
      });
    }
    setTodaysAppointmentInfo(dailyAppointmentData);
  };

  const updateAppointmentStatus = apptmnt => {
    setSelectedAppointment(apptmnt);
    setOpenUpdateStatus(true);
  };

  const openStaffNotesDialog = staff => {
    setSelectedEditStaff(staff);
    setOpenStaffNotes(true);
  };

  useEffect(() => {
    const refreshTimeout = setTimeout(() => {
      viewLocationAppointmentsSchedule(selectedLocationId);
    }, refreshTimeoutTime);

    return (): void => clearTimeout(refreshTimeout);
  }, [todaysAppointmentInfo]);

  function onSaveSuccess(): void {
    setAppointmentValues(undefined);
    setAppointmentCreated(true);
    setRescheduleAppointmentInfo(undefined);
    viewLocationAppointmentsSchedule(selectedLocationId);
    setSelectedAppointment(undefined);
  }

  function editAppointment(appt: any): void {
    setAppointmentValues({
      appointmentId: appt.appointmentId,
      appointmentType: appt.appointmentType,
      day: date,
      startTime: appt.appointmentStartTime,
      endTime: appt.appointmentEndTime,
    });
    setOpen(true);
  }

  function cancelOperation(event) {
    setPresetPatientInfo(undefined);
    setRescheduleAppointmentInfo(undefined);
    setGoToAppointmentId(undefined);
  }

  function showConfirmationDialog(response): void {
    if (response?.appointment?.id) {
      setMessage(response.message);
      setResponseAppt(response.appointment);
      onSaveSuccess();
    } else {
      if (Array.isArray(response)) {
        setError(response[0].msg);
      } else {
        setError(response.message);
      }
      setAppointmentCreated(false);
    }
    setShowConfirmation(true);
  }

  async function createDocument(appointmentId, selectedPatientId, referralFile) {
    if (referralFile && selectedPatientId) {
      const cases = await CaseApi.getCaseByUserId(selectedPatientId);
      if (cases.length) {
        const data = {
          name: 'Appointment Referral',
          typeId: 2,
          document: referralFile,
          appointmentId,
        };
        await DocumentApi.createDocument(cases[0].id, data);
      }
    }
  }

  async function createAppointment(data, referralFile, selectedPatientId): Promise<void> {
    try {
      const response = await AppointmentsApi.createAppointment(
        data.staffId,
        data.nurseStaffId,
        data.technicianStaffId,
        data.referralPhysicianId,
        data.staffType,
        data.caseId,
        data.locationId,
        data.startTime,
        data.endTime,
        data.cancellationList,
        false,
        data.scheduleType,
        data.mainTypeId,
        data.appointmentStatus,
        data.description
      );

      if (selectedPatientId) await createDocument(response.id, selectedPatientId, referralFile);

      viewLocationAppointmentsSchedule(selectedLocationId);
      showConfirmationDialog(response);
    } catch (e) {
      showConfirmationDialog({ message: e.response.data.message });
      throw e;
    }
  }

  async function update(id, data, referralFile, selectedPatientId): Promise<void> {
    try {
      const response = await updateAppointment(id, data);
      await createDocument(id, selectedPatientId, referralFile);
      viewLocationAppointmentsSchedule(selectedLocationId);
      showConfirmationDialog(response);
    } catch (e) {
      await showConfirmationDialog({ message: e.response.data.message });
    }
  }

  async function getProviderHeaders(): Promise<void> {
    const startDate = moment(date).format(DateTimeFormatString.APIDateFormat);
    const combinedDateStart = `${startDate} 00:00`;
    const combinedDateEnd = `${startDate} 23:59`;
    const appointmentProviders: Array<string> = [];
    const staffByAppointmentTypes = await Promise.all(
      APPOINTMENT_TYPES.map(async appointmentType => {
        const staff = await getStaffSchedulesByLocationId(
          selectedLocationId,
          combinedDateEnd,
          combinedDateStart,
          appointmentType.id
        );
        return staff;
      })
    );
    staffByAppointmentTypes?.map((staffListing, appointmentType) => {
      const appointmentTypeProviders: Array<string> = [];

      staffListing?.map(provider => {
        if (
          appointmentType + 1 !== APPOINTMENT_TYPE_IDS.Procedure ||
          provider.staffType !== ROLE_ID.TECHNICIAN
        ) {
          appointmentTypeProviders.push(provider.fullName);
        }
      });
      appointmentProviders[appointmentType] = appointmentTypeProviders.join(', ');
    });

    setProviders(appointmentProviders);
  }

  async function openNewAppointmentDialog(custom, details): Promise<void> {
    if (custom) {
      let user;
      if (details.staffId) {
        const staff = await getStaff('', '', null, details.staffId);
        user = staff[0];
      }
      setAppointmentValues({
        day: details.day ? details.day : date,
        appointmentType: details.type,
        startTime: details.startTime,
        endTime: details.endTime,
        length: details.length,
        staff: user,
      });
      setOpen(true);
      return;
    }
    setAppointmentValues(undefined);
    setOpen(true);
  }

  const checkForExceptions = async locationId => {
    if (!date || selectedLocationId <= 0) return;
    const exceptions = await getLocationExceptions(
      locationId,
      format(DateTimeFormat.APIDateFormat, date)
    );
    setExceptions(exceptions);
    setDayCancelled(!!exceptions);
  };

  const getStaffSchedules = async (userId, staffId): Promise<void> => {
    const startDate = moment(date).format(DateTimeFormatString.DateOnly);
    const endDate = moment(date).format(DateTimeFormatString.DateOnly);
    const staffSchedules = await UserApi.getStaffSchedules(userId, {
      startDate,
      endDate,
      locationId: selectedLocationId,
      type: undefined,
      staffId,
      selectedLocationOnly: true,
    });
    setStaffSchedules(staffSchedules);
  };

  const deletePersonalTimeAppointment = async () => {
    const deleted = deletePersonalTime ? await deletePersonalTime() : null;

    if (deleted) {
      onSaveSuccess();
    } else {
      setRetreiveInfoError(true);
      setErrorMessage('Deleting Personal Time Failed');
      setErrorTitle('Error');
    }
    if (setEventToDelete) setEventToDelete(null);
  };

  useEffect(() => {
    setLoading(true);
    let delayDebounceFn;
    if (selectedLocationId > 0) {
      delayDebounceFn = setTimeout(async () => {
        try {
          await Promise.all([
            getStaffSchedules(0, undefined),
            viewLocationAppointmentsSchedule(selectedLocationId),
            checkForExceptions(selectedLocationId),
            getProviderHeaders(),
          ]);
        } catch (err) {
          setRetreiveInfoError(true);
          setErrorMessage('Refresh page or contact Ollon if error persists');
          setErrorTitle('Error Getting Location Details');
          setSelectedLocationId(-1);
          console.log(err);
        }
      }, 300);
    }
    setLoading(false);

    return (): void => clearTimeout(delayDebounceFn);
  }, [date, selectedLocationId]);

  useEffect(() => {
    if (rescheduleAppointmentInfo) {
      setGoToAppointmentId(undefined);
    }
  }, [rescheduleAppointmentInfo]);

  useEffect(() => {
    let locations = allAppointmentLocations;
    if (locationIdsInLocationsFilter.length > 0) {
      locations = allAppointmentLocations.filter(location =>
        locationIdsInLocationsFilter.includes(location.id)
      );
    }
    const newFilteredLocations = sortLocationsByAvailability(locations);
    if (!newFilteredLocations.some(location => location.id == selectedLocationId)) {
      const locationId = newFilteredLocations.length ? newFilteredLocations[0].id : -1;
      setSelectedLocationId(locationId);
    }
    setFilteredLocations(newFilteredLocations);
  }, [locationIdsInLocationsFilter, allAppointmentLocations]);

  return (
    <Layout hideFooter>
      {user && (
        <Appointments
          exceptions={exceptions}
          date={date}
          events={events}
          user={user}
          staffList={staffList}
          error={error}
          message={message}
          responseAppt={responseAppt}
          changeDate={changeDate}
          types={APPOINTMENT_TYPES.concat(allAppointmentTypes)}
          subTypes={subTypes}
          typeId={typeId}
          open={open}
          selectType={setTypeId}
          selectedLocation={selectedLocationId}
          dayCancelled={dayCancelled}
          loading={loading}
          todaysAppointmentInfo={todaysAppointmentInfo}
          setSelectedLocation={setSelectedLocationId}
          locationIdsInLocationsFilter={locationIdsInLocationsFilter}
          setLocationIdsInLocationsFilter={setLocationIdsInLocationsFilter}
          locations={locations}
          readOnly={readOnly}
          providers={providers}
          appointmentStatus={appointmentStatus}
          filteredLocations={filteredLocations}
          referrerStatusId={referrerStatusId}
          selectedStaff={selectedStaff}
          highlightOnlyCancellationAppts={highlightOnlyCancellationAppts}
          eventToDelete={eventToDelete}
          deleting={deleting}
          deleteAppointment={deleteAppointment}
          deletePersonalTime={deletePersonalTimeAppointment}
          setEventToDelete={setEventToDelete}
          setHighlightOnlyCancellationAppts={setHighlightOnlyCancellationAppts}
          setSelectedStaff={setSelectedStaff}
          selectProvider={setProvider}
          selectAppointmentStatus={setAppointmentStatus}
          selectReferrerStatusId={setReferrerStatusId}
          cancelOperation={cancelOperation}
          editAppointment={editAppointment}
          setShowConfirmation={setShowConfirmation}
          showConfirmation={showConfirmation}
          presetPatientInfo={presetPatientInfo}
          goToAppointmentId={goToAppointmentId}
          rescheduleAppointmentInfo={rescheduleAppointmentInfo}
          setRescheduleAppointmentInfo={setRescheduleAppointmentInfo}
          searchQuery={searchQuery}
          onChangeSearchQuery={setSearchQuery}
          onUpdateAppt={update}
          staffListForLocation={staffListForLocation}
          staffSchedules={staffSchedules}
          appointmentValues={appointmentValues}
          appointmentCreated={appointmentCreated}
          onNewAppointment={async (startTime, endTime, scheduleType, staffId) => {
            const staff = await getStaff('', '', selectedLocationId, staffId);
            const user = staff[0];
            setAppointmentValues({
              day: date,
              startTime: startTime,
              endTime: endTime,
              appointmentType: scheduleType,
              staff: user,
            });
            setOpen(true);
          }}
          onNewAppointmentClick={openNewAppointmentDialog}
          onNewAppointmentClose={() => {
            setAppointmentCreated(false);
            setOpen(false);
          }}
          onMailScheduleClick={() => {
            setMailOpen(true);
          }}
          onNewAppointmentSave={createAppointment}
          onUpdateAppointmentSuccess={onSaveSuccess}
          onUpdateApptStatus={updateAppointmentStatus}
          openAddEditPersonalTimeDialog={() => setOpenAddPersonalTimeDialog(true)}
          openStaffNotes={openStaffNotesDialog}
        />
      )}
      <MailScheduleDialog
        open={mailOpen}
        onClose={() => {
          setMailOpen(false);
        }}
        date={date}
        staffSchedules={staffSchedules}
        selectedLocation={selectedLocationId}
        staffList={staffList}
        staffListForLocation={staffListForLocation}
      />
      <AddPersonalTimeDialog
        open={openAddPersonalTimeDialog}
        date={date}
        resetError={() => setError('')}
        onClose={() => setOpenAddPersonalTimeDialog(false)}
        staffSchedules={staffSchedules}
        scheduledAppointments={todaysAppointmentInfo?.locationAppointments}
        location={locations.find(location => (location.id = selectedLocationId))}
        onSave={createAppointment}
      />
      <AlertDialog
        open={retreiveInfoError}
        title={errorTitle}
        message={errorMessage}
        onClose={() => setRetreiveInfoError(false)}
      />
      <UpdateAppointmentStatusDialog
        open={openUpdateStatus}
        appointmentId={selectedAppointment?.appointmentId}
        onClose={() => {
          setOpenUpdateStatus(false);
        }}
        refresh={onSaveSuccess}
      />
      <StaffNotesDialog
        open={openStaffNotes}
        staff={selectedEditStaff}
        onClose={() => {
          setOpenStaffNotes(false);
        }}
      />
    </Layout>
  );
}

export default AppointmentContainer;
